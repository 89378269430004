import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../environments/root/environment';
import {RequestService} from '../../global/services/request/request.service';
import { SiteService } from '../services/site/site.service';

@Injectable({
  providedIn: 'root'
})
export class JobDetailsGuard implements CanActivate {

  constructor(private requestService:RequestService, private router: Router, private siteService: SiteService ,@Inject(PLATFORM_ID) private platformId: object,
                @Optional() @Inject(RESPONSE) private response: Response) {
}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      try {
        if (state.root.children[0]) {
          const recordId = route.params['recordId'];
          if(recordId && (recordId.startsWith('JOB_') || recordId.startsWith('job_'))){
            return this.getJobDetails(recordId, state);
          }else {
            return true;
          }
        }else {
          return true;
        }
      } catch(e){
        return true;
      }
  }

  getJobDetails(recordId:string, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    const requestData = {
      facetLocation: "",
      flexibleWorking: null,
      fullTime: null,
      industry: [''],
      isSponsored: false,
      jobType: [''],
      partTime: null,
      query: "",
      locations: "",
      salMax: null,
      salMin: null,
      sortType: null,
      specialismId: "",
      subSpecialismId: "",
      typeOnlyFilter: null,
      userAgent: "-Desktop",
      radius: 15,
      isCrossCountry: false,
      isResponseCountry: false,
      responseSiteLocale: "",
      pageToken: null,
      jobId: recordId,
      jobRefrence: "",
      crossCountryUrl: "",
      payType: null,
      type: "jobDetails",
      cookieDomain:cookieDomain
    }
   return this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, requestData).pipe(map(res => {
      if (res.status === config.SUCCESS) {
        if(res.data && res.data.result.jobs[0]){
          const jobRecordId = res.data.result.jobs[0].nonFilterableCustomFields.xLocaleRecordID.values[0];
          let orgName = state.root.children[0].params['orgName'];
          const queryParams = state.root.children[0].queryParams;
         
          let jobDetailUrl = '';
          let jobSearchUrl = '';
          if(orgName){
            jobDetailUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails]).replace(':orgName',orgName);
            jobSearchUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search]).replace(':orgName',orgName);
          }else {
            jobDetailUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails]
            jobSearchUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search]
          }
          let localeRecordId = '';
          if (jobRecordId) {
            let xLocaleRecordId = jobRecordId.split('/');
            localeRecordId = xLocaleRecordId[xLocaleRecordId.length - 1];
            if(!localeRecordId.startsWith('JOB_') || !localeRecordId.startsWith('job_')) {
              if (isPlatformServer(this.platformId)) {
                this.response.status(301);
                this.response.redirect(`${jobDetailUrl+'/'+localeRecordId}`);
                this.response.finished = true;
                this.response.end();
              }else {
                this.router.navigate([jobDetailUrl, localeRecordId], {
                  queryParams : queryParams
                }).then(r => {
                
                });
              }  
          } else {
            // this.router.navigate([jobSearchUrl], {
            //   queryParams: {
            //     q: '',
            //     stat: 'e',
            //     location: '',
            //     jobType: '-1'
            //   }
            // });
            if(isPlatformServer(this.platformId)){
              const searchUrl = `${jobSearchUrl}?q=&stat=e&location=&jobType=-1`;
              this.response.status(302);
              this.response.redirect(`${searchUrl}`);
              this.response.finished = true;
              this.response.end();
            }else {
              const searchUrl = `${jobSearchUrl}?q=&stat=e&location=&jobType=-1`;
              window.location.href= searchUrl;
            }
          }
        }
        }
      }
      return true;
    }, error => {
      return true;
    }));
  }
  
}
