import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DropboxResponse} from '../../models/partner/dropbox-response';
import {GDriveResponse} from '../../models/partner/gdrive-response';
import {RequestService} from '../../services/request/request.service';
import {SiteService} from '../../services/site/site.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../environments/root/environment';
import {Getcv} from '../../../jobportal/model/request/getcv';
import {DataSharingService} from '../../../jobportal/service/data-sharing.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-partner-upload-cv',
  templateUrl: './jp-partner-upload-cv.component.html',
  styleUrls: ['./jp-partner-upload-cv.component.scss']
})
export class JpPartnerUploadCvComponent implements OnInit, OnDestroy {
  public dropBoxResponse : DropboxResponse[];
  public dropBoxResponseModifies : DropboxResponse[]=[];
  public folder ='folder';
  public accessTokenDB:string;
  public showBackButton: boolean;
  public path:string;
  private state: string;
  public cv: string;
  public showClassSelected: boolean;
  private gDriveResponse: GDriveResponse[];
  public gDriveResponseModified: GDriveResponse[]=[];
  public accessTokenGD: string;
  private fileId: string;
  public buttonCancelled: boolean;
  public fileSelected: boolean;
  public showFiles: boolean;
  private baseURL: string;
  public maximumSize:string
  public jobApplyURL: string = '/apply';
  SubscriptionList:Subscription[]=[];


  constructor(private requestService: RequestService, private route: ActivatedRoute, private siteService: SiteService,
              private translate : TranslateService,private router:Router, private dataSharing:DataSharingService, @Inject(PLATFORM_ID) private platformId: object) {
  }

  ngOnInit() {
    this.accessTokenDB = sessionStorage.getItem("accesstoken");
    this.path = sessionStorage.getItem("path");
    this.baseURL = window.location.origin;
    if(isPlatformBrowser(this.platformId)) {
      this.getFiles(this.path,this.accessTokenDB);
    }
    this.maximumSize= environment[config.theme + this.siteService.getSiteLocale()]['config']['maximumSize'];
    const jobApplyURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobApply];
    this.jobApplyURL = jobApplyURL.split(':recordId')[1];
  }

   public bytesToSize(bytes:any) {
    let sizes = config.fileUnit;
    if (bytes == 0) return '0 Byte';
    let i:any;
    i= parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  public getFiles(path: any, accessToken: string) {
    let endpoint='';
    let drive='';
    if(this.router.url.indexOf('DropBox')>-1) {
      endpoint = apiEndPoints.getDropBoxFiles;
      if(accessToken)
        this.accessTokenDB=accessToken;
    }
    else {
      endpoint = apiEndPoints.getGDriveFiles;
      drive='GDrive';
      if(accessToken)
        this.accessTokenGD=accessToken;
    }
    if(path=='null')
      this.path='';
    else
      this.path=path;
    let code='';
    this.dropBoxResponseModifies=[];
    this.dropBoxResponse=[];
    const routeQueryParamsSubscription = this.route.queryParams.subscribe(param => {
      let queryParamPartner= localStorage.getItem("queryParamPartner");
      if(!queryParamPartner && queryParamPartner === null && queryParamPartner === 'null') {
        code = param.code;
        this.state = param.state;
      }else{
        if(window.location.pathname.indexOf('GDrive')>-1){
          let queryParamList =queryParamPartner.split('state=');
          let queryParam=queryParamList[1].split('?q=');
          this.state=queryParam[0];
          code=queryParam[1].split('&code=')[1].split('&scope=')[0];
        }else{
          code = param.code;
          this.state = param.state;
        }

      }
     // this.siteService.setSite(param.state);
      this.translate.setDefaultLang(this.siteService.getSiteLocale());
      let json = new Getcv('',this.baseURL + window.location.pathname + '/','',
        ((!this.path && !this.showBackButton) || !accessToken)?code:null,drive,this.path,
        ((this.path && !this.showBackButton)||(!this.path && this.showBackButton) || (this.path && !this.showBackButton) ||
          (this.path && this.showBackButton) || accessToken)?accessToken:'',null,null);
      const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,endpoint, json).subscribe(resp => {
        if (resp != null) {
          if (this.path && !this.showBackButton) {
            if(drive){
              this.gDriveResponse = resp.data.items;
              this.gDriveResponseModified=[];
              this.accessTokenGD = resp.data.accessTokenGD;
              if(this.gDriveResponse && this.gDriveResponse.length>0){
                this.gDriveResponse.forEach(data => {
                  if (!data.labels.trashed && data.parents.length > 0){
                    this.gDriveResponseModified.push(data);
                  }
                })
              }
            }else {
              this.dropBoxResponse = JSON.parse(resp.data.entries);
              this.dropBoxResponseModifies = this.dropBoxResponse;
            }
            this.showBackButton= true;
          } else if((this.path && this.showBackButton) || !this.path) {
            if(!this.path) {
              this.showBackButton = false;
              if(drive) {
                this.gDriveResponse = resp.data.items;
                this.accessTokenGD = resp.data.accessTokenGD;
                if(this.gDriveResponse && this.gDriveResponse.length>0){
                  this.gDriveResponse.forEach(data => {
                    if (!data.labels.trashed && data.parents.length > 0){
                      this.gDriveResponseModified.push(data);
                    }
                  })
                }
              }
              else {
                this.accessTokenDB = resp.data.accessTokenDB;
                this.dropBoxResponse = resp.data.entries;
                this.dropBoxResponse.forEach(data => {
                  if (data.path_lower.match(new RegExp("/", 'g')).length == 1)
                    this.dropBoxResponseModifies.push(data);
                })
              }
            }
            else {
              if(drive){
                this.gDriveResponse = JSON.parse(resp.data.items);
                this.gDriveResponseModified = this.gDriveResponse;
              }else {
                this.dropBoxResponse = JSON.parse(resp.data.entries);
                this.dropBoxResponseModifies = this.dropBoxResponse;
              }
            }
          }
          this.dropBoxResponseModifies.sort((a, b) => a.name.localeCompare(b.name));
          this.showFiles=true
        }
        else {
          this.showFiles=false;
        }
        sessionStorage.setItem("accesstoken",this.accessTokenDB);
        sessionStorage.setItem("path",this.path);

      },error => {
        this.showFiles=false;
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
    });
    this.SubscriptionList.push(routeQueryParamsSubscription);
  }

  getSelectedFile() {
    this.fileSelected=true;
    let stateParams=this.state.split('?');
    let paramList = stateParams[1].split('&');
    let query= paramList[0].split('=');
    let location= paramList[1]?paramList[1].split('='):'';
    let locationId= paramList[2]?paramList[2].split('='):'';
    let locationSet= paramList[3]?paramList[3].split('='):'';
    let jobSource= paramList[4]?paramList[4].split('='):'';
    let utm_campaign= paramList[5]?paramList[5].split('='):'';
    if(this.router.url.indexOf('DropBox')>-1){
      window.location.href=this.baseURL+'/'+stateParams[0]+'?q='+query[1]+'&'+location[0]+'='+location[1]+'&'+locationId[0]+'='+locationId[1]+
        '&jobSource='+locationSet[1]+'&utm_campaign='+utm_campaign[1]+'&medium=dropbox'+'&path='+this.cv+'&accessTokenDB='+this.accessTokenDB;
    }else {
      window.location.href=this.baseURL+'/'+stateParams[0]+'?q='+query[1]+'&'+location[0]+'='+location[1]+'&'+locationId[0]+'='+locationId[1]+
        '&jobSource='+locationSet[1]+'&utm_campaign='+utm_campaign[1]+'&medium=gdrive'+'&path='+this.cv+'&fileId='+this.fileId+'&accessTokenGD='+this.accessTokenGD;
    }

  }

  getSelectedFileV1(){
    let baseRedirectURL=window.location.origin;
    let route='';
    let queryParam ='';
    let jobSource ='';
    let applyId ='';
    let showSendCV ='';
    let showApplyForm ='';
    let locationq ='';
    let locationId ='';
    let locationSet ='';
    let utm_campaign ='';
    let stateParams:any;
    let queryParamPartner:any;
    if(this.state.indexOf('?')>-1){
      stateParams=this.state.split('?');
    }else{
      queryParamPartner=localStorage.getItem("queryParamPartner");
      let queryParamList =queryParamPartner.split('state=');
      stateParams=queryParamList[1].split('?q=');
    }

    if(stateParams[0].indexOf('/jobportal/')>-1){
      route=stateParams[0];
      baseRedirectURL=window.location.origin+'/prjs';
    }else
      route=stateParams[0];
    let paramList =stateParams[1].split('&');
    paramList.forEach(parm=>{
      if(parm.indexOf('q')>-1)
        queryParam=parm.split('q=')[1];
      if(!queryParam)
        queryParam='';
      if(parm.indexOf('jobSource')>-1)
        jobSource=parm.split('jobSource=')[1];
      if(parm.indexOf('jobsource')>-1)
        jobSource=parm.split('jobsource=')[1];
      if(parm.indexOf('source')>-1)
        jobSource=parm.split('source=')[1];
      if(jobSource === '')
       var dataSharingSharedClientConfigSubscription = this.dataSharing.sharedClientConfig.subscribe(res => jobSource = res?.jobSource );
       this.SubscriptionList.push(dataSharingSharedClientConfigSubscription);
      if(parm.indexOf('applyId')>-1)
        applyId=parm.split('applyId=')[1];
      if(parm.indexOf('id=')>-1)
        applyId=parm.split('id=')[1];
      if(parm.indexOf('showSendCV')>-1)
        showSendCV=parm.split('showSendCV=')[1];
      if(parm.indexOf('showApplyForm')>-1)
        showApplyForm=parm.split('showApplyForm=')[1];
      if(parm.indexOf('location=')>-1)
        locationq=parm.split('location=')[1];
      if(parm.indexOf('locationId')>-1)
        locationId=parm.split('locationId=')[1];
      if(parm.indexOf('locationSet')>-1)
        locationSet=parm.split('locationSet=')[1];
      if(parm.indexOf('utm_campaign')>-1)
        utm_campaign=parm.split('utm_campaign=')[1];
    });
    let redirectURL='';
    if(route.indexOf(this.jobApplyURL)>-1) {
      if (this.router.url.indexOf('DropBox') > -1) {
        window.location.href = baseRedirectURL + route + '?q=' + queryParam +'&jobSource=' + jobSource + '&applyId=' + applyId + '&showSendCV=' + showSendCV +
          '&showApplyForm=' + showApplyForm + '&location=' + locationq + '&locationId=' + locationId + '&locationSet=' + locationSet + '&utm_campaign=' + utm_campaign + '&medium=dropbox' +
          '&path=' + this.cv + '&accessTokenDB=' + this.accessTokenDB;
      } else {
        redirectURL = baseRedirectURL + route + '?q=' + queryParam + '&jobSource=' + jobSource + '&applyId=' + applyId + '&showSendCV=' + showSendCV +
          '&showApplyForm=' + showApplyForm + '&location=' + locationq + '&locationId=' + locationId + '&locationSet=' + locationSet + '&utm_campaign=' + utm_campaign +
          '&medium=gdrive' + '&path=' + this.cv + '&fileId=' + this.fileId + '&accessTokenGD=' + this.accessTokenGD;
        window.location.href = redirectURL;
      }
    }else{
      if (this.router.url.indexOf('DropBox') > -1) {
        window.location.href = baseRedirectURL + route + '?q=' + queryParam +'&jobSource=' + jobSource +'&location=' + locationq + '&locationId=' + locationId + '&locationSet=' + locationSet + '&utm_campaign=' + utm_campaign + '&medium=dropbox' +
          '&path=' + this.cv + '&accessTokenDB=' + this.accessTokenDB;
      } else {
        redirectURL = baseRedirectURL + route + '?q=' + queryParam + '&jobSource=' + jobSource +'&location=' + locationq + '&locationId=' + locationId + '&locationSet=' + locationSet + '&utm_campaign=' + utm_campaign +
          '&medium=gdrive' + '&path=' + this.cv + '&fileId=' + this.fileId + '&accessTokenGD=' + this.accessTokenGD;
        window.location.href = redirectURL;
      }
    }
  }

  getFileDetails(path_display: string, accessToken: string, fileId: string) {
      this.cv=path_display;
      this.fileId=fileId;
    if(this.router.url.indexOf('DropBox')>-1)
        this.accessTokenDB=accessToken;
    else
      this.accessTokenGD=accessToken;
      this.showClassSelected=true;
  }

  cancel() {
    this.buttonCancelled=true;
    let stateParams=[];
    if(this.state.indexOf(this.jobApplyURL)>-1) {
      window.location.href=window.location.origin+this.route.snapshot.queryParamMap.get('state')+'&drive=cancel'
    }
    else {
      stateParams = this.state.split('?');
      window.location.href=window.location.origin+stateParams[0]+'?drive=cancel';
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
