import { Component, OnDestroy, OnInit } from '@angular/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {apiEndPoints, config, environment} from '../../../../../../environments/root/environment';
import {RequestService} from '../../../../../global/services/request/request.service';
import {UpcomingInterviews} from '../../../../model/response/my-profile';
import {DataSharingService} from '../../../../service/data-sharing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-upcoming-interviews',
  templateUrl: './jp-upcoming-interviews.component.html',
  styleUrls: ['./jp-upcoming-interviews.component.scss']
})
export class JpUpcomingInterviewsComponent implements OnInit, OnDestroy {

  public upcomingInterview:UpcomingInterviews[]=[];
  public viewGoogleLink: string;
  requestServiceGetRequestSubscription:Subscription;
  constructor(private siteService:SiteService, private translation:TranslateService, private requestService:RequestService,private dataSharing:DataSharingService) {
    this.translation.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getUpcomingInterview,null,null)
      .subscribe((res)=> {
        if (res.data && res.status == config.SUCCESS) {
          this.upcomingInterview = res.data['interviewList'];
          this.dataSharing.sendUpcomingInterviewData(res.data['count']);
          this.viewGoogleLink = environment[config.theme + this.siteService.getSiteLocale()]['config']['view_google_link'];
      }
      },(error)=>{
    });
  }
  ngOnDestroy(): void {
    if(this.requestServiceGetRequestSubscription){
    this.requestServiceGetRequestSubscription.unsubscribe();}
  }
}
