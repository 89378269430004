import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExtraFieldsConfig } from 'src/app/jobportal/model/response/google-job-response';
import { JobSearchService } from 'src/app/jobportal/service/job-search.service';

@Component({
  selector: 'app-play-store',
  templateUrl: './play-store.component.html',
  styleUrls: ['./play-store.component.css']
})
export class PlayStoreComponent implements OnInit, OnDestroy {
  /**
   * Captures the domain config information
   */
  public domainConfig: ExtraFieldsConfig;
  jobSearchServiceSharedConfigListSubscription:Subscription;
  constructor(private jobSearchService: JobSearchService) { }

  ngOnInit(): void {
    this.jobSearchServiceSharedConfigListSubscription= this.jobSearchService.sharedConfigList.subscribe((domainConfig) => {
      this.domainConfig = domainConfig;
    });
  }
  ngOnDestroy(): void {
    if(this.jobSearchServiceSharedConfigListSubscription){
    this.jobSearchServiceSharedConfigListSubscription.unsubscribe();}
  }
}
