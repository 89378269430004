import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {DOCUMENT} from '@angular/common';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../../../environments/root/environment';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {LinkedinAwliRequest} from '../../../../../model/request/linkedin-awli-request';
import {CookieService} from 'ngx-cookie-service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {TranslateService} from '@ngx-translate/core';
import {SeekAuthUrl} from '../../../../../model/request/SeekApplyRequest';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {MarketingConsent} from '../../../../../model/request/marketing-consent';
import {Subscription} from 'rxjs';
import { AzureGaService } from 'src/app/global/services/azure-ga/azure-ga.service';

declare var $:any;

@Component({
  selector: 'app-jp-apply-option',
  templateUrl: './jp-apply-option.component.html',
  styleUrls: ['./jp-apply-option.component.scss']
})
export class JpApplyOptionComponent implements OnInit,AfterViewInit, OnDestroy {

  public pageConfig: ExtraFieldsConfig;
  public JobResponse: GoogleJobResponse[];
  public indeedJs: string = '(function(d, s, id) {\n' +
    '        var js, iajs = d.getElementsByTagName(s)[0];\n' +
    '        if (d.getElementById(id)){return;}\n' +
    '        js = d.createElement(s); js.id = id;js.async = true;\n' +
    '        js.src = "https://apply.indeed.com/indeedapply/static/scripts/app/bootstrap.js";\n' +
    '        iajs.parentNode.insertBefore(js, iajs);\n' +
    '        }(document, \'script\', \'indeed-apply-js\'));';
  public profileData: string;
  public isconsentPopUpEnabled: boolean;
  private allowLogin:boolean;
  public hideText: boolean;
  public seekApplyFlow=false;
  public indeedApplyFLow=false;
  public indeedAPIURL='';
  public jobID:string;
  public thankyouPageURL: string;
  public isMarketingConsentEnabled: boolean;
  public isMarketingConsentForHU: boolean;
  public marketingConsentData: MarketingConsent;
  public marketingSms: string;
  public marketingEshot:string;
  public marketingConsent:string;
  public marketingWhatsApp:string;
  public submitClicked: boolean;
  private openMarketPopupRef: Subscription = new Subscription();
  private sharedConfigListRef: Subscription= new Subscription();
  private shredJobDetailDataRef: Subscription = new Subscription();
  private marketConsentDataRef: Subscription = new Subscription();
  public isApplyOptionComponent: boolean;
  private crossClick: boolean;
  public azureEnable: boolean;
  public disable_login: boolean;
  public orgName: string;
  public orgCode: string;
  public jobSource: string;
  public marketConsentPopup: boolean=false;
  public isPrivacyConsentEnabled: boolean;
  public expressConsent: boolean;
  public isLoggedin: boolean;
  public isLinkedinPrivacyEnabled: boolean;
  public applyId : string;
  public recordId: string;
  SubscriptionList:Subscription[]=[];
  /**
   * Captures the azure login URL
   */
  private azureLoginURL: string = '';

  constructor(private searchService:JobSearchService,private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,private requestService:RequestService,private router:Router,
              private siteService:SiteService,private activatedRoute:ActivatedRoute,private cookieService:CookieService,
              private tokenStorage:TokenStorage,private translatService:TranslateService,private deviceDetector:DeviceDetectorService,
              public dataSharingService:DataSharingService, private gaService: AzureGaService) {
    this.translatService.setDefaultLang(this.siteService.getSiteLocale());
    this.azureEnable = !!environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    this.disable_login = environment[config.theme + this.siteService.getSiteLocale()]['config']['disable_login'];
  }

  ngOnInit() {
    this.isLoggedin= !!this.tokenStorage.getLoggedInToken();
    localStorage.removeItem("requstedRedirectURL");
    this.orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(this.activatedRoute.snapshot.queryParamMap.get('showApplyForm') && this.activatedRoute.snapshot.queryParamMap.get('showApplyForm').indexOf('true')>-1)
      this.hideText=true;
    this.profileData=localStorage.getItem('profileData');
    if(localStorage.getItem('profileData') && localStorage.getItem('profileData')!=='undefined'){
      this.applyLinkedin(true)
    }
    this.sharedConfigListRef = this.searchService.sharedConfigList.subscribe(config=>{
      this.pageConfig=config;
      if (config && config.isMarketingConsentEnabled) {
        this.isMarketingConsentEnabled = config.isMarketingConsentEnabled;
      }
      if (config && config.isMarketingConsentForHU) {
        this.isMarketingConsentForHU = config.isMarketingConsentForHU;
      }
      if (config && config.isPrivacyConsentEnabled) {
        this.isPrivacyConsentEnabled = config.isPrivacyConsentEnabled;
      }
      if (config && config.isLinkedinPrivacy) {
        this.isLinkedinPrivacyEnabled = config.isLinkedinPrivacy;
      }
    });
    let keyWord='';
    let applyId='';
    let jobName='';
    let jobSource='';
    let jobRef = '';
    let jobTitle = '';
    let locationDesc = '';
    let thankYouUrl = '';
    this.shredJobDetailDataRef = this.searchService.sharedJobDetailData.subscribe(jobDetailData =>{
      this.JobResponse=jobDetailData;
        if(this.JobResponse && this.JobResponse.length>0 && this.JobResponse[0].jobSource) {
          let candJobSource = this.JobResponse[0].jobSource;
          this.indeedAPIURL=this.indeedAPIURL+'?candJobSource='+candJobSource;
        }
    });
    let defaultJobSource='';

    const dataSharingServiceSharedClientConfigSubscription= this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
    const activatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      const dataSharingServiceSharedClientConfigOrgCodeSubscription = this.dataSharingService.sharedClientConfig.subscribe(res => this.orgCode = res?.orgCode );
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        this.jobSource=jobSource;
      if(this.orgCode==config.haysTechOrgCode){
        this.indeedAPIURL=this.deviceDetector.isDesktop()?environment.indeedV2APIURL+jobSource+'-Desktop':
          this.deviceDetector.isMobile()?environment.indeedV2APIURL+jobSource+'-Mobile':environment.indeedV2APIURL+jobSource+'-Tablet';
        this.indeedAPIURL=this.indeedAPIURL+'/'+this.orgCode;
      }else {
        const indeedApiURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['indeedAPIURL'];
        this.indeedAPIURL = this.deviceDetector.isDesktop() ? indeedApiURL + jobSource + '-Desktop' :
          this.deviceDetector.isMobile() ? indeedApiURL + jobSource + '-Mobile' : indeedApiURL + jobSource + '-Tablet';
        }
        if (param.get('applyId')) {
          this.jobID = param.get('applyId');
        }
        if (param.get('id')) {
          this.jobID = param.get('id');
        }
      if (param.get('q')) {
        keyWord = param.get('q');
      }
      if (param.get('applyId')) {
        applyId = param.get('applyId');
      }else if (param.get('id')){
        applyId=param.get('id');
      } else if (param.get('applyid')){
        applyId=param.get('applyid');
      }
      if (param.get('jobName')) {
        jobName = param.get('jobName');
      }
      this.applyId = applyId;
     // jobRef = this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '';
     this.SubscriptionList.push(dataSharingServiceSharedClientConfigOrgCodeSubscription);
    });

    const activatedRouteParamMapSubscription= this.activatedRoute.paramMap.subscribe(param=>{
      this.recordId = param.get('recordId');
    });
  
    this.shredJobDetailDataRef = this.searchService.sharedJobDetailData.subscribe(jobDetailData =>
    {

      this.JobResponse = jobDetailData;
      jobRef = this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '';
      jobTitle = this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '';
      locationDesc= this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : '';
      thankYouUrl = this.getThankYouUrl(routingEndpoint.thankyou);
      this.thankyouPageURL=decodeURIComponent(window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&locationDesc='+locationDesc+'&jobRef='+jobRef);
    });
  this.marketConsentDataRef = this.dataSharingService.$isMarketingConsentLinkedIn.subscribe(data=>this.marketingConsentData = data)
  this.SubscriptionList.push(dataSharingServiceSharedClientConfigSubscription);
  this.SubscriptionList.push(activatedRouteQueryParamMapSubscription);
  this.SubscriptionList.push(activatedRouteParamMapSubscription);

}
  getThankYouUrl(endpointUrl){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let thankyouUrl ='';
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl];
      thankyouUrl = url.replace(':orgName',orgName);
    }
    else
      thankyouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
      thankyouUrl = thankyouUrl.replace(':recordId', this.recordId);
      return thankyouUrl;
  }
  ngAfterViewInit(){

  }


  applyLinkedin(isConsentPopUpEnabled: boolean) {
    this.isconsentPopUpEnabled = isConsentPopUpEnabled;
    this.submitClicked = true;
    if (this.allowLogin) {
      let jobSource = '';
      let applyId = '';
      let keyWord = '';
      let jobName = '';
      let defaultJobSource='';
      const applyLinkedinDataSharingServiceSharedClientConfigSubscription= this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      const applyLinkedinActivatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;

        if (param.get('applyId')) {
          applyId = param.get('applyId');
        }
        if (param.get('id')) {
          applyId = param.get('id');
        }
        if (param.get('keyWord')) {
          applyId = param.get('keyWord');
        }
        if (param.get('jobName')) {
          jobName = param.get('jobName');
        }
      });
      let profileData = localStorage.getItem("profileData").toString();
      if (this.isMarketingConsentEnabled && !this.marketingConsentData.email && !this.marketingConsentData.sms && !this.marketingConsentData.whatsApp) {
        this.showMarketingPopup();
      }
      if(this.isMarketingConsentEnabled){
        this.openMarketPopupRef = this.dataSharingService.$openMarketContentPopup.subscribe(data => {
          // if(this.crossClick)
          //   return
          if(!data && this.submitClicked){
            this.callLinkedIn(profileData, jobSource, applyId, keyWord, jobName);
          }
        })
      }
      else
        this.callLinkedIn(profileData, jobSource, applyId, keyWord, jobName);
        this.SubscriptionList.push(applyLinkedinActivatedRouteQueryParamMapSubscription);
        this.SubscriptionList.push(applyLinkedinDataSharingServiceSharedClientConfigSubscription);
    }
  }

  callLinkedIn(profileData, jobSource, applyId, keyWord, jobName){
    if(this.isMarketingConsentEnabled)
      this.checkMarketingData();
    let linkedinAwliRequest: LinkedinAwliRequest;
    let jobSourceAPI= jobSource;

    jobSourceAPI=this.deviceDetector.isDesktop()?jobSourceAPI+'-Desktop':this.deviceDetector.isMobile()?jobSourceAPI+'-Mobile':jobSourceAPI+'-Tablet';
    let candJobSource='';
    if(this.JobResponse && this.JobResponse.length>0 && this.JobResponse[0].jobSource){
      candJobSource=this.JobResponse[0].jobSource;
    }
    linkedinAwliRequest = new LinkedinAwliRequest(profileData,jobSourceAPI, applyId,
      this.marketingSms ? this.marketingSms : '', this.marketingEshot ? this.marketingEshot : '', this.marketingWhatsApp ? this.marketingWhatsApp : '', this.isPrivacyConsentEnabled ? this.expressConsent ? 'Y' : 'N' : '', this.marketingConsent ? this.marketingConsent : '',candJobSource,this.isLinkedinPrivacyEnabled ? 'Y':'N');
    let thankYouUrl = '';
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if (this.tokenStorage.getLoggedInToken()){
      if(orgName)
        thankYouUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.secureThankYou]).replace(':orgName',orgName);
      else
        thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureThankYou];
    }
    else {
      if(orgName)
        thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.thankyou].replace(':orgName',orgName);
      else
        thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.thankyou];
    }
    thankYouUrl = thankYouUrl.replace(':recordId', this.recordId);
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.applyLinkedin, linkedinAwliRequest).subscribe(res => {
      if (res.status === config.SUCCESS && res.data && res.data.statuscode === config.portalSuccessCode) {
        localStorage.removeItem("profileData");
        this.cookieService.set('applyJobid', applyId, 7, '/');
        this.router.navigate([thankYouUrl], {
          queryParams: {
            q: keyWord, jobName: jobName,
            applyId: applyId,
            jobSource: jobSource,
            jobTitle: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '',
            jobRef: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '',
            locationDesc: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : ''
          }
        })

      } else {
        localStorage.removeItem("profileData");
        this.router.navigate([thankYouUrl], {
          queryParams: {
            q: keyWord, jobName: jobName,
            applyId: applyId,
            jobSource: jobSource,
            jobTitle: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '',
            jobRef: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '',
            jobApply: 'N',
            locationDesc: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : ''
          }
        })
      }

    }, error => {
      localStorage.removeItem("profileData");
      this.router.navigate([thankYouUrl], {
        queryParams: {
          q: keyWord, jobName: jobName,
          applyId: applyId,
          jobSource: jobSource,
          jobTitle: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '',
          jobRef: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '',
          jobApply: 'N',
          locationDesc: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : ''
        }
      })
    });
    this.submitClicked= false
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  checkMarketingData(){
    this.marketingConsent='Y';
    if(this.marketingConsentData.email)
      this.marketingEshot = 'Y';
    else if(!this.marketingConsentData.email)
      this.marketingEshot = 'N';
    if(this.marketingConsentData.sms)
      this.marketingSms = 'Y';
    else if(!this.marketingConsentData.sms)
      this.marketingSms = 'N';
    if(this.marketingConsentData.whatsApp)
      this.marketingWhatsApp = 'Y';
    else if(!this.marketingConsentData.whatsApp)
      this.marketingWhatsApp = 'N';
  }

  seekApplyForm(){
    let jobSource = '';
    let applyId = '';
    let keyWord = '';
    let utm_campaign='';
    let micrositeCode='';
    let location='';
    let locationId='';
    let locationSet='';
    let locationLevel='';
    let subDomain='';
    let specialismId='';
    let subSpecialismId='';
    let jobName='';
    let defaultJobSource='';
    const seekApplyFormDataSharingServiceSharedClientConfigSubscription= this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
    const seekApplyFormActivatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      if (param.get('applyId'))
        applyId = param.get('applyId');
      else if (param.get('id')){
        applyId=param.get('id');
      }
      if (param.get('q'))
        keyWord = param.get('q');
      if (param.get('jobName'))
        jobName = param.get('jobName');
      if (param.get('utm_campaign'))
        utm_campaign = param.get('utm_campaign');
      if (param.get('micrositeCode'))
        micrositeCode = param.get('micrositeCode');
      if (param.get('location'))
        location = param.get('location');
      if (param.get('locationId'))
        locationId = param.get('locationId');
      if (param.get('locationSet'))
        locationSet = param.get('locationSet');
      if (param.get('locationLevel'))
        locationLevel = param.get('locationLevel');
      if (param.get('subDomain'))
        subDomain = param.get('subDomain');
      if (param.get('specialismId'))
        specialismId = param.get('specialismId');
      if (param.get('subSpecialismId'))
        subSpecialismId = param.get('subSpecialismId');
    });

    let seekAuthUrl=new SeekAuthUrl(
      window.location.origin,
      applyId,
      "q="+keyWord+"&jobName="+jobName+"&applyId="+applyId+"&location="+location+"&locationId="+locationId+"&locationSet="+locationSet+"&locationLevel="+locationLevel+
      "&s="+""+"&jobSource="+jobSource+"&utm_campaign="+utm_campaign+"&micrositeCode="+micrositeCode+"&subDomain="+subDomain+"&specialismId="+specialismId+
      "&subSpecialismId="+subSpecialismId+"&locale="+this.siteService.getLocale()+"&domain="+this.siteService.getDomain(), keywords.seekApplyUrl
    );
    const seekApplyFormRequestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.seekAuthUrl, seekAuthUrl).subscribe(res => {
      if(res.data && res.data['locationUri']){
        this.isconsentPopUpEnabled=false;
        window.location.href=res.data['locationUri'];
      }
    });
    this.SubscriptionList.push(seekApplyFormDataSharingServiceSharedClientConfigSubscription);
    this.SubscriptionList.push(seekApplyFormActivatedRouteQueryParamMapSubscription);
    this.SubscriptionList.push(seekApplyFormRequestServicePostRequestGateWaySubscription);
  }

  public indeedApplyForm(){
    this.isconsentPopUpEnabled=false;
    $('.indeed-apply-button-inner').click();
  }


  public getConsentValue($event: any) {
    if(this.isMarketingConsentForHU){
      if($event.type==='consentPopUp'){
        this.marketConsentPopup=false;
        this.allowLogin=true;
        this.marketingEshot=$event.event ? 'Y' :'N';
        this.marketingSms=$event.event ? 'Y' :'N';
      }else if($event.type==='' && $event.event){
        this.marketingEshot=$event.event ? 'Y' :'N';
        this.marketingSms=$event.event ? 'Y' :'N';
        this.marketConsentPopup=false;
        this.allowLogin=true;
      }else{
        if(this.isLoggedin) {
          this.marketConsentPopup = false;
          this.allowLogin = true;
        }else{
          this.marketConsentPopup = true;
          this.allowLogin = $event.event;
        }
      }
    }else{
      this.marketConsentPopup = false;
      this.allowLogin = $event.event;
    }
    if(this.isPrivacyConsentEnabled){
      this.marketConsentPopup=false;
      this.expressConsent = $event.event;
      this.allowLogin = true;
    }
    if(this.seekApplyFlow) {
      this.seekApplyForm();
    }
    else if(this.indeedApplyFLow){
      if (!this.marketConsentPopup)
        this.indeedApplyForm();
      else this.isconsentPopUpEnabled=false;
    }else
      this.applyLinkedin(false);
  }

  seekApply(value){
    this.seekApplyFlow=true;
    if(!this.tokenStorage.getLoggedInToken())
        this.isconsentPopUpEnabled=true;
    else{
      this.seekApplyForm();
    }

  }

  indeedApply(value){
    this.indeedApplyFLow=true;
    this.isconsentPopUpEnabled=true;
  }

  showMarketingPopup() {
    this.isApplyOptionComponent = true
    this.dataSharingService.setOpenMarketContentPopup(true);
  }

  closePopup($event: boolean) {
    this.isconsentPopUpEnabled=$event;
    localStorage.removeItem('profileData');
    this.profileData=null;
  }

  crossClicked(event){
    this.crossClick = event;
    this.marketConsentPopup=false;
  }

  ngOnDestroy(): void {
    this.marketConsentDataRef.unsubscribe();
    this.shredJobDetailDataRef.unsubscribe();
    this.sharedConfigListRef.unsubscribe();
    this.openMarketPopupRef.unsubscribe();
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){
          subscribe.unsubscribe();
      }
      });
    }   

  redirectToAzure() {
      let keyWord='';
      let jobName='';
      let applyId='';
      let jobSource='';
      let specialismId='';
      let subSpecialismId='';
      let defaultJobSource='';
      const redirectToAzureDataSharingServiceSharedClientConfigSubscription= this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );

      const redirectToAzureActivatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param=>{
        if(param.get('q'))
          keyWord=param.get('q');
        if(param.get('jobName'))
          jobName=param.get('jobName');
        if(param.get('applyId'))
          applyId=param.get('applyId');
        else if (param.get('id'))
          applyId=param.get('id');
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        if(param.get('specialismId'))
          specialismId=param.get('specialismId');
        if(param.get('subSpecialismId'))
          subSpecialismId=param.get('subSpecialismId');

        let applyUrl='';
        let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
        if(orgName){
          let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.secureJobApply];
          applyUrl = url.replace(':orgName',orgName);
        }
        else
          applyUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureJobApply];

        if(applyUrl.indexOf(':recordId')>-1){
          let applyRedirectUrl = window.location.origin+applyUrl.replace(':recordId', this.recordId)+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+
            '&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId+'&signed=Y';
          localStorage.setItem("requstedRedirectURL",applyRedirectUrl);
        }
        this.SubscriptionList.push(redirectToAzureActivatedRouteQueryParamMapSubscription);
        this.SubscriptionList.push(redirectToAzureDataSharingServiceSharedClientConfigSubscription);
      });
      this.getAzureLoginURL();
  }
  /**
   * Method to get the azure login URL
   */
  private getAzureLoginURL(){
    if(this.gaService.azureLoginURL){
      this.azureLoginURL = this.gaService.azureLoginURL;
      window.location.href = this.azureLoginURL;
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(response){
          this.azureLoginURL = this.gaService.azureLoginURL;
          window.location.href = this.azureLoginURL;
        }
      });
    }
    if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL();
    }
  }
}
