import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {CookieService} from 'ngx-cookie-service';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../jobportal/model/response/google-job-response';
import {RequestService} from '../../services/request/request.service';
import {SiteService} from '../../services/site/site.service';
import {JobSearchService} from '../../../jobportal/service/job-search.service';
import {TokenStorage} from '../../services/tokenStorage/token-storage';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../environments/root/environment';
import {SeekApplyRequest} from '../../../jobportal/model/request/SeekApplyRequest';
import { DataSharingService } from 'src/app/jobportal/service/data-sharing.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import {Meta, Title} from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-seek-apply',
  templateUrl: './seek-apply.component.html',
  styleUrls: ['./seek-apply.component.scss']
})
export class SeekApplyComponent implements OnInit , OnDestroy{

  public JobResponse: GoogleJobResponse[];
  private applyIdCookie: string;
  private domain='';
  private locale='';
  private extraFieldConfig: ExtraFieldsConfig;
  private cookieDomain: string;
  public applyId : string;
  public recordId: string;
  SubscriptionList:Subscription[]=[];
  public isDesktopDevice: boolean;
  private userAgent: string;

  constructor(private requestService:RequestService,private activatedRouter:ActivatedRoute,private siteService:SiteService,private jobSearchService:JobSearchService,
              private router:Router, private tokenStorage:TokenStorage,private xmlParserService:NgxXml2jsonService,private cookieService:CookieService, private dataShareService: DataSharingService,
              @Inject(PLATFORM_ID) private platformId: object,private translation:TranslateService,private titleService: Title,private metaService: Meta, @Inject(DOCUMENT) private document: Document,private deviceDetector:DeviceDetectorService) { }

  ngOnInit() {
    this.domain=decodeURIComponent(this.activatedRouter.snapshot.queryParamMap.get('state')).split('domain=')[1];
    this.locale=decodeURIComponent(this.activatedRouter.snapshot.queryParamMap.get('state')).split('locale=')[1].split('&domain=')[0];
    this.siteService.setSite('/' + this.domain.toUpperCase() + '/' + this.locale + '/');
    this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];

   const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS) {
        this.extraFieldConfig = res.data;
        this.jobSearchService.setExtraConfigField(this.extraFieldConfig);
      }});
    this.jobSearchService.getJobDeatilsForSeek(this.activatedRouter, this.requestService, this.siteService, this.tokenStorage, this.xmlParserService,
      'jobApply', '', '', this.cookieService,this.router,this.cookieDomain, this.dataShareService,this.translation,this.titleService,this.metaService,this.document);
    const jobSearchServiceSharedJobDetailDataSubscription = this.jobSearchService.sharedJobDetailData.subscribe(jobDetailData => {
      this.JobResponse = jobDetailData;
      if(this.JobResponse && this.JobResponse[0].jobRecordId){
        let xLocaleRecordId = this.JobResponse[0].jobRecordId.split('/');
        this.recordId =  xLocaleRecordId[xLocaleRecordId.length - 1];
      }
      if(this.cookieService.get('applyJobid') )
        this.applyIdCookie=this.cookieService.get('applyJobid');
      if(this.JobResponse && this.JobResponse.length>0 &&  this.JobResponse[0].jobId && this.JobResponse[0].jobId.indexOf(this.applyIdCookie)>-1)
          this.JobResponse[0].isJobApplied=true;
      if(this.JobResponse && this.JobResponse.length>0 && this.activatedRouter.snapshot.queryParamMap.get('code') && this.activatedRouter.snapshot.queryParamMap.get('state') && this.router.url.indexOf('/seekApply')>-1 && isPlatformBrowser(this.platformId))
        this.seekApply(this.activatedRouter.snapshot.queryParamMap.get('code'),this.activatedRouter.snapshot.queryParamMap.get('state'));
      else if(this.activatedRouter.snapshot.queryParamMap.get('error') && this.activatedRouter.snapshot.queryParamMap.get('state'))
        this.redirectToApply(this.activatedRouter.snapshot.queryParamMap.get('state'));
    });
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
    this.SubscriptionList.push(jobSearchServiceSharedJobDetailDataSubscription);

  }

  seekApply(code:string,state:string){
    this.isDesktopDevice = this.deviceDetector.isDesktop(window.navigator.userAgent);
    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    let jobSource=decodeURIComponent(state).split('jobSource=')[1].split('&utm_campaign=')[0];
    jobSource=jobSource+this.userAgent;
    console.log("defaultJobSource" + jobSource);
    let jobId=decodeURIComponent(state).split('applyId=')[1].split('&location=')[0];
    let userAgent = this.jobSearchService.getUserAgent(window.navigator.userAgent);
    let q=decodeURIComponent(state).split('q=')[1].split('&jobName=')[0];
    let jobName=decodeURIComponent(state).split('jobName=')[1].split('&applyId=')[0];
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    this.applyId = jobId;
    this.siteService.setSite('/' + this.domain.toUpperCase() + '/' + this.locale + '/');
    let seekRequest=new SeekApplyRequest(
      jobId,
      code,
      userAgent,
      '',
      jobSource,
      window.location.origin,
      encodeURIComponent(state),
      keywords.seekApplyUrl
    );
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.seekApply, seekRequest).subscribe((res)=>{
      let thankYouUrl = '';
      if (this.tokenStorage.getLoggedInToken())
        thankYouUrl = this.getApplyUrl(routingEndpoint.secureThankYou);
      else
        thankYouUrl = this.getApplyUrl(routingEndpoint.thankyou);
      if(res.status === config.SUCCESS && res.data && res.data.statuscode === config.portalSuccessCode){
        this.cookieService.set('applyJobid', jobId, 7, '/');
        this.router.navigate([thankYouUrl], {
          queryParams: {
            q: q, jobName: jobName,
            applyId: jobId,
            jobSource: jobSource,
            jobTitle: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '',
            jobRef: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '',
            locationDesc: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : ''
          }
        });
      }
    });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  redirectToApply(state:string) {
    let applyUrl='';
    let jobSource='';
    let jobId='';
    let keyWord='';
    let locationParam='';
    let specialismId='';
    let subSpecialismId='';
    let jobName='';
    jobId=decodeURIComponent(state).split('applyId=')[1].split('&location=')[0];
    this.applyId = jobId;
    if(this.tokenStorage.getLoggedInToken())
      applyUrl=this.getApplyUrl(routingEndpoint.secureJobApply);
    else
      applyUrl=this.getApplyUrl(routingEndpoint.jobApply);

    jobSource=decodeURIComponent(state).split('jobSource=')[1].split('&utm_campaign=')[0];
   
    keyWord=decodeURIComponent(state).split('q=')[1].split('&jobName=')[0];
    locationParam=decodeURIComponent(state).split('location=')[1].split('&locationId=')[0];
    specialismId=decodeURIComponent(state).split('specialismId=')[1].split('&subSpecialismId=')[0];
    subSpecialismId=decodeURIComponent(state).split('subSpecialismId=')[1].split('&locale=')[0];
    jobName=decodeURIComponent(state).split('jobName=')[1].split('&applyId=')[0];
    this.router.navigateByUrl(applyUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+jobId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId)
  }

  getApplyUrl(endpointUrl){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
    let applyurl ='';
    if(orgName){
      applyurl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl]).replace(':orgName',orgName);
    }
    else
      applyurl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
      applyurl = applyurl.replace(':recordId',this.recordId);
      return applyurl;
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
