import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {UploadCvRequest} from '../../../../model/request/upload-cv-request';
import {CvData} from '../../../../model/request/cv-data';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {RequestService} from '../../../../../global/services/request/request.service';
import {GetCVData} from '../../../../model/response/manage-cv-response';
import {SetTitleService} from '../../../../service/set-title.service';
import {DataSharingService} from '../../../../service/data-sharing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-manage-cv',
  templateUrl: './jp-manage-cv.component.html',
  styleUrls: ['./jp-manage-cv.component.scss']
})
export class JpManageCvComponent implements OnInit, OnDestroy {

  public uploadCvFormGroup:FormGroup;
  public errorMsgShow=false;
  public showServerError: boolean;
  public disableUpload: boolean;
  public cvData:CvData;
  public manageCvFlow='manageCvFlow';
  public showPopUp=false;
  public existingMsgError=false;
  public uploadButtonClicked: boolean;
  SubscriptionList:Subscription[]=[];
  constructor(private actiatedRoute:ActivatedRoute,private translateService:TranslateService,
              private siteService:SiteService,private requestService:RequestService,private router:Router,private titleService:SetTitleService,private dataSharing:DataSharingService) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
    const translateServiceGetTranslationSubscription = this.translateService.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['manage_cv_page_heading']);
    });
    this.dataSharing.getWidgetListName(keywords.manageCVPageName);
    this.SubscriptionList.push(translateServiceGetTranslationSubscription);
  }

  ngOnInit() {
    this.uploadCvFormGroup=new FormGroup({
      cvData: new FormControl('',Validators.required)
    });
  }

  get f(){
    if(this.uploadCvFormGroup.value.cvData) {
      this.errorMsgShow = false;
      this.showPopUp = false;
    }
    return this.uploadCvFormGroup.controls;
  }

  showValidationMessage(event){
    if(event){
      this.errorMsgShow=false;
      this.showPopUp=false;
    }else{
      this.existingMsgError=true;
    }

  }


  uploadCV(){

    let baseRoute = '';
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.manageCV];
      baseRoute = url.replace(':orgName',orgName);
    }
    else
      baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.manageCV];

    this.router.navigate([baseRoute]);
    let token='';
    const actiatedRouteQueryParamMapAccessTokenDBSubscription = this.actiatedRoute.queryParamMap.subscribe(param=>{
      token=param.get('accessTokenDB')?param.get('accessTokenDB'):param.get('accessTokenGD');
    });
    if(this.uploadCvFormGroup.controls.cvData.value)
      this.cvData=this.uploadCvFormGroup.controls.cvData.value;
    if(this.uploadCvFormGroup.valid && this.cvData){
      this.errorMsgShow=false;
      this.showServerError=false;
      this.disableUpload=true;

      let uploadCVJson = new UploadCvRequest(this.cvData.name,null,token,this.cvData);      
      localStorage.setItem('latestCV', this.cvData.name);      
      const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.uploadcv, uploadCVJson).subscribe(response => {
        if (response.status === config.SUCCESS && response.data.statuscode==='WC000') {
          this.dataSharing.sendClearCvData(true);
          this.router.navigate([baseRoute], {queryParams: {uploadCV: 'Y',cv:this.cvData.name}});
          this.disableUpload=false;
          this.uploadCvFormGroup.reset();
        }else {
          this.dataSharing.sendClearCvData(true);
          this.disableUpload=false;
          this.router.navigate([baseRoute], {queryParams: {uploadCV: 'N',cv:this.cvData.name}});
          this.uploadCvFormGroup.reset();
        }   
         this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
      },error => {
        this.dataSharing.sendClearCvData(true);
        this.disableUpload=false;
        if (error.error &&error.error.data === config.protectedFileUpload) {
          this.showPopUp = true;
        } else {
          const actiatedRouteQueryParamMapUploadCVSubscription= this.actiatedRoute.queryParamMap.subscribe(param=>{
            if(param.get('uploadCV') && param.get('uploadCV')==='N')
              this.showServerError= true;

          });
          this.router.navigate([baseRoute], {queryParams: {uploadCV: 'N', cv: this.cvData.name}});
          this.uploadCvFormGroup.reset();
          this.SubscriptionList.push(actiatedRouteQueryParamMapUploadCVSubscription);
        }
      })
    }else{
      if(this.cvData) {
        this.errorMsgShow = true;
        this.disableUpload = false;
      }
    }
    this.SubscriptionList.push(actiatedRouteQueryParamMapAccessTokenDBSubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
