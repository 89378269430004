import {Component, NgModule, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';

import {RequestService} from '../../../../../../global/services/request/request.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {LoginCounter, SignInResponse} from '../../../../../../jobportal/model/response/sign-in-response';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {DataSharingService} from '../../../../../../jobportal/service/data-sharing.service';
import {HeaderRequestJson} from '../../../../../../global/models/header/header-menu';
import {SignIn} from '../../../../../../jobportal/model/request/sign-in';
import {Go1generateLinkRequest} from '../../../../model/request/go1generateLinkRequest';
import { AzureGaService } from 'src/app/global/services/azure-ga/azure-ga.service';


@Component({
  selector: 'app-go1-login',
  templateUrl: './go1-login.component.html',
  styleUrls: ['./go1-login.component.scss']
})


export class Go1LoginComponent implements OnInit {
  public loginFormGroup: FormGroup;
  private singInRequest: SignIn;
  public signInResponse: SignInResponse;
  public loginCounterResponse: LoginCounter;
  public isAuthFail: boolean;
  public formNotValid: boolean;
  public disableBtn=false;
  public captchaShow=false;
  public isAzureEnabled: boolean;
  /**
   * Captures the azure login URL
   */
  private azureLoginURL: string = '';

  constructor(private requestService:RequestService,private tokenStorage: TokenStorage,public router:Router,private dataSharing:DataSharingService,
              private siteService:SiteService, private translate: TranslateService,private cookieService:CookieService,private activatedRoute:ActivatedRoute, 
              private gaService: AzureGaService) {
              this.translate.setDefaultLang(this.siteService.getSiteLocale());
              this.isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('logout')){
        this.tokenStorage.loggedInSignOut('','',this.dataSharing,this.requestService,'loggedin',0);
      }

      if(this.isAzureEnabled){
        let trainingId = '';
        const trainingUserValue = 'Y';
        if (param.get('trainingId')){
          trainingId = param.get('trainingId');
        }
        localStorage.setItem("requstedRedirectURL",window.location.origin+"/account/online-learning/training?redirectUrl=https://hayslearning.mygo1.com/&trainingId="+trainingId+"&trainingUser="+trainingUserValue);
        this.tokenStorage.clearCacheAndCookie();
        this.getAzureLoginURL();
        //azureLoginURL = `${azureLoginURL}?workFlow=go1_login`;
      }

      if(param.get('access')==='denied'){
        this.loginFormGroup.reset();
      }

      if(param.get('ChangePassword')==='N' || param.get('ChangeEmail')==='N'){
        this.isAuthFail=true;
      }

    });
    this.loginFormGroup = new FormGroup({
      emailAddress: new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }
      ),

      password: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      reCaptchaReactive: new FormControl('',
        {updateOn: 'change'}
      )
    });
  }

  public submitSignIn() {
    this.isAuthFail=false;
    this.formNotValid=false;
    this.validations();
    if(this.loginFormGroup.valid && !this.formNotValid){
      this.disableBtn=true;
      let username =this.loginFormGroup.controls.emailAddress.value;
      let password=btoa(this.loginFormGroup.controls.password.value);
      this.singInRequest = new SignIn(password,true,null,username.trim(),
        (this.loginCounterResponse && this.loginCounterResponse.Counter===3)?this.loginCounterResponse.Counter:null,
        (this.loginCounterResponse && this.loginCounterResponse.Counter===3)?this.loginFormGroup.controls.reCaptchaReactive.value:'','true');
      this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.createToken,this.singInRequest).subscribe(res=>{
        if(res.status===config.SUCCESS){
          if(res.data.statuscode){
            this.isAuthFail=true;
            this.loginCounterResponse=res.data;
            this.captchaShow = this.loginCounterResponse['Counter'] == 3;
            this.disableBtn=false;
            this.loginFormGroup.reset();
          }else{
            this.signInResponse = res.data;
            this.tokenStorage.saveToken(this.signInResponse.token);
            this.tokenStorage.saveSessionId(this.signInResponse.userSession);
            this.tokenStorage.saveLoggedInUserDetails(this.signInResponse.firstName,this.signInResponse.lastName,this.signInResponse.emailAddress,
              this.signInResponse.whatsappNumber?'Y':'N');
            this.setCookie(this.signInResponse.portalProfileId);
            let trainingId="";
            this.activatedRoute.queryParamMap.subscribe(param=> {
              if (param.get('trainingId'))
                 trainingId=param.get('trainingId');
                });
            let json =new Go1generateLinkRequest(trainingId);
            this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.go1generateLink,json).subscribe(res=> {
              if (res.status === config.SUCCESS) {
                window.location.href=res.data;
              }
            },error => {
              this.disableBtn = false;
              this.isAuthFail = true;
              this.loginFormGroup.reset();
              let loginLink = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.goLoginPage];
              let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
              if(orgName)
                loginLink = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.goLoginPage]).replace(':orgName',orgName);
              if (this.router.url.indexOf('login') > -1) {
                this.router.navigate([loginLink],
                  {queryParams: {access: 'denied'}});
              }
            });


          }

        }
      },error => {
        this.disableBtn=false;
        /* if(error.error.status==='HAYS_401'){*/
        this.isAuthFail=true;
        this.loginFormGroup.reset();
        let loginLink = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
        let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
        if(orgName)
          loginLink = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);
        if(this.router.url.indexOf('login')>-1) {
          this.router.navigate([loginLink],
            {queryParams: {access: 'denied'}});
        }
        // }
      })
    }else{
      this.isAuthFail=true;
      this.formNotValid=true;
      this.disableBtn=false;
    }


  }

  private setCookie(portalProfileId: string) {
    let isUsrNameCookie:boolean;
    let isPortalIdCookie:boolean;
    let cookieDomain= location.hostname;
    let len = cookieDomain.length;
    let n = cookieDomain.indexOf(".");
    if(cookieDomain.indexOf(".")>-1)
      cookieDomain = cookieDomain.substring(n, len);
    if(document.cookie.split(';').filter(item => {
      return item.indexOf('usrname=') >-1
    }).length){
      document.cookie = 'usrname' + "=" + this.signInResponse.firstName+'+'+this.signInResponse.lastName + ";expires=0"  + ';domain ='+cookieDomain;
      isUsrNameCookie=true;
    }
    if(document.cookie.split(';').filter(item => {
      return item.indexOf('PortalId=') >-1
    }).length){
      document.cookie = 'PortalId' + "=" + portalProfileId + ";expires=0"  + ';domain ='+cookieDomain;
      isPortalIdCookie=true;
    }
    if(!isUsrNameCookie)
      document.cookie = 'usrname' + "=" + this.signInResponse.firstName + '+' + this.signInResponse.lastName + ";expires=0" + ';path=/' + ';domain =' + cookieDomain;
    if(!isPortalIdCookie)
      document.cookie = 'PortalId' + "=" + portalProfileId + ";expires=0" + ';path=/' + ';domain =' + cookieDomain;


  }

  validations(){
    if(!this.loginFormGroup.value.emailAddress || !this.loginFormGroup.value.password){
      this.isAuthFail=true;
      return false;
    }

    if(this.captchaShow && !this.loginFormGroup.value.reCaptchaReactive){
      this.formNotValid=true;
      return false;
    }
  }


  public routingUrl(){
    let trainingId="";
    this.activatedRoute.queryParamMap.subscribe(param=> {
      if (param.get('trainingId'))
        trainingId=param.get('trainingId');
    });
    let forgetPasswordlink = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.forgetPassword];
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName)
      forgetPasswordlink = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.forgetPassword]).replace(':orgName',orgName);
    this.router.navigate([forgetPasswordlink],
      {queryParams: {trainingUser:"Y",trainingId: trainingId}});
  }
  /**
   * Method to get the azure login URL
   */
  private getAzureLoginURL(){
    if(this.gaService.azureLoginURL){
      this.azureLoginURL = this.gaService.azureLoginURL;
      this.redirectToAzure();
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(response){
          this.azureLoginURL = this.gaService.azureLoginURL;
          this.redirectToAzure();
        }
      });
    }
    if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL(); 
    }
  }
  /**
   * Redirect the user to azure
   */
  private redirectToAzure(){
    let searchParams = new URLSearchParams(this.azureLoginURL);
    searchParams.set("workFlow", "go1_login");
    this.azureLoginURL = decodeURIComponent(searchParams.toString());
    window.location.href = this.azureLoginURL;
  }

}
