import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {map} from 'rxjs/operators';
import {Location, LocationAdapt, LocationData} from '../../../../../model/response/location';
import {apiEndPoints, config} from '../../../../../../../environments/root/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rec-locations',
  templateUrl: './rec-locations.component.html',
  styleUrls: ['./rec-locations.component.scss'],
  providers:[LocationAdapt]
})
export class RecLocationsComponent implements OnInit, OnDestroy {

  @Input() parent: FormGroup;
  public locationData:LocationData[];
  requestServiceGetRequestSubscription:Subscription;
  constructor(private requestService:RequestService, private adapter:LocationAdapt){}

  ngOnInit() {
    this.getLocations();
  }

  private getLocations(){
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI, apiEndPoints.locationOnRecruitingNow,null,null).pipe(
      map((res: Location) => this.adapter.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data)
          this.locationData = res.data;
      }, (error) => {

      });
  }
  ngOnDestroy(): void {
    if(this.requestServiceGetRequestSubscription){
     this.requestServiceGetRequestSubscription.unsubscribe();}
  }
}
