<section class="app-erp-custom-loader">
  <div class="row">
    <div class="col-12 text-center">
      <img id="erp_loader_icon" src="../../assets/images/erp-loader-icon.gif" />
      <h3 [translate]="'loader_page_heading'" ></h3>
      <p [translate]="'loader_page_sub_heading'"></p>
    </div>
  </div>
</section>

