import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {DataSharingService} from './jobportal/service/data-sharing.service';
import {Router} from '@angular/router';
import {SiteService} from './global/services/site/site.service';
import {config, environment, routingEndpoint} from '../environments/root/environment';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer, PlatformLocation } from '@angular/common';
import { AzureGaService } from './global/services/azure-ga/azure-ga.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '';
  public containerClass: any;
  private isAzureEnabled: boolean;
  public jobDetailsURL: string = 'Job/Detail';
  public jobApplyURL: string = '/apply';
  public jobSearchURL: string = '/search';
  public jobSearchTitleURL: string = '/search';
  public jobSearchLocURL: string = '/search';
  /**
   * Captures the azure login URL
   */
  private azureLoginURL: string = '';


  constructor(private dataSharing: DataSharingService, public route: Router, private siteService: SiteService, private titleService: Title,private platformLocation: PlatformLocation,@Inject(PLATFORM_ID) private platformId: object,
              private gaService: AzureGaService
      ) {
    let queryPathName = '';
    let queryParamList = [];
    if(isPlatformServer(this.platformId)){
      //this.printRequestUrl();
      queryPathName = this.platformLocation.pathname;
    }else {
       queryPathName = window.location.pathname;
       queryParamList = window.location.search.split('?');
    }
    if(this.siteService.getSiteLocale()){
      this.jobSearchURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
      this.jobSearchTitleURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.searchLinkTitle];
      this.jobSearchLocURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.searchLinkLocation];
      this.jobDetailsURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];
      const jobApplyURL = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobApply];
      this.jobApplyURL = jobApplyURL.split(':recordId')[1];
    }
    this.titleService.setTitle(this.title);
    if (queryPathName.indexOf('&') > -1 && queryPathName.indexOf('globalConsent/OTId') <= -1) {
      let extraParam = queryPathName.substring(queryPathName.indexOf('&')).split('&');
      extraParam.forEach(x => queryParamList[1] = queryParamList[1] + '&' + x);
      queryPathName = queryPathName.split('&')[0];
    }

    if (queryPathName.indexOf('globalConsent/OTId') > -1) {
      let globalConsentRouteList = queryPathName.split('/OTId=');
      if (globalConsentRouteList && globalConsentRouteList.length > 0) {
        let globalConsentRout = globalConsentRouteList[0] + '?OTId=' + globalConsentRouteList[1];
        this.route.navigateByUrl(globalConsentRout);
        return;

      }
    }
    if (queryPathName.indexOf('consent/') > -1) {
      if (queryPathName.includes('expressConsent')) {
        let consentRouteList = queryPathName.split('/expressConsent');
        let identifier = consentRouteList[1].split('/');
        let consentRout = consentRouteList[0] + '?type=expressConsent&identifier=' + identifier[1]+"&reference="+identifier[2];
        this.route.navigateByUrl(consentRout);
        return;
      }
    }
    let logoutUrl = '/login/?logout=success';
    const loginUrl = this.siteService.getSiteLocale() ? environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url]['login']:'';
    if(loginUrl){
      logoutUrl = loginUrl+'/?logout=success';
    }
    if ('jobportal' != this.siteService.getOrgName()) {
      logoutUrl = '/' + this.siteService.getOrgName() + logoutUrl;
    }

    if (queryPathName.indexOf('logout') > -1 && queryPathName.indexOf('/jobportal/') === -1 && isPlatformBrowser(this.platformId)) {
      window.location.href = window.location.origin + logoutUrl;
      if ((queryPathName.indexOf('logout') > -1 || queryPathName.indexOf('azureLogout') > -1 || window.location.search.indexOf("logout") > -1) && queryPathName.indexOf('/jobportal/') === -1) {
        let isAzureB2CEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
        if (isAzureB2CEnabled) {
          this.getAzureLoginURL(queryPathName);
        } else {
          window.location.href = window.location.origin + logoutUrl;
        }
      }
      this.route.navigate([loginUrl], {queryParams: {logout: 'success'}});
    }


      queryPathName = decodeURIComponent(queryPathName);

      let pathName = window.location.pathname;
      let query = window.location.search;
      let identifier = '';
      if (query.indexOf('identifier') > -1) {
        identifier = query.split('identifier=')[1];
      } else if (query.indexOf('signedurl') > -1) {
        identifier = query.split('signedurl=')[1];
      }
      if (identifier.indexOf('+') > -1) {
        //let newId = identifier.replace(/\+/gi, '%2B');
        let encryptedIdentifier= encodeURI(identifier.split("&")[0]);
        localStorage.setItem('encryptedIdentifier',encryptedIdentifier);
        this.route.navigateByUrl(pathName + '?identifier=' +identifier);
      }
      if ((queryPathName.indexOf('browse/details') > -1 || queryPathName.indexOf(this.jobDetailsURL) > -1 || queryPathName.indexOf(this.jobApplyURL) > -1) && queryPathName.indexOf(this.jobSearchURL) === -1 && queryPathName.indexOf(this.jobSearchTitleURL) === -1 && queryPathName.indexOf(this.jobSearchLocURL) === -1) {
        if (queryPathName.indexOf('%28') > -1) {
          queryPathName = queryPathName.replace('%28', '%2528');
        }
        if (queryPathName.indexOf('%29') > -1) {
          queryPathName = queryPathName.replace('%29', '%2529');
        }
        if (queryPathName.indexOf('(') > -1) {
          let queryParamReplaceTo = queryPathName.split('(');
          queryParamReplaceTo.forEach((data, i) => {
            queryPathName = queryPathName.replace('(', '%28');
          });

        }
        if (queryPathName.indexOf(')') > -1) {
          let queryParamReplaceToNext = queryPathName.split(')');
          queryParamReplaceToNext.forEach((data, i) => {
            queryPathName = queryPathName.replace(')', '%29');
          });
        }
        if (queryPathName.indexOf(this.jobDetailsURL) > -1 && queryParamList[1]) {
         // if(isPlatformBrowser(this.platformId))
            this.route.navigateByUrl(queryPathName + '?' + queryParamList[1]);
        } else {
         // if(isPlatformBrowser(this.platformId))
            this.route.navigateByUrl(queryPathName);
        }
      }
      let pathVariable = '';
      let lang = '';
      let jobSource = '';
      if ((queryPathName.indexOf('browse/details') > -1 || queryPathName.indexOf(this.jobDetailsURL) > -1) && queryPathName.indexOf('&lang=') > -1 && queryPathName.indexOf(this.jobApplyURL) === -1 && queryPathName.indexOf(this.jobSearchURL) === -1 && queryPathName.indexOf(this.jobSearchTitleURL) === -1 && queryPathName.indexOf(this.jobSearchLocURL) === -1) {
        let queryPathnameList = queryPathName.split('&');
        queryPathnameList.forEach(item => {
          if (item.indexOf(this.jobDetailsURL) > -1) {
            pathVariable = item;
          }
          if (item.indexOf('lang') > -1) {
            lang = item;
          }
          if (item.indexOf('jobSource') > -1) {
            jobSource = item;
          }
          if (item.indexOf('jobsource') > -1) {
            jobSource = item;
          }
          if (item.indexOf('source') > -1) {
            jobSource = item;
          }
        });
        if (!jobSource) {
          this.dataSharing.sharedClientConfig.subscribe(res => jobSource = res?.jobSource);
        }
        if (lang && jobSource) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + jobSource + '&' + lang;
        } else if (lang) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + lang;
        } else if (jobSource) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + jobSource;
        } else {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable;
        }


      }

      localStorage.setItem('queryParamPartner', decodeURIComponent(window.location.search));
      this.dataSharing.sharedMainComponentData.subscribe((res) => {
        this.containerClass = res;
      });

    }

    ngOnInit(){
      
    }
    printRequestUrl(){
      if(isPlatformServer(this.platformId)){
        const url = new URL(this.platformLocation.href);
        url.port = '';
        let requestedURL = url.toString();
        if(this.platformLocation.hostname==='localhost') {
          requestedURL = this.platformLocation.href;
        }
        console.log("Requested URL : " + requestedURL);
        console.log("Date-Time : " + new Date());
      }
    }
  /**
   * Method to be called to logout user
   */
  private logOutUser(queryPathName){
    if (queryPathName.indexOf('azureLogout') > -1 || queryPathName.indexOf('logout') > -1) {
      let searchParams = new URLSearchParams(this.azureLoginURL);
      searchParams.set("action", "logout");
      window.location.href = decodeURIComponent(searchParams.toString());
    }
  }
  /**
   * Method to be called to get the azure login URL
   */
  private getAzureLoginURL(queryPathName){
    if(this.gaService.azureLoginURL){
      this.azureLoginURL = this.gaService.azureLoginURL;
      this.logOutUser(queryPathName);
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(response){
          this.azureLoginURL = this.gaService.azureLoginURL;
          this.logOutUser(queryPathName);
        }
      })
    }
    if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL();
    }
  }
}
