<app-custom-loader *ngIf="!isDataLoaded"></app-custom-loader>

<Section class="manage-job-alert" *ngIf="this.isDataLoaded">
  <h2 [translate]="'manage_job_alert'"></h2>
  <div>
    <p [translate]="'manage_job_alert_text1'"></p>
    <p>
      <span [translate]="'save_text'" ></span>
      <span [translate]="'manage_job_alert_text2'"></span>
      <span [translate]="'manage_job_alert_text3'"></span>
    </p>
  </div>

  <div class="set-up-alert" *ngIf="!hasAlerts || !jobAlertResponse">
    <h3 [translate]="'set_up_alert_heading'"></h3>
    <ul> <li [translate]="'set_up_alert_info_1'"></li>
      <li [translate]="'set_up_alert_info_2'"></li>
      <li [translate]="'set_up_alert_info_3'"></li></ul>
  </div>

  <ul class="manage-alert-listing" *ngIf="hasAlerts && jobAlertResponse">
    <li *ngFor="let alert of jobAlertResponse">
      <h4>
        <p *ngIf="alert.locations; else noLocation" [textContent]="alert.keywords + this.separator3 + alert.locations"></p>
        <ng-template #noLocation><p [textContent]="alert.keywords"></p></ng-template>
          <!--<span *ngIf="alert.compensationType" [textContent]="alert.compensationType|titlecase"></span>-->
        <span [textContent]="alert.lastediteddate|date: 'dd MMM yyyy'"></span>
      </h4>
      <div class="btn-cont">
        <button id="edit_alert_button" *ngIf="this.loggedIn" class="edit-alert" (click)="editSavedJobAlert(alert)" [disabled]="delete"></button>
        <a id="gtm_delete_job_alert_btn" class="icon-cancel" (click)="deleteSavedJobAlert(alert)"></a>

      </div>
    </li>
  </ul>

 <div class="row">
    <div class="col-12">
      <button id="edit_job_alert_btn" class="btn btn-secondary" [translate]="'manage_job_alert_button'" (click)="redirectToSearch()"></button>
    <button id="delete_all_job_alerts_btn" *ngIf="jobAlertResponse.length > 1" class="btn btn-default" [translate]="'delete_all_job_alerts_button'" (click)="openModal('delete-all-job-alerts')"></button>
    <app-erp-delete-all-job-alerts-popup [jobAlertResponse]="jobAlertResponse"></app-erp-delete-all-job-alerts-popup>
    </div>
  </div>
  <br>
</Section>
<!--//*ngIf="jobAlertResponse.length > 1"-->
