const  VIEW_FULL_SITE = "View Full Site";
const TERMS = "Terms";
const PRIVACY = "Privacy";
const HAYS_PLC = "© Hays plc 2022";

import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../environments/root/environment';
import {RequestService} from '../../../services/request/request.service';
import {clientConfigRequest, footerData, HeaderRequestJson} from '../../../models/header/header-menu';
import {SiteService} from '../../../services/site/site.service';
import {Router} from '@angular/router';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import { isPlatformServer, PlatformLocation } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public pageName: string;
  public liferaybaseUrl:string;
  public licenceLink: string;
  public userAgent: string;
  public showlicence: boolean;
  public showFooter: boolean;
  public footerData: footerData[]=[];
  public searchPage: string;
  public jobDetails: string;
  public orgName: string;
  public hostName: string;
  public footerLogoUrl: string;
  htsBaseUrl: any;
  isHtsRedirect: boolean;
  plc: any;
  public valiateOrgName:string;
  SubscriptionList:Subscription[]=[];
  constructor(private deviceDetector: DeviceDetectorService,public route:Router,private dataSharing: DataSharingService, private requestService : RequestService, private siteservice : SiteService,public router:Router,private jobSearchService:JobSearchService,private platformLocation: PlatformLocation,@Inject(PLATFORM_ID) private platformId: object) {
    this.showlicence=false;
    if(isPlatformServer(this.platformId)){
      this.hostName = this.platformLocation.hostname;
    }else {
       this.hostName = location.hostname;
    }
    this.orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this.htsBaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.htsBaseUrl];
    this.getRouteUrls();
    this.getClientConfig();
  }

  ngOnInit() {
    this.userAgent = this.deviceDetector.isMobile()?'-Mobile':'';
    const urlParams = new URL(window.location.href);
    this.valiateOrgName = urlParams.pathname.split('/')[1];
    const dataSharingGetWidgetListPageNameSubscription = this.dataSharing.getWidgetListPageName.subscribe((res) => {
      this.pageName = res;
      if (this.pageName === keywords.searchPageName){
          this.showFooter = true;
      }else if(this.pageName === keywords.jobDetailWidget) {
        if(this.userAgent != '-Mobile'){
         // this.footerData = null;
          this.getLicensedCountry();
          this.getFooter();
        }
        this.showFooter = true;
      }
      else{
        const dataSharingFooterShareSubscription = this.dataSharing.footerShare.subscribe(data=>{
          if(!data){
              this.getLicensedCountry();
              this.getFooter();
          }else {
            this.footerData=data['PageData']['Menu']['Bottom'];
          }
        });

        this.showFooter = true;
        this.SubscriptionList.push(dataSharingFooterShareSubscription);
      }
    });
    this.SubscriptionList.push(dataSharingGetWidgetListPageNameSubscription);

  }
  getLicensedCountry(){
    /*this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS && res.data) {*/
    const jobSearchServiceSharedConfigListSubscription = this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
      if(domainConfig && domainConfig.isLegalNameLicence) {
        this.licenceLink = domainConfig.licenceLink;
        this.showlicence=true;
        // this.footerData = null;
      }
    });

      /*}
    });*/
    this.SubscriptionList.push(jobSearchServiceSharedConfigListSubscription);

  }

  getFooter(){
     let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let json= new HeaderRequestJson('', environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.footer]
    );
    let endPoint=apiEndPoints.pagemanagement;

    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, json)
      .subscribe((res) => {
        if (res.status == config.SUCCESS && res.data) {
          if (orgName && orgName == config.HaysHTS) {
             this.isHtsRedirect = true;
            res.data['PageData']['Menu']['Bottom'].splice(-1);
            res.data['PageData']['Menu']['Bottom'].forEach(resource => {
              switch (resource.linkName) {
                case VIEW_FULL_SITE:
                  resource.resource_url = this.htsBaseUrl + resource.resource_url;
                  break;
                case TERMS:
                  resource.resource_url = this.liferaybaseUrl + resource.resource_url
                  break;
                case PRIVACY:
                  resource.resource_url = this.liferaybaseUrl + '/' + config.HaysCareer + resource.resource_url;
                  break;
                case "":
                  this.plc = resource.resource_name;
                  res.data['PageData']['Menu']['Bottom'].splice(-1);
                  break;
              }
              // this.isHtsRedirect = true;
              //   resource.resource_url = (resource.sortOrder == 1 && resource.linkName) ? this.htsBaseUrl + resource.resource_url :
              //   (resource.sortOrder == 2) ? this.liferaybaseUrl + resource.resource_url :
              //     (resource.sortOrder == 3) ? this.liferaybaseUrl + '/careers' + resource.resource_url : "";
              // this.plc = !resource.linkName ? resource.resource_name : "";
            })
          } else if (orgName) {
            res.data['PageData']['Menu']['Bottom'].forEach(resource => {
              if((orgName==this.valiateOrgName))
              {
                resource.resource_url = '/' + orgName + resource.resource_url;
              }
              else
              {
                resource.resource_url= '/'+ resource.resource_url.split('/')[1]  + resource.resource_url.replace(resource.resource_url.split('/')[1],this.orgName); 
              }
              
              //resource.resource_url = '/' + orgName + resource.resource_url;
            })
          }
          this.dataSharing.setFooterData(res.data);
          this.footerData = res.data['PageData']['Menu']['Bottom'];
        }
      })
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);

  }

  getRouteUrls() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    this.searchPage=environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
    this.jobDetails=environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];
    if(orgName) {
      this.searchPage=(environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search]).replace(':orgName', orgName);
      this.jobDetails=(environment[config.theme+this.siteservice.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails]).replace(':orgName', orgName);
    }
    if(orgName=='cognizant') {
      this.liferaybaseUrl = environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.liferayCognizantUrl];
    }
    else if(orgName == 'yourrailindustryjob' || orgName === 'geniuswithin'){
      this.liferaybaseUrl = environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    }
  }

  getClientConfig(){
    let payload = new clientConfigRequest(this.orgName? location.origin+'/'+this.orgName+'/' : location.origin+'/');
    if(isPlatformServer(this.platformId))
    payload = new clientConfigRequest(this.orgName? 'https://'+this.hostName+'/'+this.orgName+'/' : 'https://'+this.hostName+'/');
    if(this.hostName == 'localhost')
      payload = new clientConfigRequest(this.orgName? this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/'+this.orgName+'/'  : this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/');
    const requestServicePostRequestGateWaySubscription1 = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getClientConfig, payload)
      .subscribe(response => {
        if (response.status === config.SUCCESS && response.data) {
          this.dataSharing.sendClientConfig(response.data);
          this.footerLogoUrl = response.data['footerLogoUrl'];
        }
      }, error => {
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription1);

  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
