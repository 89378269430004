import {Component,Input, OnDestroy, OnInit} from '@angular/core';
import {RequestService} from '../../../../global/services/request/request.service';
import {JsonRequest} from '../../../model/response/office-locator';
import {map} from 'rxjs/operators';
import {SiteService} from '../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../environments/root/environment';
import {OfficeForSpecialism, OfficeForSpecialismAdapt, OfficeForSpecialismData} from '../../../model/response/office-for-specialism';
import {FormGroup} from '@angular/forms';
import {DataSharingService} from '../../../service/data-sharing.service';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-office-for-specialism',
  templateUrl: './jp-office-for-specialism.component.html',
  styleUrls: ['./jp-office-for-specialism.component.scss'],
  providers:[OfficeForSpecialismAdapt]
})
export class JpOfficeForSpecialismComponent implements OnInit, OnDestroy {
  @Input() specialismId : string;
  public officeList : OfficeForSpecialismData[];
  @Input() public parent: FormGroup;
  @Input() public officeSpecialism: string;
  public officeName: string;
  public isOfficeList: boolean;
  public isSectorClicked: boolean;
  @Input() public isAUSHaysOffice: boolean;
  public selectedSpecilsmValue: string;
  public updateYourDetailsUrl: string;
  public routing_url: any;
  SubscriptionList:Subscription[]=[];

  constructor(private officeForSpecialismAdapt:OfficeForSpecialismAdapt, private requestService:RequestService, private _translate : TranslateService,
              private siteService:SiteService,private dataSharing :DataSharingService,public router: Router) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.routing_url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    const dataSharingSharedIsSectorClickedSubscription= this.dataSharing.sharedIsSectorClicked.subscribe(isOfficeClicked=>{
      this.isSectorClicked=isOfficeClicked;
    });
    const dataSharingSharedOfficeListSubscription= this.dataSharing.sharedOfficeList.subscribe(officeList =>{
      this.officeList=officeList;
      if(this.officeList && this.officeList.length>0){
        this.officeList.forEach(office=>{
          if(this.isAUSHaysOffice)
            office.office_name=office.State +', '+office.office_name;
          if(office.office_id+':'+office.office_email_address===this.officeSpecialism){
            this.officeName=office.office_name;
          }
        })
        this.isOfficeList=true;
        this.isSectorClicked=false;
      }
    })
    this.updateYourDetailsUrl = this.routing_url.updateYourDetailsUrl
    this.SubscriptionList.push(dataSharingSharedIsSectorClickedSubscription);
    this.SubscriptionList.push(dataSharingSharedOfficeListSubscription);
  }

  ngOnChanges(): void {
    /*if(this.specialismId)
      this.getOfficeList();*/
  }

  getOfficeList(){
    let json= new JsonRequest(keywords.GetOfficeForSpecialism,this.specialismId);
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getOfficeForSpecialism,json).pipe(
      map((res: OfficeForSpecialism) => this.officeForSpecialismAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(res.data["results"].length >0) {
            this.officeList = res.data["results"];
            if(this.officeSpecialism){
              if(this.officeList && this.officeList.length>0){
                this.officeList.forEach(office=>{
                  if(office.office_id+':'+office.office_email_address===this.officeSpecialism){
                    this.officeName=office.office_name;
                  }
                })
              }
            }
          }
        }
      }, (error) => {
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }
  onChangeSpecilsm(event){
    this.selectedSpecilsmValue = event.target.value
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
