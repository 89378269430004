import {Component, EventEmitter, Input, OnInit, Output, SecurityContext} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse, JobSearchFilter} from '../../../../../model/response/google-job-response';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {formatDate} from '@angular/common';
import {JobSearchService} from '../../../../../service/job-search.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {SetTitleService} from '../../../../../service/set-title.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {
  @Input() jobList: GoogleJobResponse[] = [];
  @Input() extraConfig: ExtraFieldsConfig;
  @Input() noResultFound: boolean;
  @Input() keyWord: string;
  @Input() locationQuery: string;
  public location: string="";
  public industry: string;

  public specialismId: string;
  public subSpecialismId: string;
  private sortType: string;
  private jobType: string;
  private flexiWorkType: string;
  private minPay: string;
  private maxPay: string;
  public jobSource: string;
  public jobSearchFilterData: JobSearchFilter;
  private payTypeIndex: string;
  private jobSearchFilter: JobSearchFilter;
  public isWorkingPattern: boolean;
  public buttonClicked: boolean;
  @Output() public openSearchFilter = new EventEmitter<boolean>();
  private showFilter: boolean;
  public isScrolled: boolean;
  private searchPageTitle: string;
  private searchPageDesc: string;
  private jobDetailUrl: string;
  /**
   * Determines whether logo specific to job type as contract and microsite Id HAYSITTEST is to be displayed
   */
  public isMicrositeIdJobTypeLogoEnabled: boolean = false;
  /**
   * Captures the domain of site
   */
  private siteBaseURL: string = '';

  constructor(private sharedService: DataSharingService, private activatedRoutr: ActivatedRoute, private router: Router,
              private siteService: SiteService, private jobSearchService: JobSearchService, private translate: TranslateService, private requestService: RequestService,
              private xmlParser: NgxXml2jsonService, private tokenStorage: TokenStorage, private titleService: SetTitleService,
              private sanitizer: DomSanitizer) {
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyWord?this.keyWord+" ":" ";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        this.searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        this.searchPageDesc = res['search_page_description_text'];
      if (res['search_result_title1'])
        this.searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      if (res['meta_desc_text1'])
        this.searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];

    });
    if(router.navigated){
      if(sessionStorage.getItem("jobTypeFilter")){
        this.jobSearchFilterData = JSON.parse(sessionStorage.getItem("jobTypeFilter"));
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilterData);
      }
    }
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if(sessionStorage.getItem("jobTypeFilter")){
            this.jobSearchFilterData = JSON.parse(sessionStorage.getItem("jobTypeFilter"));
            this.jobSearchService.setJobSearchFilter(this.jobSearchFilterData);
          }
        }
      });
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    this.translate.getTranslation(siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyWord?this.keyWord+" ":" ";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        this.searchPageTitle = res['search_result_title'];
      if (res['search_result_title1'])
        this.searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      // this.titleService.setTitleForPage(this.searchPageTitle);
    });
    this.siteBaseURL = environment[config.theme + siteService.getSiteLocale()]['siteBaseURL'];
  }

  ngOnInit() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName)
      this.jobDetailUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails]).replace(':orgName',orgName);
    else
      this.jobDetailUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails]

    let defaultJobSource='';
    this.isMicrositeIdJobTypeLogoEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['micrositeIdContractTypeLogoEnabled'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['micrositeIdContractTypeLogoEnabled'] : false;
    this.sharedService.sharedClientConfig.subscribe(res => {
      if(res){
        defaultJobSource = res.jobSource;
      }
    } );

    this.activatedRoutr.queryParamMap.subscribe(param=>{

      if(!param.get("sortType")) {
        this.jobSearchFilter=new JobSearchFilter('','','0','','',
          '','','','false',
          'false','false','RELEVANCE_DESC','','','-1','-1','-1',
          '-1','0','');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
      }
      this.jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;

    });
    this.sharedService.sharedLocation.subscribe(location => this.location = location);
    this.jobSearchService.sharedIsScrolled.subscribe(isScrolled => this.isScrolled = isScrolled);
    this.sharedService.sharedIndustry.subscribe(industry => this.industry = industry);
    this.jobSearchService.sharedJobSearchFilterData.subscribe(jobSearchFilter => {
      /*if(!this.jobSearchFilterData) {*/
        this.jobSearchFilterData = jobSearchFilter;
       /* return;
      }*/
    });
    this.jobSearchService.sharedIsWorkingPattern.subscribe(isWorkingPattern => this.isWorkingPattern = isWorkingPattern);
    this.sharedService.sharedClientConfig.subscribe(res => {
      if(res){
        this.jobSource = res.jobSource;
      }
    } );
  }

  removeFilter(type: string) {
    switch (type) {
      case 'location':
        this.location = '';
        this.sharedService.sendLocation(this.location);
        this.jobSearchService.setSerchPerfomedValue(false);
        if (this.jobSearchFilterData) {
          this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, this.jobSearchFilterData.payTypeIndex,
            this.jobSearchFilterData.minSal,
            this.jobSearchFilterData.minSalValue,
            this.jobSearchFilterData.maxSal,
            this.jobSearchFilterData.maxSalValue
            , this.jobSearchFilterData.jobType,
            this.jobSearchFilterData.flexibleworking
            , this.jobSearchFilterData.fullTime,
            this.jobSearchFilterData.partTime
            , this.jobSearchFilterData.sortType,
            this.jobSearchFilterData.workingPattern, this.jobSearchFilterData.jobTypeSelected,
            this.jobSearchFilterData.workingTypeIndex, this.jobSearchFilterData.jobtypeIndex,
            this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex,
            this.jobSearchFilterData.sortTypeIndex, this.jobSearchFilterData.payTypeValue);
          this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
          //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(),
            this.jobSearchFilterData.jobtypeIndex, this.jobSearchFilterData.sortTypeIndex,
            this.jobSearchFilterData.workingTypeIndex, this.location, this.industry,
            this.jobSearchFilterData.payTypeIndex, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex, 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);

        } else {
          this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, '-1', '', '', '', '', '', 'false'
            , 'false', 'false', 'RELEVANCE_DESC', '', '', '-1', '-1', '-1', '-1',
            '0', '');
          this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
          sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
         // localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(),
            '-1', '0',
            '-1', this.location, this.industry,
            '-1', '-1', '-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        }

        break;
      case 'industry':
        this.industry = '';
        this.sharedService.sendIndustry(this.industry);
        this.jobSearchService.setSerchPerfomedValue(false);
        if (this.jobSearchFilterData) {
          this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, this.jobSearchFilterData.payTypeIndex,
            this.jobSearchFilterData.minSal,
            this.jobSearchFilterData.minSalValue,
            this.jobSearchFilterData.maxSal,
            this.jobSearchFilterData.maxSalValue
            , this.jobSearchFilterData.jobType,
            this.jobSearchFilterData.flexibleworking
            , this.jobSearchFilterData.fullTime,
            this.jobSearchFilterData.partTime
            , this.jobSearchFilterData.sortType,
            this.jobSearchFilterData.workingPattern, this.jobSearchFilterData.jobTypeSelected,
            this.jobSearchFilterData.workingTypeIndex, this.jobSearchFilterData.jobtypeIndex,
            this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex,
            this.jobSearchFilterData.sortTypeIndex, this.jobSearchFilterData.payTypeValue);
          this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
          // localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(),
            this.jobSearchFilterData.jobtypeIndex, this.jobSearchFilterData.sortTypeIndex,
            this.jobSearchFilterData.workingTypeIndex, this.location, this.industry,
            this.jobSearchFilterData.payTypeIndex, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex, 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);

        } else {
          this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, '-1', '', '', '', '', '', 'false'
            , 'false', 'false', 'RELEVANCE_DESC', '', '', '-1', '-1', '-1', '-1',
            '0', '');
          this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
         // localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
          this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(),
            '-1', '0',
            '-1', this.location, this.industry,
            '-1', '-1', '-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);

        }
        break;
      case 'jobType':
        this.jobType = '-1';
        this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, this.jobSearchFilterData.payTypeIndex, this.jobSearchFilterData.minSal, this.jobSearchFilterData.minSalValue,
          this.jobSearchFilterData.maxSal, this.jobSearchFilterData.maxSalValue, '', this.jobSearchFilterData.flexibleworking,
          this.jobSearchFilterData.fullTime, this.jobSearchFilterData.partTime, this.jobSearchFilterData.sortType, this.jobSearchFilterData.workingPattern,
          '', this.jobSearchFilterData.workingTypeIndex, this.jobType, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex,
          this.jobSearchFilterData.sortTypeIndex,
          this.jobSearchFilterData ? this.jobSearchFilterData.payTypeValue : '');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(), this.jobType,
          this.sortType, this.jobSearchFilterData.workingTypeIndex,
          this.location, this.industry, this.jobSearchFilterData.payTypeIndex, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex,
          'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
      case 'workingPattern':
        this.isWorkingPattern = false;
        this.flexiWorkType = '-1';
        this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, this.jobSearchFilterData.payTypeIndex, this.jobSearchFilterData.minSal, this.jobSearchFilterData.minSalValue,
          this.jobSearchFilterData.maxSal, this.jobSearchFilterData.maxSalValue, this.jobSearchFilterData.jobType, 'false',
          'false', 'false', this.jobSearchFilterData.sortType, '', this.jobSearchFilterData.jobTypeSelected,
          this.flexiWorkType, this.jobSearchFilterData.jobtypeIndex, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex, this.jobSearchFilterData.sortTypeIndex,
          this.jobSearchFilterData ? this.jobSearchFilterData.payTypeValue : '');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
       // localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(), this.jobSearchFilterData.jobtypeIndex,
          this.jobSearchFilterData.sortTypeIndex, this.flexiWorkType,
          this.location, this.industry, this.payTypeIndex, this.jobSearchFilterData.minPayIndex, this.jobSearchFilterData.maxPayIndex, 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
      case 'payType':
        this.payTypeIndex = '-1';
        this.minPay = '-1';
        this.maxPay = '-1';
        this.jobSearchFilter = new JobSearchFilter(this.location, this.industry, this.payTypeIndex, '', '',
          '', '', this.jobSearchFilterData.jobType, this.jobSearchFilterData.flexibleworking,
          this.jobSearchFilterData.fullTime, this.jobSearchFilterData.partTime, this.jobSearchFilterData.sortType, this.jobSearchFilterData.workingPattern,
          this.jobSearchFilterData.jobTypeSelected, this.jobSearchFilterData ? this.jobSearchFilterData.workingTypeIndex : '',
          this.jobSearchFilterData.payTypeIndex, this.minPay, this.maxPay, this.jobSearchFilterData.sortTypeIndex, '');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoutr, this.siteService.getSiteLocale(), this.jobSearchFilterData.jobtypeIndex,
          this.jobSearchFilterData.sortTypeIndex, this.flexiWorkType,
          this.location, this.industry, this.payTypeIndex, this.minPay, this.maxPay, 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
    }

  }

  isNewJob(createDate: any) {
    let currentDate = new Date();
    const formattedCurrentDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    let clientDate: any;
    let oldDateValue = formatDate(new Date(new Date().setDate(currentDate.getDate() - 3)), 'yyyy-MM-dd', 'en-US');
    clientDate = createDate.year + '-' + ('0' + (createDate.month)).slice(-2) + '-' + ('0' + createDate.day).slice(-2);
    return clientDate >= oldDateValue && clientDate <= formattedCurrentDate;
  }

  redirectToDetails(crossCountryUrl: string, jobRecordId: string, jobId: string, isSponsored: string, specialismId: string,
                    subSpecialismId: string, jobName: string, locale: string, domain: string, micrositeID: string, micrositeUrl: string) {
    if(micrositeID==='HAYSITTEST')
      window.location.href=micrositeUrl;
    else {
      if (this.jobList && this.jobList.length > 0) {
        this.jobList.forEach(job => {
          if (job.jobId === jobId)
            job.isLoading = true;
        })
      }

      let url = '';
      if (crossCountryUrl) {
        url = crossCountryUrl;
      }

      var localeRecordId = '';
      if (jobRecordId) {
        var xLocaleRecordId = jobRecordId.split('/');
        localeRecordId = xLocaleRecordId[xLocaleRecordId.length - 1];
      }
      if(!specialismId)
          specialismId='';
      if(!subSpecialismId)
          subSpecialismId='';
      if(!this.keyWord)
        this.keyWord='';
      if(!this.locationQuery)
        this.locationQuery='';
      let defaultJobSource='';
      this.sharedService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      this.activatedRoutr.queryParamMap.subscribe(param=>{
        this.jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      });

      if (!url) {
        if(this.router.url.indexOf(this.jobDetailUrl)>-1) {
          let jobDetailsURL = window.location.origin + this.jobDetailUrl + '/' + encodeURIComponent(localeRecordId) + '?q=' + encodeURIComponent(this.keyWord) + '&location=' + this.locationQuery + "&applyId=" + jobId + '&jobSource=' + this.jobSource +
            '&isSponsored=' + isSponsored + '&specialismId=' + specialismId + '&subSpecialismId=' + subSpecialismId + '&jobName=' + jobName+'&lang='+locale;
          window.location.href = jobDetailsURL;
        }else{
          this.router.navigate([this.jobDetailUrl, localeRecordId], {
            queryParams: {
              q: this.keyWord ? this.keyWord : '',
              location: this.locationQuery ? this.locationQuery : '',
              applyId: jobId,
              jobSource: this.jobSource ? this.jobSource : defaultJobSource,
              isSponsored: isSponsored,
              specialismId: specialismId,
              subSpecialismId: subSpecialismId,
              jobName: jobName,
              lang: locale
            }
          }).then(r => {
            // console.log('redirected to details');
            this.jobSearchService.setJobDetailResponse(null);
            this.jobSearchService.setJobResponse(null);
          });
        }
      } else {
        if (!this.keyWord)
          this.keyWord = '';
        if (!this.locationQuery)
          this.locationQuery = '';
        if (!specialismId)
          specialismId = '';
        if (!subSpecialismId)
          subSpecialismId = '';

        let crossUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];
        let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
        if(orgName)
          crossUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails]).replace(':orgName',orgName);
        /*crossUrl = '/' + domain + '/' + locale + crossUrl;*/
        crossUrl= url ? '/Job/Detail' : crossUrl;
        let crossSiteUrl = url + crossUrl + '/' + encodeURIComponent(localeRecordId) + '?q=' + this.keyWord + '&location=' + this.locationQuery
        + '&applyId=' + jobId + '&jobSource=' + this.jobSource + '&isSponsored=' + isSponsored + '&specialismId='
        + specialismId + '&subSpecialismId=' + subSpecialismId + '&jobName=' +
        jobName + "&lang=" + locale;
        window.location.href = crossSiteUrl;
      }
      this.buttonClicked = false;
    }
  }

  getJobDetailsUrl(crossCountryUrl: string, jobRecordId: string, jobId: string, isSponsored: string, specialismId: string,
                    subSpecialismId: string, jobName: string, locale: string,domain:string) {
    let url = '';
    if (crossCountryUrl) {
      url = crossCountryUrl;
    }

    var localeRecordId = '';
    if (jobRecordId) {
      var xLocaleRecordId = jobRecordId.split('/');
      localeRecordId = xLocaleRecordId[xLocaleRecordId.length - 1];
    }
      if(!this.keyWord)
        this.keyWord='';
      if(!this.locationQuery)
        this.locationQuery='';
      if(!specialismId)
        specialismId='';
      if(!subSpecialismId)
        subSpecialismId='';

    let crossUrl= url ? '/Job/Detail' :this.jobDetailUrl;

      /*crossUrl='/'+domain+'/'+locale+crossUrl;*/
      let crossSiteUrl=url+ crossUrl + '/' + encodeURIComponent(localeRecordId)+
        '?q=' + encodeURIComponent(this.keyWord)
        + '&location=' + this.locationQuery
        + '&applyId=' + jobId
        + '&jobSource=' + this.jobSource
        + '&isSponsored=' + isSponsored +
        '&specialismId=' + specialismId +
        '&subSpecialismId=' + subSpecialismId +
        '&jobName=' + jobName +
        "&lang=" + locale;
    //  window.location.href =crossSiteUrl;
    return crossSiteUrl;
  }

  openFilter() {
    this.showFilter = !this.showFilter;
    this.openSearchFilter.emit(this.showFilter);
  }

  getJobdetailRoutingLink(crossCountryUrl: string, job: GoogleJobResponse) {
    let url = '';
    if (crossCountryUrl) {
      url = crossCountryUrl;
    }
    var localeRecordId = '';
    if (job.jobRecordId) {
      var xLocaleRecordId = job.jobRecordId.split('/');
      localeRecordId = xLocaleRecordId[xLocaleRecordId.length - 1];
    }
    if(!this.keyWord)
      this.keyWord='';
    if(!this.locationQuery)
      this.locationQuery='';

  let routingUrl = this.siteBaseURL+this.jobDetailUrl+'/'+encodeURIComponent(localeRecordId)+'?q='+ encodeURIComponent(this.keyWord)+'&location='+this.locationQuery+"&applyId="+job.jobId+'&jobSource='+this.jobSource+
    '&isSponsored='+job.isSponsored+'&specialismId='+''+'&subSpecialismId='+''+'&jobName='+job.jobName+'&lang='+job.siteLocale.split('-')[0];
  return routingUrl;
  }
}
