import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { RequestService } from 'src/app/global/services/request/request.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { apiEndPoints, config, environment } from 'src/environments/root/environment';
import { SiteService } from 'src/app/global/services/site/site.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-privacy-acceptance-checkbox',
  templateUrl: './jp-privacy-acceptance-checkbox.component.html',
  styleUrls: ['./jp-privacy-acceptance-checkbox.component.scss']
})
export class JpPrivacyAcceptanceCheckboxComponent implements OnInit, OnDestroy {
  public confirmationText = [];
  public consentBox1 = [];
  requestServiceGetRequestSubscription:Subscription;
  @Input() public parent: FormGroup;
  @Output() checkBoxValidatorEvent = new EventEmitter<boolean>();
  constructor(private _requestService: RequestService, private _translate: TranslateService, private siteService: SiteService, private router: Router, @Inject(PLATFORM_ID) private platformId: object) {
    const siteLocale = this.siteService.getSiteLocale();
    this._translate.setDefaultLang(siteLocale);
    if(isPlatformBrowser(this.platformId)){
      this.getConfirmation();
    }
  }

  ngOnInit() {

  }
  getConfirmation() {
    let liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this.requestServiceGetRequestSubscription = this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getTermsAndConditions).subscribe(response => {
      if (response.status === config.SUCCESS && response.data) {
        this.confirmationText = response.data;
        let j = 1;
        this.confirmationText['checkBox1'].forEach((data, i) => {
          if (data['link'].indexOf('email') == -1) {
            data['link'] = liferayBaseUrl + data['link'];
          }
          data['consentText1'] = decodeURI(data['consentText1']);
          data['consentText2'] = decodeURI(data['consentText2']);
          if (i > 3 && i < 7) {
            this.parent.addControl('privacyconsent' + j, new FormControl('', { validators: [Validators.requiredTrue], updateOn: 'change' }));
            data['checkboxNo'] = j;
            j++;
          }
          this.consentBox1.push(data);
        });
      }
    }, (error) => {
    });
  }
  privacyCheck(event, type) {
    const isChecked = event.target.checked;
    if(type==='parentCheckBox'){
      this.parent.patchValue({
        privacyconsent1: isChecked,
        privacyconsent2: isChecked,
        privacyconsent3: isChecked,
      });
    }
    if(this.parent.controls.privacyconsent.valid && this.parent.controls.privacyconsent1.valid && this.parent.controls.privacyconsent2.valid && this.parent.controls.privacyconsent3.valid){
      this.checkBoxValidatorEvent.emit(true);
    }else {
      this.checkBoxValidatorEvent.emit(false);
    }
  }
  ngOnDestroy(): void {
    if(this.requestServiceGetRequestSubscription){
    this.requestServiceGetRequestSubscription.unsubscribe();}
  }
}

