<section class="marketing-preferences">
  <h2 [translate]="'erp_marketing_preference_heading_text'" *ngIf="isDataLoaded"></h2>

  <div class="box border" *ngIf="consentUpdated">
    <i class="icon-ok"></i><span [translate]="'erp_thankyou_text'"></span>
  </div>

  <div class="row" *ngIf="!consentUpdated">
    <div class="col-sm-12 col-md-6" *ngIf="isDataLoaded">
      <h3 [translate]="'erp_email_text'"></h3>
      <p style="white-space: pre-line;" [translate]="'erp_email_subheading_text_new'"></p>

      <div class="border panel-box">
        <p [translate]="'erp_like_to_recieve_email_text_new'"></p><br>
        <div class="switch-field">
          <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
            <div class="toggle-btn">
              <button id="yes_email" class="btn" [ngClass]="getConsentResponseData.eshot ? 'active' : ''" (click)="setService($event,'email')" [translate]="'erp_yes_text'" ></button>
              <button id="no_email" class="btn" [ngClass]="!getConsentResponseData.eshot ? 'active' : ''" (click)="setService($event,'email')" [translate]="'erp_no_text'"></button>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="col-sm-12 col-md-6" *ngIf="isDataLoaded">
      <h3 [translate]="'erp_mobile_heading_text'"></h3>
      <p class="heading-text" style="white-space: pre-line;" [translate]="'erp_mobile_sub_heading_text'"></p>
      <div class="border panel-box">
        <div class="option-box">
          <div class="toggle">
            <p [translate]="'erp_receive_mobile_sms_text'"></p><br>
            <div class="switch-field">
              <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic"  >
                <div class="toggle-btn">
                  <button id="yes_mobile" class="btn" [ngClass]="getConsentResponseData.sms ? 'active' : ''" (click)="setService($event,'sms')" [translate]="'erp_yes_text'" >
                  </button>
                  <button id="no_mobile" class="btn" [ngClass]="!getConsentResponseData.sms ? 'active' : ''" (click)="setService($event,'sms')" [translate]="'erp_no_text'">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-sm-12" *ngIf="isDataLoaded">
      <p class="other-info" [translate]="'erp_email_share_interest_text_new'"></p>
    </div>

    <div class="col-sm-12 col-md-6"  *ngIf="isDataLoaded">
      <div class="border panel-box mt-3">
        <div class="job-alert">
          <p [translate]="'erp_job_alert_head_text'"></p>
          <div>
            <!--<p class="email-second-head" [translate]="'erp_job_alert_subhead_text'"></p>-->
            {{'erp_job_alert_subhead_text_1' | translate}}  <a id="gtm_email_confm_login_redirect" [href]="manageJobAlertUrl" [translate]="'erp_job_alert_subhead_text_2'"></a> {{'erp_job_alert_subhead_text_3' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6"  *ngIf="isDataLoaded">
      <div class="border panel-box mt-3 save-info">
        <span class="save-icon save" [translate]="'erp_save_your_changes_text'"></span>
        <span class="save-btn text-right">
            <button type="submit" [ngClass]="updateClicked?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" id="saveButton" [disabled]="buttonDisable" (click)="updateMarketingPref()" [translate]="'erp_save_text'"></button>
        </span>
      </div>
      <p class="other-info" [translate]="'erp_sms_notification_text'"></p>
    </div>

  </div>


  <div class="col-sm-12"  *ngIf="this.isDataLoaded || this.consentUpdated">
      <h3 [translate]="'privacy_policy_heading'"></h3>
      <p class="heading-text">{{'privacy_policy_text_1'|translate}}
          <a id="privacy_policy_link" [href]="this.privacyPolicyData?.resource_url" target="_blank">{{this.privacyPolicyData?.linkName}}</a>
          {{'privacy_policy_text_3'|translate}}</p>
  </div>


</section>
