<div class="job-box bg-white" *ngIf="consultantDetails && consultantDetails.length>0 && configData">
  <h3 [translate]="'talk_to_consultant_label'"></h3>
  <div *ngIf="consultantDetails[0].jobOwner.JobOwner">
    <div *ngIf="(consultantDetails[0].jobOwner.JobOwner.name | json)!='{}'">{{'talk_to_text1' | translate}} <strong id="gtm_jobowner_name">{{consultantDetails[0].jobOwner.JobOwner.name}}</strong>
      <span *ngIf="configData.showConsultantDelimeter">, </span> <span [translate]="'talk_to_text2'"></span>
      <span *ngIf="configData.showConsultantDelimeter && jobOwnerLength!==0">, </span>
      <span *ngIf="jobOwnerLength!==0">{{'talk_to_text3' | translate}} </span><strong id="gtm_jobowner_location" *ngIf="jobOwnerLength!==0">{{consultantDetails[0].jobOwner.JobOwner.location}}</strong></div>
    <div *ngIf="(consultantDetails[0].jobOwner.JobOwner.address | json)!='{}' && (consultantDetails[0].jobOwner.JobOwner.name | json)!='{}'">
      <span *ngIf="configData.showConsultantFullAddress">{{consultantDetails[0].jobOwner.JobOwner.address}} </span>
      <span *ngIf="!configData.showConsultantFullAddress && jobOwnerLength!==0">
      <span *ngFor="let add of  getAddress(consultantDetails[0].jobOwner.JobOwner.address); index as i">
              <span *ngIf="getAddress(consultantDetails[0].jobOwner.JobOwner.address).length-1 ===add">{{add}}</span>
        <span *ngIf="getAddress(consultantDetails[0].jobOwner.JobOwner.address).length-1 !==add && add">{{add}}<em *ngIf="i !== getAddress(consultantDetails[0].jobOwner.JobOwner.address).length-1">,</em></span>
      </span>
    </span>
    </div>
    <p *ngIf="(consultantDetails[0].jobOwner.JobOwner.tel | json)!='{}'">{{'telephone' | translate}}: <a  class="consultant-phone" [href]="'tel:'+consultantDetails[0].jobOwner.JobOwner.tel" [title]="'call '+consultantDetails[0].jobOwner.JobOwner.tel" id="jd_telephone">
      <strong [textContent]="consultantDetails[0].jobOwner.JobOwner.tel"></strong> </a>
    </p>
  </div>
  <div id = 'accreditation-number-details' *ngIf = 'consultantDetails[0]?.accreditationNumber' [innerHTML] = 'consultantDetails[0]?.accreditationNumber'>

  </div>
</div>