import {Component, OnDestroy, OnInit} from '@angular/core';
import {RequestService} from '../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {Router} from '@angular/router';
import {JobAlertResponse} from '../../../../model/response/job-alert-response';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {GetSavedJobAlertRequest} from '../../../../../erp-integration/model/request/erp-job-alert';
import {CookieService} from 'ngx-cookie-service';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-my-searches',
  templateUrl: './jp-my-searches.component.html',
  styleUrls: ['./jp-my-searches.component.scss']
})
export class JpMySearchesComponent implements OnInit, OnDestroy {
  jobAlertResponse: JobAlertResponse[]=[];
  public showAlertList: boolean;
  private confirmMsg: string;
  public delete: boolean;
  private orgName : string;
  SubscriptionList:Subscription[]=[];
  constructor(private requestService:RequestService,private dataSharingService: DataSharingService,private siteService:SiteService,
              private translateService:TranslateService,private router:Router, private cookieService: CookieService, private titleService:SetTitleService) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
    const translateServiceGetTranslationSubscription = this.translateService.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.confirmMsg = (res['confirm_job_alert_removal_msg']);
      if(res['job_alert_title']){
        this.titleService.setTitleForPage(res['job_alert_title']);
      }
    });
    this.dataSharingService.getWidgetListName(keywords.savedSearchesWidget);
    this.SubscriptionList.push(translateServiceGetTranslationSubscription);
  }

  ngOnInit() {
    this.getJobAlerts();
  }

  private getJobAlerts() {
    this.orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let payload = new GetSavedJobAlertRequest('', keywords.haysGCJ);
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getJobAlerts,payload).subscribe(response => {
      if (response.status === config.SUCCESS && response.data) {
        this.jobAlertResponse=response.data.alertprofileList;
        if(this.jobAlertResponse.length>0){
          this.showAlertList=true;
          let alertList = [ ];
          this.jobAlertResponse.forEach(item => alertList.push(item.alertname));
          this.cookieService.set('alerts', alertList.join('|'), 0,'/');
        }
        else{
          this.showAlertList = false;
          this.jobAlertResponse=undefined;
        }
      }else {
        this.showAlertList = false;
        this.jobAlertResponse=undefined;
      }

    }, error => {
      let errorRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
      if(this.orgName){
        errorRoute = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName',this.orgName);
      }
      this.router.navigate([errorRoute],{queryParams:{getSavedAlert:'N'}})
    });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  deleteJobAlert(alert: JobAlertResponse) {
    if(confirm(this.confirmMsg)) {
      this.delete=true;
      let deleteJson = {"alertId": alert.alertprofileid};
      const deleteJobAlertRequestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.deleteJobAlert, deleteJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          this.jobAlertResponse.forEach((jobAlert,index) => {
            if (jobAlert.alertprofileid === alert.alertprofileid) {
              this.jobAlertResponse.splice(index, 1);
            }
          });
          if(this.jobAlertResponse && this.jobAlertResponse.length===0)
            this.jobAlertResponse=undefined;
          this.delete=false;

        }
      }, error => {
        let errorRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
        if(this.orgName){
          errorRoute = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName',this.orgName);
        }
        this.router.navigate([errorRoute],{queryParams:{deleteJobAlert:'N'}})
        this.delete=false;

      })
      this.SubscriptionList.push(deleteJobAlertRequestServicePostRequestGateWaySubscription);
    }
  }

  editJobAlert(alert: JobAlertResponse) {
    let baseSearchUrl = [environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search]];
    if(this.orgName)
      baseSearchUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search]).replace(':orgName',this.orgName);
    let searchURL = window.location.origin + baseSearchUrl + "?alertid="+alert.alertprofileid+"&q="+alert.alertname;
    window.location.href=searchURL;


  }
  ngOnDestroy(){
    this.jobAlertResponse=undefined;
    this.showAlertList=false;
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){
          subscribe.unsubscribe();
      }
      });
    }   
}
