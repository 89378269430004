import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RequestService} from "../../../global/services/request/request.service";
import {SiteService} from "../../../global/services/site/site.service";
import {apiEndPoints, config, environment, keywords, routingEndpoint} from "../../../../environments/root/environment";
import {DecryptIdentifierJsonRequest} from "../../model/request/erp-decrypt-identifier";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-secure-gateway',
  templateUrl: './secure-gateway.component.html',
  styleUrls: ['./secure-gateway.component.scss']
})
export class SecureGatewayComponent implements OnInit, OnDestroy {

  public jobAlert: string;
  public marketingPref: string;
  public errorRoute: string;
  SubscriptionList:Subscription[]=[];


  constructor(private activatedRoute : ActivatedRoute,
              private requestService: RequestService,
              private _router: Router,
              private siteService:SiteService
  ) {
  }

  ngOnInit() {
    this.decryptSFMCIdentifier();
  }

  private decryptSFMCIdentifier()
  {
    this.getRouteUrls();
    const activatedRouteQueryParamSubscription=this.activatedRoute.queryParams.subscribe(params => {
      let identifier = params['identifier'];
      if(identifier && identifier.length>0) {
        if(this._router.url.indexOf('alert-secure-gateway')> -1)
          this.getSignedUrl(identifier, this.jobAlert);
        else
          this.getSignedUrl(identifier, this.marketingPref);
      }
      else{
        this._router.navigate([this.errorRoute],{queryParams:{erpExpire:'Y'}});
      }
    });
    this.SubscriptionList.push(activatedRouteQueryParamSubscription);
  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.jobAlert = (base[routingEndpoint.erpManageJobAlert]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
      this.marketingPref = (base[routingEndpoint.erpMarketingPref]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.jobAlert = base[routingEndpoint.erpManageJobAlert];
      this.errorRoute = base[routingEndpoint.errorPage];
      this.marketingPref = base[routingEndpoint.erpMarketingPref];
    }
  }

  private getSignedUrl(identifier,appUrl)
  {
    let encryptedIdentifier= localStorage.getItem("encryptedIdentifier");
    let jsonBody =null;
    if(encryptedIdentifier)
    jsonBody = new DecryptIdentifierJsonRequest(encryptedIdentifier, appUrl);
    else jsonBody = new DecryptIdentifierJsonRequest(identifier, appUrl);
    
   const requestServicepostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.signedURL, jsonBody)
      .subscribe( response => {
        localStorage.removeItem("encryptedIdentifier");
        if(response.status === config.SUCCESS && response.data && response.data.length>0) {
          if(appUrl.indexOf('alert') > -1){
            this._router.navigate([this.jobAlert], { queryParams: { "signedurl": response.data, "jobSource": keywords.hays } });
          }else{
            this._router.navigate([this.marketingPref], { queryParams: { "signedurl": response.data } });
          }
        } else {
          this._router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
        }
      },error => {
        this._router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
      });
      this.SubscriptionList.push(requestServicepostRequestGateWaySubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
