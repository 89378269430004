import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiteService } from 'src/app/global/services/site/site.service';
import { RequestService } from 'src/app/global/services/request/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Register } from 'src/app/jobportal/model/request/register';
import { DataSharingService } from 'src/app/jobportal/service/data-sharing.service';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {apiEndPoints, config, environment, keywords, routingEndpoint} from "../../../../../../../../environments/root/environment";
import {DecryptIdentifierJsonRequest} from "../../../../../../model/response/decrypt-identifier";
import {isPlatformBrowser} from "@angular/common";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-th-privacy-policy-update',
  templateUrl: './th-privacy-policy-update.component.html',
  styleUrls: ['./th-privacy-policy-update.component.scss']
})
export class ThPrivacyPolicyUpdateComponent implements OnInit, OnDestroy {
  private errorRoute: string;
  public signUpFormGroup: FormGroup;
  public confirmationText = [];
  public consentBox1 = [];
  @Input() public parent: FormGroup;
  public siteLocale: any;
  public isCaptchaDisable = true;
  public formSubmitted = false;
  public disableButton: boolean;
  public firstName: string;
  public lastName: string;
  public candidateOTID: null;
  public emailAdd: string;
  public identifier: string;
  public trainingId: string="";
  public trainingUser: string="";
  private errorPageRoute: string;
  private successPageRoute: string;
  public displayPopUp = false;
  public newsLetterPopup= false;
  public isCheckBoxChecked = false;
  public newsLetterSubscription:string="";
  public whatsupconsents: string="";
  public eshotconsent: string="";
  public smsconsent: string="";
  router: any;
  forgetPasswordRoute: any;
  viewMode = 'tab2';
  public showError=false;
  SubscriptionList:Subscription[]=[];
constructor(private _translate: TranslateService,public siteService: SiteService,
  private _requestService: RequestService,private _router: Router,
  private activatedRoute: ActivatedRoute,private titleService: SetTitleService,
  public dataShareService: DataSharingService, @Inject(PLATFORM_ID) private platformId: object) {
  if (localStorage.getItem('domain') !== 'TH') {
    const base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    const errorPage = base[routingEndpoint.errorPage];
    this._router.navigate([errorPage], { queryParams: { 'orgName': 'Incorrect' } });
  }
  this.parent = new FormGroup({})
const activatedRouteQueryParamsSubscription= this.activatedRoute.queryParams.subscribe((params)=> {
  if(params['identifier']) {
        this.decryptIdentifier(params['identifier']);
      }
    });
  const siteLocale = this.siteService.getSiteLocale();
  this._translate.setDefaultLang(siteLocale);
  if(isPlatformBrowser(this.platformId)){
    this.getConfirmation();
  }
  this.SubscriptionList.push(activatedRouteQueryParamsSubscription);

  }
  ngOnInit(): void {
    this.checkCaptchaDisabled();
  this.createFormGroup();
  }
  private checkCaptchaDisabled() {
    const checkCaptchaDisabledRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isCaptchaDisabled,null,null).pipe().
    subscribe(response => {
      localStorage.setItem("disableCaptcha",response.data);
      this.dataShareService.setIsCaptchaDIsabled(response.data.toString());
    });
  }

  getConfirmation() {
    let liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    const getConfirmationRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getTermsAndConditions).subscribe(response => {
      if (response.status === config.SUCCESS && response.data) {
        this.confirmationText = response.data;
        let consentCheckBoxIndex = 4;
        this.confirmationText['checkBox1'].forEach((data, i) => {
          if (data['link'].indexOf('email') == -1) {
            data['link'] = liferayBaseUrl + data['link'];
          }
          data['consentText1'] = decodeURI(data['consentText1']);
          data['consentText2'] = decodeURI(data['consentText2']);
          if (i > 4 && i < 8) {
            consentCheckBoxIndex++;
            data['consentCheckBoxIndex'] = consentCheckBoxIndex;
            this.parent.addControl(`privacyconsent${consentCheckBoxIndex}`, new FormControl('', { validators : [Validators.requiredTrue], updateOn : 'change'}));          }
          this.consentBox1.push(data);
        });
      }
    }, (error) => {
    });
    this.SubscriptionList.push(getConfirmationRequestServiceGetRequestSubscription);
  }

  protected createFormGroup(): void {
    this.signUpFormGroup = new FormGroup({
      privacyconsent: new FormControl(false, {
          validators: [Validators.requiredTrue],
          updateOn: 'change'
        }
      ),
      reCaptchaReactive: new FormControl('',

      { updateOn: 'change' }
      ),
    });
    // if (localStorage.getItem('disableCaptcha') === 'true') {
    //   this.isCaptchaDisable = true;
    // }
    // if (this.isCaptchaDisable && this.signUpFormGroup) {
    //   this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
    // }
    if(localStorage.getItem("disableCaptcha")) {
      if(localStorage.getItem("disableCaptcha") == "true") {
        this.isCaptchaDisable = true;
      } else {
        this.isCaptchaDisable = false;
        this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
      }
    }
    this.dataShareService.sharedIsCaptchaDisabled.subscribe(res => {
      if (res == "true") {
        this.isCaptchaDisable = true;
      } else if(res == "false") {
        this.isCaptchaDisable = false;
        this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
        this.signUpFormGroup.get('reCaptchaReactive').updateValueAndValidity();
      }
    }) 
  }

  decryptIdentifier(identifier){
    let jsonData= new DecryptIdentifierJsonRequest(identifier,'ThailandExpTime');
    const decryptIdentifierRequestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.decryptIdentifier,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if (res.data['statusCode']==='200') {
            let result=JSON.parse(res.data.data);
            this.firstName=result.split('FirstName:')[1].split(',')[0].replace(' ','');
            this.lastName=result.split('LastName:')[1].split(',')[0].replace(' ','');
            this.candidateOTID=result.split('CandidateOTID:')[1].split(',')[0].replace(' ','');
            this.emailAdd=result.split('EmailAddress:')[1].split(',')[0].replace(' ','');
            if(res.data['statusCode'] === config.expireCode && res.data === config.expireMsg){
              this.router.navigate([this.forgetPasswordRoute], {
                queryParams: {trainingUser: this.trainingUser,trainingId: this.trainingId, status: 'expired' }
              });
            }
          }else {
            this.router.navigate([this.successPageRoute], { queryParams: { ThailandPrivacyPolicy: 'Y' } });
          }
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{resetFlag:keywords.invalid}});
      });
      this.SubscriptionList.push(decryptIdentifierRequestServicePostRequestGateWaySubscription);
  }

  submitPrivacy() {
    let cvData: any='';
    if(this.newsLetterSubscription=='Y'){      
      this.whatsupconsents="Y";
      this.smsconsent="Y";
      this.eshotconsent="Y";
     }
     else{
       
       this.whatsupconsents="N";
       this.smsconsent="N";
       this.eshotconsent="N";
     }
    let payload = new Register(cvData, '', '',this.emailAdd, this.signUpFormGroup.value.privacyconsent, null, null,
    this.eshotconsent, null, this.firstName, this.signUpFormGroup.value.reCaptchaReactive,
    null, null, this.lastName, this.newsLetterSubscription.toString(), null, null, this.smsconsent, null, this.trainingUser, null, this.trainingId, null, null,null, 'ThailandPrivacyPolicy', null,this.candidateOTID);
    const base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this.successPageRoute = base[routingEndpoint.successPage];
    this.errorPageRoute = base[routingEndpoint.successPage];
    const submitPrivacyRequestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.register, payload).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this._router.navigate([this.successPageRoute], { queryParams: { ThailandPrivacyPolicy: 'Y' } });
      } else {
        this._router.navigate([this.errorPageRoute], { queryParams: { ThailandPrivacyPolicy: 'N', error: response.status } });
      }
    }, (error) => {
      this._router.navigate([this.errorPageRoute], { queryParams: { ThailandPrivacyPolicy: 'N', error: error.status } });
    });
    this.SubscriptionList.push(submitPrivacyRequestServicePostRequestGateWaySubscription);
  }

  updatePrivacyAcceptance() {
    this.formSubmitted = true;
    if (this.isCheckBoxChecked && this.signUpFormGroup.valid && this.newsLetterSubscription) {
      this.disableButton = true;
      // this.initRegister();
      this.submitPrivacy()
    }

    else {
      if (!this.isCheckBoxChecked) {
        this.displayPopUp = true;

      }
      else if(!this.newsLetterSubscription){
        this.newsLetterPopup=true;
      }

    }
  }
  checkNewsSub(){
  if(!this.newsLetterSubscription){
    this.showError=true;
  } else{
      this.closePopUp()
    }
  }
  public whitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

 closePopUp() {
    this.displayPopUp = false;
    this.newsLetterPopup=false;
  }

  public consentBoxChangeTH(){
    if(this.parent.controls.privacyconsent5.value && this.parent.controls.privacyconsent6.value && this.parent.controls.privacyconsent7.value){
     this.isCheckBoxChecked = true;
    }
    else{
      this.isCheckBoxChecked = false;
    }
    this.signUpFormGroup.patchValue({
      privacyconsent: this.isCheckBoxChecked
    })
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
