import {Component, Input, OnInit,} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {environment} from '../../../../../environments/root/environment';

@Component({
  selector: 'app-jp-captcha',
  templateUrl: './jp-captcha.component.html',
  styleUrls: ['./jp-captcha.component.scss']
})
export class JpCaptchaComponent implements OnInit {

  @Input() parent: FormGroup;
  public secretKey="";

  constructor() {
  }

  ngOnInit() {
    this.secretKey = environment["google_captcha_client_key"];
  }

}
