import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {OfficeLocatorAdapt, OfficeLocatorData, SelectedLocationJsonRequest} from '../../../model/response/office-locator';
import {map} from 'rxjs/operators';
import {RequestService} from '../../../../global/services/request/request.service';
import {AutocompleteService} from '../../../service/autocomplete.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {AuStateList, AuStateListData, AuStateListDataAdapt} from '../../../model/response/au-state-list';
import {apiEndPoints, config, keywords} from '../../../../../environments/root/environment';
import {DataSharingService} from '../../../service/data-sharing.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-jp-office',
  templateUrl: './jp-office.component.html',
  styleUrls: ['./jp-office.component.scss'],
  providers:[OfficeLocatorAdapt,AuStateListDataAdapt]
})
export class JpOfficeComponent implements OnInit, OnDestroy {
  public locationList:OfficeLocatorData[];
  public auStateList:AuStateListData[];
  public showStatelist :boolean;
  public latitude: number;
  public longitude: number;
  public address: string="";
  public siteLocale: string="";
  public selectedLocationJson: any;
  @Output() selectedlocation = new EventEmitter<any>();
  public validationMessage:boolean;
  public showGeocoderTextBox:boolean;
  public locationPlaceholder:string="";
  @ViewChild("search", {static: false}) searchElementRef : ElementRef;
  public showLocationList: boolean;
  @Output() showLoaderOffice = new EventEmitter<any>();
  SubscriptionList:Subscription[]=[];
  constructor(private requestService:RequestService,private officeLocatorAdapt : OfficeLocatorAdapt,
              private auStateListDataAdapt :AuStateListDataAdapt,
              private autoCompleteService: AutocompleteService,private siteService: SiteService,
              private dataSharingService: DataSharingService,
              private _translate: TranslateService) {
    this.siteLocale=this.siteService.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
    this.locationPlaceholder=keywords.officeLocPlaceholder;
    
  }
  ngOnInit(){
    this.showGeocoderTextBox=true;
    this.validationMessage=false;
    const dataSharingServiceValidationSubscription= this.dataSharingService.validation.subscribe(clear => {
      this.validationMessage = clear;
    });
    this.autoSuggestionDisabled();
    this.SubscriptionList.push(dataSharingServiceValidationSubscription);
  }

  autoSuggestionDisabled(){
    const autoSuggestionDisabledRequestServiceGetRequestSubscription= this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isAutoSugesstionEnabled,null,null).pipe(
     map((res: Location) => this.officeLocatorAdapt.adapt(res)))
     .subscribe((res) => {
        if (res.status === config.SUCCESS) {
          this.showGeocoderTextBox=true;
          this.showLoaderOffice.emit(false);
          this.autoCompleteServiceCall();
        }else if(res.status===config.notFoundStatus){
          this.showGeocoderTextBox=false;
          this.getStateList();
          this.getApacLocationList();
        }
      }, (error) => {
       this.showGeocoderTextBox=false;
       this.showLoaderOffice.emit(false);
      });
      this.SubscriptionList.push(autoSuggestionDisabledRequestServiceGetRequestSubscription);
  }
  getStateList(){
    const getStateListRequestServiceGetRequestSubscription= this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getStateList,null,null).pipe(
     map((res: AuStateList) => this.auStateListDataAdapt.adapt(res)))
     .subscribe((res) => {
        if (res.status === config.SUCCESS) {

          if(res.data && res.data["results"].length >0 && res.data['statusCode']==='UC001') {
            this.showStatelist=true;
            this.showLoaderOffice.emit(false);
            this.auStateList = res.data["results"];
            this.selectedLocationJson = new SelectedLocationJsonRequest(this.auStateList[0].State_id, this.auStateList[0].state_description);
            this.selectedlocation.emit(this.selectedLocationJson);
          }
         }else{
          this.showStatelist=false;
          this.showLoaderOffice.emit(false);
        }

      }, (error) => {
       this.showStatelist=false;
       this.showLoaderOffice.emit(false);
  });  this.SubscriptionList.push(getStateListRequestServiceGetRequestSubscription);

  }
  getApacLocationList(){
   const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getLocationHierarchy,null,null).pipe(
      map((res: OfficeLocatorData) => this.officeLocatorAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS) {
          this.showLocationList = true;
          this.showLoaderOffice.emit(false);
          if(res.data && res.data["results"].length >0) {
            this.locationList = res.data["results"];
            this.selectedLocationJson = new SelectedLocationJsonRequest(this.locationList[0].LOCATION_ID, this.locationList[0].LOCATION_DESC);
            this.selectedlocation.emit(this.selectedLocationJson);
          }
        }else{
          this.showLocationList = false;
          this.showLoaderOffice.emit(false);
        }
      }, (error) => {
        this.showLocationList = false;
        this.showLoaderOffice.emit(false);
      });
      this.SubscriptionList.push(requestServiceGetRequestSubscription);

    }
  autoCompleteServiceCall(){
    this.autoCompleteService.autoCompleteLocationCall(this.searchElementRef);
    const autoCompleteServiceLongitudeSubscription= this.autoCompleteService.long.subscribe(longitude => {
      this.longitude = longitude;
    });
    const autoCompleteServiceLatitudeSubscription= this.autoCompleteService.lat.subscribe(latitude => {
      this.latitude = latitude;
    });
    this.SubscriptionList.push(autoCompleteServiceLongitudeSubscription);
    this.SubscriptionList.push(autoCompleteServiceLatitudeSubscription);
  }
  selectedLocation(event){
    let location=event.currentTarget.value;
    let locationArray=location.split("_");
    this.selectedLocationJson=new SelectedLocationJsonRequest(locationArray[0],locationArray[1]);
    this.selectedlocation.emit(this.selectedLocationJson);
  }

  keyUp(){
    this.dataSharingService.onClick(false);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
