import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { config, environment } from 'src/environments/root/environment';
import { SiteService } from '../site/site.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AzureGaService {
  /**
   * Captures the observable to which user subscribes
   */
  public azureURLObservable: Subject<string> = new Subject<string>();
  /**
   * Captures azure login URL value
   */
  public azureLoginURL: string = '';
  /**
   * Determines whether interval is executing
   * It will be used within component before calling the generateAzureURL, to enhance performance
   */
  public isIntervalExecuting: boolean = false;
  /**
   * Captures the ga cookie value
   */
  public gaCookieValue: string = '';

  constructor(private siteService: SiteService,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: object) { 
  }
  /**
   * Method to be called to return azure URL
   * Checks if cookie named _ga exists and has value, then add it in URL as query param and return
   * If not then return the URL retrieved from country config
   * @returns 
   */
  public generateAzureURL(){
    let azureURL: string = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
    let gaCookieValue: string = this.cookieService.get('_ga');
    if(!gaCookieValue && isPlatformBrowser(this.platformId)){
      this.isIntervalExecuting = true;
      window.setTimeout(() => {
        gaCookieValue = this.cookieService.get('_ga');
        this.isIntervalExecuting = false;
        this.setAndEmitURL(azureURL, gaCookieValue);
      }, 2000);
    }
    else{
      this.setAndEmitURL(azureURL, gaCookieValue);
    }
  }
  /**
   * Method to be called to perform operations on GA value and append to the azure URL
   * @param gaCookieValue 
   * @param azureURL 
   * @returns 
   */
  private setGAValue(gaCookieValue: string, azureURL: string) : string{
    this.gaCookieValue = this.getGAValue();
    azureURL = `${azureURL}&_ga=${this.gaCookieValue}`;
    return azureURL;
  }
  /**
   * Method to be called to assign azure URL to class property and emit the value
   * @param azureURL 
   * @param gaCookieValue 
   */
  private setAndEmitURL(azureURL: string, gaCookieValue: string){
    this.azureLoginURL = this.setGAValue(gaCookieValue, azureURL);
    this.azureLoginURL = this.updateAndGetAzureURL();
    this.azureURLObservable.next(this.azureLoginURL);
  }
  /**
   * Method to be called to add orgname to the azure URL 
   * @returns 
   */
  public updateAndGetAzureURL() : string{
    let azureURL: string = this.azureLoginURL;
    if(azureURL){
      let orgName = this.siteService.getOrgName();
      if(orgName === 'jobportal'){
        orgName = 'hays';
      }
      if(azureURL.indexOf('{orgName}') > -1){
        azureURL = azureURL.replace('{orgName}', orgName);
      }
      return azureURL;
    }
  }
  /**
   * Gets the GA value and returns it
   * @returns 
   */
  private getGAValue(){
    if(isPlatformBrowser(this.platformId)){
      const ga = window[window['GoogleAnalyticsObject']];
      if(ga && typeof ga['getAll'] === 'function'){
        const linkerParam = ga['getAll']()[0].get('linkerParam').split('_ga=')[1];
        return linkerParam;
      }
    }
  }
}
