export const baseUrl = {
    // apiBaseUrl:'https://moatapi.hays.com.au/jobportalapi'
    apiBaseUrl:'https://moatapi.hays.com.au/jobportalwr',
    apiBaseUrlWrapper: 'https://moatapi.hays.com.au/jobportalwr'
  };
  export const en_TH = {
    config:{
      countryCode:', Thailand',
      country_name: 'Thailand',
      gtm_code:"GTM-TNT2TL9",
      timesheetUrl:"https://www1.hays.com.au/haysonline/Login.aspx",
      salaryGuideUrl:"http://salaryguide.hays.com.au/",
      view_google_link:'http://maps.google.com.au/maps?q=',
      haystech_register_vacancy_link:'https://www.hays.co.th/it/register-a-vacancy',
      haystech_enquire_now_link:'https://www.hays.co.th/it/rpo-recruitment',
      zopim_enabled:false,
      zopim_enabled_device:'-Desktop',
      zopim_key_withURL:"//v2.zopim.com/?2SfCzrC8fQC6u5Mn6KEr6KMcqQoU4nJL",
      maximumSize:2097152,
      appleItunesMetaEnabled: true,
      isGo1Enabled:true,
      azure_enable:true,
     // auzureLoginURL : 'https://moat.hays.co.th/prjsauthapp/authurl/JobPortal/{orgName}/candidate/th/en',
      auzureLoginURL : 'https://testsg.hays.co.th/portal/auth?application=jobportal&client={orgName}&entityType=candidate&domain=th&locale=en&hostname=testsg&action=&workFlow=',
      dynamicPageTitleMetaTags: true,
      canonicalSearchLinkCompleteURL: false,
      flowSpecificConsentStatement: true,
      indeedAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeed/',
      addAutoBlockAssetScriptEnabled: true,
      addAutoBlockOptOutScriptEnabled: true,
      isCaptchaValueEnabled: false,
      isResubscibeEnabled: false
    },
    liferaybaseUrl: 'https://testsg.hays.co.th',
    liferayUrl: 'https://testsg.hays.co.th',
    cookieDomain:'.hays.co.th',
    cookieBannerDomain:'.hays.co.th',
    siteBaseURL: 'https://testsg.hays.co.th',
    gateWayAPI:{
      location: baseUrl.apiBaseUrl+'/th/en/jobportal/s/candidate/submit/location',
      isCaptchaDisabled : baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/captchaenabled',
      locationOnRecruitingNow:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/recruiting/browse/v1/location?type=recruiting-now-locations',
      employeeType:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/recruiting/browse/v1/employment?type=recruiting-now-employment',
      submitRecruitingForm:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/submit/v1/recruitingnow',
      getSector: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/specilisms', // post call for getting list of sectors
      getLocationHierarchy :  baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/hierarchy',
      getStateList : baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/states',
      getOfficeForSpecialism: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/offices',
      getSubSpecialism: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/subspecialism ',
      getAllOffice: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/alloffices',
      getOfficeResultForAPAC: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/officeresultapac',
      getLatLongOfficeResult: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/officeresultlatlong',
      isAutoSugesstionEnabled: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/google/browse/v1/isautosugesstionenabled',
      getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkboxnew',
      getTermsAndConditionsApply: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkbox_apply',
      getTermsAndConditionsRegister:baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkbox_register',
      getGDriveAndDropbox: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/partner/browse/v1/isdropboxgdrivedisabled',
      getIsKanaEnabled: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/partner/browse/v1/iskanaenabled',
      register: baseUrl.apiBaseUrlWrapper +'/int/s/th/en/{orgname}/candidate/submit/v1/register',
      pagemanagement:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/pagemanagement/browse/v1/pagemenu',
      countryLanguage:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/site/browse/v1/getlanguages?type=change_language',
      getBrowseByExpertise:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/browsebyexpertise',
      showOfficeEmail:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/showofficeemail',
      connectdropbox:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/connectdropbox',
      connectGdrive:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/connectgdrive',
      getDropBoxFiles:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/dropboxfiles',
      getDriveFiles:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/gdrivefiles',
      getFileLink:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/dropboxfilelink',
      gdriveFileLink:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/gdrivefilelink',
      getAutoSuggetion:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/autosuggestion?keyWord=',
      submitEmail:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/gdpr/submit/emailconfirmation',
      getgooglejobs:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/jobsv2',
      getgooglejobssecure:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/jobsv2',
      jobflowconfig:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/pagemanagement/browse/v1/jobflowconfig',
      getJobFilters:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/filters?type=job_filter',
      getCustomerServiceEmail: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/pagemanagement/browse/v1/customeremail',
      pagemanagementSecure:baseUrl.apiBaseUrl +'/int/s/th/en/{orgname}/pagemanagement/browse/v1/pagemenu',
      createToken:baseUrl.apiBaseUrl+'/ext/ns/th/en/{orgname}/usermanagement/create/v1/token',
      validatePage:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/usermanagement/validate/v1/page',
      submitForgetPassEmail: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/submit/v1/forgetpassword',
      getInterviewAndCvTips: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/browse/v1/interviewandcvtips',
      getRecentApplication: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/browse/v1/recentapplication',
      getInterviewCount: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/notification/browse/v1/interview',
      getUpcomingInterview: baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/browse/v1/interviewdetails',
      getpersonaldetails: baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/browse/v1/getpersonaldetailsupgrade',
      getGDriveFiles: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/partner/browse/v1/gdrivefiles',
      gcsPublish:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/submit/v1/gcsevent',
      saveAlert:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/create/v1/alert',
      saveWhatsapp:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/submit/v1/whatsapp',
      updateWhatsapp:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/update/v1/whatsappnumber',
      getWhatsappNumber:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/browse/v1/whatsappnumber',
      getWhatsAppAlerts:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/decrypt/v1/whatsappalert',
      getJobAlerts:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/alert',
      getGdprConfig:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/pagemanagement/browse/v1/gdprconfig',
      getMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/gdpr/browse/v1/marketingpreferences',
      updateMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/gdpr/update/v1/marketingpreferences',
      decryptIdentifier:baseUrl.apiBaseUrl+'/int/ns/th/en/{orgname}/decrypt/browse/v1/decryptidentifier',
      saveJob: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/job/create/v1/savejob',
      deleteJob: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/job/delete/v1/deletesavedjob',
      getSavedJobs: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/job/browse/v1/getsavedjobs',
      resetPassword:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/submit/v1/resetpassword',
      deleteJobAlert:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/delete/v1/alert',
      editAlert:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/update/v1/alert',
      changeEmail:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/submit/v1/changeemail',
      getCvs:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/browse/v1/getcvs',
      downloadCV:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/browse/v1/downloadcv',
      deleteCv:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/delete/v1/deletecv',
      getSingleJob:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/getsavedjob',
      changePassword:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/candidate/submit/v1/changepassword',
      uploadcv:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/upload/v1/cv',
      jobApply: baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/candidate/submit/v1/jobapply',
      unsubscribe: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/alert/v1/unsubscribe',
      jobAlertVerify: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/alert/v1/verify',
      applyLinkedin: baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/job/v1/applylinkedin',
      getWidgetList: baseUrl.apiBaseUrl+'/int/ns/th/en/{orgname}/content/browse/v1/widgetlist',
      getTimeSheet: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/auth/browse/v1/pagemenu',
      getKillerQuestions: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/content/browse/v1/killerquestions',
      seekAuthUrl:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/browse/v1/seekurl',
      seekApply:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/submit/v1/applyseek',
      signedURL:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/user/create/v1/signedurl',
      getErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/user/browse/v1/marketingpref',
      updateErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/th/en/{orgname}/user/save/v1/marketingpref',
      validateAlert: baseUrl.apiBaseUrl+'/int/ns/th/en/{orgname}/alert/v1/validatealert',
      erpValidateEmail:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/user/send/v1/consentemail',
      getClientConfig:baseUrl.apiBaseUrl+'/int/ns/th/en/{orgname}/client/browse/v1/clientconfig',
      deleteAllAlerts:baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/job/delete/v1/deleteallalerts',
      viewEmailLink:baseUrl.apiBaseUrl+'/int/ns/th/en/{orgname}/alert/v1/viewmaillink',
      go1generateLink: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/training/create/v1/generatego1link',
      go1getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/th/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkbox_newgo1',
      getGo1Trainings: baseUrl.apiBaseUrl+'/int/s/th/en/{orgname}/training/fetch/gettraining',
      logoutUser:baseUrl.apiBaseUrl+'/ext/ns/th/en/{orgname}/auth/delete/v1/session',
    },
    routing_url:{
      searchLinkTitleTerm: '-jobs',
      searchLinklocTerm: 'in-',
      searchLinkTitle: '/job-search/{jobTitle}-jobs',
      searchLinkLocation: '/job-search/in-{location}',
      searchLinkInLocation: '/job-search/{jobTitle}-jobs-in-{location}',
      errorPage:'/account/error/',
      successPage:'/account/cv-success/',
      successUpdateEmailPrefences:'/account/sent-preferences-email',
      confirmationPage: '/partner/ps/success',
      officeLocatorResultPage:'/contact/offices-near-me/result',
      officeLocatorPage:'/contact/offices-near-me',
      speculativeCV:'/account/send-cv',
      myProfile:'/my-account/profile',
      login:'/account/login',
      register: '/account/register',
      search:'/job-search',
      secureSearch:'/job-search',
      secureJobDetails:'/job-detail',
      jobdetails:'/job-detail',
      forgetPassword:'/account/login/forgot-password',
      header:'/TH/en/newheader',
      footer:'/TH/en/newfooter',
      recordIdJobDetail:'/TH/en/jobportal/s/candidate/job/browse/details/',
      jobApply:'/job-detail/:recordId/apply',
      secureJobApply:'/job-detail/:recordId/apply',
      gdprThankyouPage:'/my-account/marketing-preferences-confirmed',
      secureGdprThankyouPage:'/my-account/marketing-preferences-confirmed',
      secureMarketingPrefPage:'/my-account/marketing-preferences',
      marketingPrefPage:'/my-account/marketing-preferences',
      resetPasswordPage:'/account/reset',
      manageCV:'/my-account/manage-cv',
      secureThankYou:'/job-detail/:recordId/job-success',
      thankyou:'/job-detail/:recordId/job-success',
      saveWhatsappPage:'/my-account/whatsapp-number-confirmed',
      updateWhatsappPage:'/my-account/update-whatsapp-number',
      unsubscribePage:'/prjs/TH/en/jobportal/as/alert/submit/unsubscribe',
      alertThankYouPage:'/account/job-alert-success',
      emailConfirmationPage:'/prjs/TH/en/jobportal/as/gdpr/submit/email-confirmation',
      recruitingNow:'/recruitment/recruit-now',
      secureRecruitingNow:'/recruitment/recruit-now',
      goLoginPage:'/account/partner/go1/training/login',
      savedSearchesPage: '/my-account/job-alerts',
      shortlistedJobsPage: '/account/saved-jobs',
      changeEmailPage:'/my-account/change-email',
      emailConfirmation:'/account/update-preferences-email',
      logOutPage:'/account/logout',
      erpMarketingPref: '/account/erp/marketing-preferences',
      secureErpMarketingPref: '/my-account/erp/marketing-preferences',
      erpEmailConfirmation: '/account/update-preferences-email',
      erpManageJobAlert: '/account/erp/job-alerts',
      secureErpManageJobAlert: '/my-account/erp/job-alerts',
      erpThankyouPage: '/account/erp/marketing-preferences-confirmed',
      secureErpThankyouPage: '/my-account/erp/marketing-preferences-confirmed'
    },
    // routing_url:{
    //   errorPage:'/s/error/',
    //   successPage:'/s/success/',
    //   officeLocatorResultPage:'/office-locator/result',
    //   officeLocatorPage:'/office-locator',
    //   speculativeCV:'/speculativeCV',
    //   myProfile:'/s/my-profile',
    //   login:'/login',
    //   search:'/search',
    //   secureSearch:'/search',
    //   secureJobDetails:'/Job/Detail',
    //   jobdetails:'/Job/Detail',
    //   forgetPassword:'/login/forgot-password',
    //   header:'/TH/en/newheader',
    //   footer:'/TH/en/newfooter',
    //   recordIdJobDetail:'/TH/en/jobportal/s/candidate/job/browse/details/',
    //   jobApply:'/job/:recordId/apply',
    //   secureJobApply:'/job/:recordId/apply',
    //   gdprThankyouPage:'/as/thankyou',
    //   marketingPrefPage:'/as/marketing-prefrences',
    //   resetPasswordPage:'/reset',
    //   manageCV:'/s/manage-cv',
    //   secureThankYou:'/job/apply/details',
    //   thankyou:'/job/apply/details',
    //   saveWhatsappPage:'/TH/en/jobportal/s/candidate/submit/whatsapp',
    //   updateWhatsappPage:'/TH/en/jobportal/s/candidate/update/whatsapp',
    //   unsubscribePage:'/TH/en/jobportal/as/alert/submit/unsubscribe',
    //   alertThankYouPage:'/job/VerifyAlert/success',
    //   emailConfirmationPage:'/TH/en/jobportal/as/gdpr/submit/email-confirmation',
    //   recruitingNow:'/recruiting-now',
    //   secureRecruitingNow:'/recruiting-now',
    //   erpMarketingPref: '/erp/as/marketing-pref',
    //   secureErpMarketingPref: '/erp/s/marketing-pref',
    //   erpEmailConfirmation: '/erp/as/email-confirmation',
    //   erpManageJobAlert: '/erp/as/manage-job-alert',
    //   secureErpManageJobAlert: '/erp/s/manage-job-alert',
    //   erpThankyouPage: '/erp/thank-you',
    //   goLoginPage:'/partner/go1/training/login/'
    // },
    routing_url_org: {
      errorPage: '/:orgName/s/error/',
      successPage:'/:orgName/s/success/',
      officeLocatorResultPage:'/:orgName/office-locator/result',
      officeLocatorPage:'/:orgName/office-locator',
      speculativeCV:'/:orgName/speculativeCV',
      myProfile:'/:orgName/s/my-profile',
      login:'/:orgName/login',
      search:'/:orgName/search',
      secureSearch:'/:orgName/search',
      secureJobDetails:'/:orgName/Job/Detail',
      jobdetails:'/:orgName/Job/Detail',
      forgetPassword:'/:orgName/login/forgot-password',
      header:'/:orgName/TH/en/newheader',
      footer:'/:orgName/TH/en/newfooter',
      recordIdJobDetail:'/:orgName/TH/en/jobportal/s/candidate/job/browse/details/',
      jobApply:'/:orgName/job/:recordId/apply',
      secureJobApply:'/:orgName/job/:recordId/apply',
      gdprThankyouPage:'/:orgName/as/thankyou',
      marketingPrefPage:'/:orgName/as/marketing-prefrences',
      resetPasswordPage:'/:orgName/reset',
      manageCV:'/:orgName/s/manage-cv',
      secureThankYou:'/:orgName/job/apply/details',
      thankyou:'/:orgName/job/apply/details',
      saveWhatsappPage:'/:orgName/TH/en/jobportal/s/candidate/submit/whatsapp',
      updateWhatsappPage:'/:orgName/TH/en/jobportal/s/candidate/update/whatsapp',
      unsubscribePage:'/:orgName/TH/en/jobportal/as/alert/submit/unsubscribe',
      alertThankYouPage:'/:orgName/job/VerifyAlert/success',
      emailConfirmationPage:'/:orgName/TH/en/jobportal/as/gdpr/submit/email-confirmation',
      recruitingNow:'/:orgName/recruiting-now',
      secureRecruitingNow:'/:orgName/recruiting-now',
      erpMarketingPref: '/:orgName/erp/as/marketing-pref',
      secureErpMarketingPref: '/:orgName/erp/s/marketing-pref',
      erpEmailConfirmation: '/:orgName/erp/as/email-confirmation',
      erpManageJobAlert: '/:orgName/erp/as/manage-job-alert',
      secureErpManageJobAlert: '/:orgName/erp/s/manage-job-alert',
      erpThankyouPage: '/:orgName/erp/thank-you',
      goLoginPage:'/:orgName/partner/go1/training/login/',
    }
  };



