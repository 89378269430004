import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from 'src/app/global/services/site/site.service';
import {map} from 'rxjs/operators';
import {UploadCV, UploadCVAdapt} from 'src/app/jobportal/model/response/uploadCV';
import {RequestService} from 'src/app/global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {apiEndPoints, config, environment, routingEndpoint, keywords} from '../../../../../environments/root/environment';
import {DataSharingService} from '../../../service/data-sharing.service';
import {CookieService} from 'ngx-cookie-service';
import {CvData} from '../../../model/request/cv-data';
import {Register} from '../../../model/request/register';
import {SetTitleService} from '../../../service/set-title.service';
import {JobSearchService} from '../../../service/job-search.service';
import {MarketingConsent} from '../../../model/request/marketing-consent';
import {Subscription} from 'rxjs';
import {LanguageSpoken, LanguageSpokenData, LanguageSpokenAdapt} from 'src/app/jobportal/model/response/language-spoken';

@Component({
  selector: 'app-jp-register-new',
  templateUrl: './jp-register-new.component.html',
  styleUrls: ['./jp-register-new.component.scss'],
  providers: [UploadCVAdapt, LanguageSpokenAdapt]

})
export class JpRegisterNewComponent implements OnInit, OnDestroy {


  public signUpFormGroup: FormGroup;
  public siteLocale: any;
  public isCaptchaDisable = true;
  public formSubmitted = false;
  public booleanFlag: string;
  public router: any;
  public specRoute = false;
  public registerRoute = false;
  public showFileValidation = true;
  public showPopUp = false;
  public firstName: string;
  public lastName: string;
  public emailAddress: string = '';
  public isChecked: boolean;
  public officeSpecialism: string;
  public sector: string;
  private isFormValid: boolean;
  public legalConsentVisible = false;
  validationForCA = false;
  validationForItaly = false;
  public disableButton: boolean;
  public validationCheck: boolean;
  public emailError: boolean;
  public isAUSHaysOffice: boolean;
  public katkanalastname: string;
  public katkanafirstname: string;
  private errorPageRoute: string;
  private successPageRoute: string;
  public isMarketingConsentEnabled: boolean;
  public submitClicked: boolean;
  public allowRegister: boolean;
  public marketingConsentData: MarketingConsent;
  private jobShredConfigRef: Subscription = new Subscription();
  private isMarketRef: Subscription = new Subscription();
  private legalConsentRef: Subscription = new Subscription();
  private openMarketPopupRef: Subscription = new Subscription();
  private crossclick: boolean;
  public isRegisterComponent: boolean;
  public isMarketingConsentForHU: boolean=false;
  public getPrivacyConsent2: boolean;
  public marketConsentPopup: boolean=false;
  public isPrivacyConsentEnabled: boolean=false;
  private expressConsent: string;
  public isAzureEnabled: boolean;
  public updateDetailsRoute = false;
  public mobileNumber: string;
  public mobileError : boolean;
  public dialingCode: string;
  public languageProficiencyData : LanguageSpokenData[];
  /**
   * Captures whether privacy pop up is displayed or not for CHN
   */
  public isPrivacyPopUpDisplayed : boolean = false;
  /**
   * Captures privacy pop up inner text depending upon the route
   */
  public privacyPopUpContent : string;
  
  public routing_url: any;
  public valOrgName:string;
  /**
   * Determines whether marketing consent pop up is displayed for TH
   */
  public isMarketingConsentPopUpDisplayed: boolean;
  /**
   * Captures user response for the marketing setting for TH domain
   */
  public marketingSettingResponse: string | undefined = undefined;
  /**
   * Captures teh current user flow
   */
  public currentUserFlow: string = 'user_register';
  SubscriptionList:Subscription[]=[];

  constructor(private _requestService: RequestService, private _translate: TranslateService,
              public _site: SiteService, public uploadCVAdapt: UploadCVAdapt, private _cdr: ChangeDetectorRef,
              private _router: Router, private activatedRoute: ActivatedRoute, public dataShareService: DataSharingService,
              private cookieService: CookieService, private titleService: SetTitleService, private jobSearchService: JobSearchService, public languageSpokenAdapt: LanguageSpokenAdapt) {
    
    
    this.valOrgName = this._router.routerState.snapshot.root.firstChild.params['orgName'];
    if (this.valOrgName) {
      this.routing_url = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      //this.routing_url = this.routing_url.replace(':orgName', orgName);
    }
    else
    {
      this.routing_url = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];  
    }
    if(this.valOrgName)
        {
          this.routing_url.register= this.routing_url.register.replace(':orgName', this.valOrgName);
          this.routing_url.speculativeCV= this.routing_url.speculativeCV.replace(':orgName', this.valOrgName);
         
          this.routing_url.login=this.routing_url.login.replace(':orgName', this.valOrgName);
          //this.routing_url.updateYourDetailsUrl=this.routing_url.updateYourDetailsUrl.replace(':orgName', this.valOrgName)
        }

    if (decodeURIComponent(this._router.url).indexOf(this.routing_url.speculativeCV) > -1) {
      this.specRoute = true;
      this.currentUserFlow = '';
    }
    let register_link = 'register';
    if(this.routing_url.register){
      register_link = this.routing_url.register;
    }

    
    if (decodeURIComponent(this._router.url).indexOf(register_link) > -1 || decodeURIComponent(this._router.url).indexOf(this.routing_url.login)>-1) {
      this.registerRoute = true;
    }
    if(this.routing_url.updateYourDetailsUrl && decodeURIComponent(this._router.url).indexOf(this.routing_url.updateYourDetailsUrl) > -1) {
      if(localStorage.getItem('domain') !=='JP') {
        const base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
        const errorPage = base[routingEndpoint.errorPage];
        this._router.navigate([errorPage], {queryParams:{'orgName':'Incorrect'}});
      }
      this.updateDetailsRoute = true;
      this.registerRoute = false;
      this.specRoute = false;
    }
    this.siteLocale = this._site.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
    const translateGetTranslationSubscription = this._translate.getTranslation(this._site.getSiteLocale()).subscribe((res) => {
      if (this.specRoute) {
        this.titleService.setTitleForPage(res['spec_cv_page_title']);
      }
      else if (this.registerRoute) {
        if(res['azure_register_metadata_page_title_text'])
          this.titleService.setTitleForPage(res['azure_register_metadata_page_title_text']);
        else
          this.titleService.setTitleForPage(res['new_to_hays_text']);
      }
      else if(this.updateDetailsRoute) {
        this.titleService.setTitleForPage(res['update_your_details_page_title']);
      }
    });
    this.SubscriptionList.push(translateGetTranslationSubscription);
  }

  ngOnInit() {
    this.checkCaptchaDisabled();
    if(localStorage.getItem("training_id")) {
      this.isAzureEnabled=true;
      //window.location.href = window.location.origin + "/partner/go1/training/register";
      window.location.href = window.location.origin + "/account/online-learning";
    }
    this.jobShredConfigRef = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isAUSHaysOffice) {
        this.isAUSHaysOffice = domainConfig.isAUSHaysOffice;
      }
      if (domainConfig && domainConfig.isMarketingConsentEnabled) {
        this.isMarketingConsentEnabled = domainConfig.isMarketingConsentEnabled;
      }
      if (domainConfig && domainConfig.isMarketingConsentForHU) {
        this.isMarketingConsentForHU = domainConfig.isMarketingConsentForHU;
      }
      if (domainConfig && domainConfig.isPrivacyConsentEnabled) {
        this.isPrivacyConsentEnabled = domainConfig.isPrivacyConsentEnabled;
      }
      if(domainConfig && domainConfig.isLanguageProficiencyEnable) {
        this.getLanguageProficiency();
      }
    });

    const activatedRouteQueryParamMapPathSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      if ((!param.get('path') && !param.get('drive'))) {
        this.cookieService.delete('applicantDetails', '/');
      }
    });
    if (localStorage.getItem('showKana') === 'true' || localStorage.getItem('showKana') === 'false') {
      this.booleanFlag = localStorage.getItem('showKana');
      this.createFormGroup();
    } else {
      this.isKanaEnabled();
    }
    this.isMarketRef = this.dataShareService.$isMarketingConsent.subscribe(data=>this.marketingConsentData = data)
    this.SubscriptionList.push(activatedRouteQueryParamMapPathSubscription);
  }
  private checkCaptchaDisabled() {
    const checkCaptchaDisabledRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isCaptchaDisabled,null,null).pipe().
    subscribe(response => {
      localStorage.setItem("disableCaptcha",response.data);
      this.dataShareService.setIsCaptchaDIsabled(response.data.toString());
    });
  }

  protected createFormGroup(): void {
    this.signUpFormGroup = new FormGroup({
      firstname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change',
      }),
      lastname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change'
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'change'
      }),
      cvData: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        }
      ),
      expertise: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      officeForSpecialism: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      privacyconsent1: new FormControl('', {
          updateOn: 'change'
        }
      ),
      privacyconsent2: new FormControl('', {
          updateOn: 'change'
        }
      ),
      privacyconsentAll: new FormControl('', {
          updateOn: 'change'
        }
      ),
      marketingconsent: new FormControl('', {
        updateOn: 'change'
      }
    ),
    eshotconsent: new FormControl('', {
      updateOn: 'change'
    }
    ),
    smsconsent: new FormControl('', {
      updateOn: 'change'
    }
    ),
    whatsApp: new FormControl('', {
      updateOn: 'change'
    }
    ),
      reCaptchaReactive: new FormControl('',
        {updateOn: 'change'}
      ),
      languageProficiency: new FormArray([])
    });
    if(this.updateDetailsRoute){
      this.signUpFormGroup.addControl('mobileNumber', new FormControl('', { validators: Validators.required, updateOn: 'change'}));
      this.signUpFormGroup.addControl('dialingCode', new FormControl('', { validators: Validators.required, updateOn: 'change'}));
      this.signUpFormGroup.controls["cvData"].clearValidators();
      this.signUpFormGroup.controls["cvData"].updateValueAndValidity();
      this.dialingCode = '81';
    }
    this.dataShareService.setSignUpForm(this.signUpFormGroup);
    if (this.cookieService.get('applicantDetails')) {
      let applicantCookie: any;
      applicantCookie = decodeURIComponent(this.cookieService.get('applicantDetails'));
      applicantCookie = JSON.parse(applicantCookie);
      this.firstName = applicantCookie.firstName ? applicantCookie.firstName : '';
      this.lastName = applicantCookie.lastName ? applicantCookie.lastName : '';
      this.emailAddress = applicantCookie.email ? applicantCookie.email : '';
      this.katkanalastname = applicantCookie.katkanalastname ? applicantCookie.katkanalastname : '';
      this.katkanafirstname = applicantCookie.katkanafirstname ? applicantCookie.katkanafirstname : '';
      this.officeSpecialism = applicantCookie.officeSpecialism ? applicantCookie.officeSpecialism : '';
      this.sector = applicantCookie.sector ? applicantCookie.sector : '';
      this.isChecked = applicantCookie.marketingConsents;
      this.dialingCode = applicantCookie.dialingCode ? applicantCookie.dialingCode : '';
      this.mobileNumber = applicantCookie.mobileNumber ? applicantCookie.mobileNumber : '';
    }

    if (this.booleanFlag) {
      this.signUpFormGroup.addControl('katkanalastname', new FormControl('', {updateOn: 'change'}));
      this.signUpFormGroup.addControl('katkanafirstname', new FormControl('', {updateOn: 'change'}));
    }
    if(localStorage.getItem("disableCaptcha")) {
      if(localStorage.getItem("disableCaptcha") == "true") {
        this.isCaptchaDisable = true;
      } else {
        this.isCaptchaDisable = false;
        this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
      }
    }
    this.dataShareService.sharedIsCaptchaDisabled.subscribe(res => {
      if (res == "true") {
        this.isCaptchaDisable = true;
      } else if(res == "false") {
        this.isCaptchaDisable = false;
        this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
        this.signUpFormGroup.get('reCaptchaReactive').updateValueAndValidity();
      }
    }) 
    if (localStorage.getItem('domain') === 'CA') {
      this.validationForCA = true;
      this.signUpFormGroup.addControl('privacyconsent2', new FormControl('', {updateOn: 'change'}));
    }
    if (localStorage.getItem('domain') === 'ITL' || localStorage.getItem('domain') === 'IT') {
      this.validationForItaly = true;
      this.signUpFormGroup.addControl('privacyconsent2', new FormControl('', {updateOn: 'change'}));
    }
  }

  public createLanguageProficiency() {
    if(this.languageProficiencyData && this.languageProficiencyData.length > 0) {
     const languageProficiency =  this.signUpFormGroup.get('languageProficiency') as FormArray;
     this.languageProficiencyData.map(item => {
       languageProficiency.push (new FormGroup({
         language : new FormControl(item.code_id, {
           validators: [Validators.required],
           updateOn: 'change',
         }),
         proficiency :new FormControl('', {
           validators: [Validators.required],
           updateOn: 'change',
         })
        }));
     })
    }
   }
   private getLanguageProficiency(){
     const getLanguageProficiencyRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getLanguageSpoken).pipe(
       map((res:LanguageSpoken)=> this.languageSpokenAdapt.adapt(res)))
       .subscribe((res)=>{
         if(res.status==config.SUCCESS && res.data){
           this.languageProficiencyData = res.data;
           this.createLanguageProficiency();
         }
       },(error)=>{
       });
       this.SubscriptionList.push(getLanguageProficiencyRequestServiceGetRequestSubscription);
   }

  public whitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  showValidation(flag) {
    this.showPopUp = false;
    this.disableButton = false;
    this.signUpFormGroup['controls'].reCaptchaReactive.setValue('');
    this.showFileValidation = flag;
  }

  isKanaEnabled() {
   const requestServiceGetRequestSubscription = this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getIsKanaEnabled).pipe(
      map((response: UploadCV) => this.uploadCVAdapt.adapt(response))
    ).subscribe(response => {
      this.booleanFlag = response.status === config.SUCCESS ? 'true' : 'false';
      localStorage.setItem('showKana', this.booleanFlag);
      this.createFormGroup();
    }, (error) => {
      this.booleanFlag = 'false';
      localStorage.setItem('showKana', this.booleanFlag);
    });
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
  }

  legalConsentPopup() {
    this.legalConsentRef = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isLegalConsentPopupEnabled && !domainConfig.isMultiPrivacyPolicyEnabled) {
        this.legalConsentVisible = true;
      } else {
        this.initRegister();
      }
    });
  }

  legalConsentFlag(temp) {
    let flag = temp === true ? 'Y' : 'N';
    this.signUpFormGroup.value.eshotconsent = flag;
    this.signUpFormGroup.value.smsconsent = flag;
    this.signUpFormGroup.value.privacyconsent2= flag;
    if(!this.isMarketingConsentForHU && !this.isPrivacyConsentEnabled)
      this.signUpFormGroup.value.marketingconsent = flag;
    this.marketConsentPopup=false;
    this.initRegister();
  }

  register() {
    this.submitClicked = true;
    let EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    if (!this.emailAddress && !EMAIL_REGEX.test(this.emailAddress)) {
      this.emailError = true;
    }
    if(this.updateDetailsRoute){
      this.onMobileChanged('');
    }

    if (this.officeSpecialism && this.sector && this.firstName && this.lastName && this.emailAddress && EMAIL_REGEX.test(this.emailAddress.trim()) && this.isChecked) {
      this.signUpFormGroup.value.officeForSpecialism = this.officeSpecialism;
      this.signUpFormGroup.value.expertise = this.sector;
      this.signUpFormGroup.value.privacyconsent1 = this.isChecked;
      this.signUpFormGroup.patchValue({
        expertise: this.sector,
        officeForSpecialism: this.officeSpecialism,
        privacyconsent1: this.isChecked
      });
      this.signUpFormGroup['controls']['officeForSpecialism'].setErrors(null);
      this.signUpFormGroup['controls']['expertise'].setErrors(null);
      this.signUpFormGroup['controls']['privacyconsent1'].setErrors(null);
      this.isFormValid = true;
      this.emailError = false;
    }
    this.formSubmitted = true;
    if (this.checkValidation()) {
      if (this.signUpFormGroup.valid || (this.isFormValid && this.signUpFormGroup.value.reCaptchaReactive)) {
        if (this.isMarketingConsentEnabled && !this.marketingConsentData.email && !this.marketingConsentData.sms && !this.marketingConsentData.whatsApp) {
          this.showMarketingPopup();
        }
        if (this.isPrivacyConsentEnabled && this.signUpFormGroup['controls'].privacyconsent2.value !== true){
          this.showMarketingPopup();
        }
        if (this.isMarketingConsentEnabled) {
          this.openMarketPopupRef = this.dataShareService.$openMarketContentPopup.subscribe(data => {
            if(this.crossclick)
              return
            if (!data && this.submitClicked) {
              this.disableButton = true;
              this.emailError = false;
              this.legalConsentPopup();
              this.submitClicked = false;
            }
          });
        }else if(!this.getPrivacyConsent2 && this.isMarketingConsentForHU) {
          this.disableButton = true;
          this.emailError = false;
          this.submitClicked = false;
          this.marketConsentPopup = true;
        }
        else if(this.getPrivacyConsent2 && this.isMarketingConsentForHU) {
          this.disableButton = true;
          this.emailError = false;
          this.legalConsentFlag(true);
          this.submitClicked = false;
        }
        else {
          this.disableButton = true;
          this.emailError = false;
          this.legalConsentPopup();
          this.submitClicked = false;
        }
      }
    }
  }

  initRegister() {
    let token = '';
    const activatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      token = param.get('accessTokenDB') ? param.get('accessTokenDB') : param.get('accessTokenGD');
    });
    let cvData: CvData;
    cvData = this.signUpFormGroup.controls.cvData.value;
    const languageProficiency = this.signUpFormGroup.controls.languageProficiency.value;
    this.dataTransform();
    const marketingConsentValue = localStorage.getItem('domain') === 'TH' ? this.marketingSettingResponse : this.signUpFormGroup.value.marketingconsent;
    if(this.isMarketingConsentEnabled)
      this.checkMarketingData();
    let registerJson = new Register(this.signUpFormGroup.controls.cvData.value, cvData.name, '', this.emailAddress,
      this.signUpFormGroup.value.privacyconsent1, this.signUpFormGroup.value.privacyconsent2, this.signUpFormGroup.value.privacyconsent3,
      this.signUpFormGroup.value.eshotconsent, this.signUpFormGroup.value.expertise, this.signUpFormGroup.value.firstname, this.signUpFormGroup.value.reCaptchaReactive,
      this.signUpFormGroup.value.katkanafirstname, this.signUpFormGroup.value.katkanalastname, this.signUpFormGroup.value.lastname, marketingConsentValue,
      this.signUpFormGroup.value.officeForSpecialism.split(':')[0], this.signUpFormGroup.value.officeForSpecialism.split(':')[1],
      this.signUpFormGroup.value.smsconsent, token, null, null, null, this.signUpFormGroup.value.whatsApp, this.expressConsent);
      if(this.updateDetailsRoute){
        const mobileNumber = '+'+this.dialingCode+this.mobileNumber;
        registerJson.mobilenumber = mobileNumber;
        registerJson.requestType = "SpecAppNoCV";
      }
      if(languageProficiency && languageProficiency.length > 0){
        const languageProficiencyFinal = languageProficiency.filter( item =>{
          if(item.proficiency!=='null'){
           return item;
          }
        });
        if(languageProficiencyFinal && languageProficiencyFinal.length > 0){
          registerJson.languageProficiency = languageProficiencyFinal;
        }
      }
      this.submitAndRegister(registerJson);
      this.SubscriptionList.push(activatedRouteQueryParamMapSubscription);
  }


  submitAndRegister(registerJson) {
    if (this.cookieService.get('applicantDetails')) {
      this.cookieService.delete('applicantDetails', '/');
    }
    if(this.updateDetailsRoute){
      window.history.replaceState('', '', this.routing_url.updateYourDetailsUrl+'?lang='+ this._site.getLocale());
    }
    //let baseRoute = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
    this.getRoutes();
    const requestServicePostRequestGateWaySubscription= this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.register, registerJson).subscribe(response => {
      if (response.status === config.SUCCESS) {
        window.location.href = window.location.origin + this.successPageRoute + '?submitCV=Y';
        /*this._router.navigate([baseRoute[routingEndpoint.successPage]], { queryParams: { submitCV: 'Y' } });*/
      } else {
        //this.ngxUiLoader.stop();
        this._router.navigate([this.errorPageRoute], {queryParams: {submitCV: 'N', error: response.status}});
      }
    }, (error) => {
      //this.ngxUiLoader.stop();
      if (error.error.data === config.protectedFileUpload) {
        this.showPopUp = true;
      } else {
        this._router.navigate([this.errorPageRoute], {queryParams: {submitCV: 'N', error: error.status}});
      }
    });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  checkMarketingData(){
    if(this.marketingConsentData.email)
      this.signUpFormGroup.value.eshotconsent = 'Y';
    else if(!this.marketingConsentData.email)
      this.signUpFormGroup.value.eshotconsent = 'N';
    if(this.marketingConsentData.sms)
      this.signUpFormGroup.value.smsconsent = 'Y';
    else if(!this.marketingConsentData.sms)
      this.signUpFormGroup.value.smsconsent = 'N';
    if(this.marketingConsentData.whatsApp)
      this.signUpFormGroup.value.whatsApp = 'Y';
    else if(!this.marketingConsentData.whatsApp)
      this.signUpFormGroup.value.whatsApp = 'N';
  }

  getRoutes() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params['orgName'];
    let base = '';
    if (orgName) {
      base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      this.successPageRoute = (base[routingEndpoint.successPage]).replace(':orgName', orgName);
      this.errorPageRoute = (base[routingEndpoint.successPage]).replace(':orgName', orgName);
    } else {
      base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      this.successPageRoute = base[routingEndpoint.successPage];
      this.errorPageRoute = base[routingEndpoint.successPage];
    }

  }

  checkValidation() {
    this.validationCheck = true;
    if (this.signUpFormGroup['controls'].privacyconsent1.value === true) {
      if(this.validationForItaly && this.signUpFormGroup['controls'].privacyconsent2.value !== true){
        this.validationCheck = false;
      }
    }
    else {
      this.validationCheck = false;
    }
    if(localStorage.getItem('domain') === 'CHN' && !this.isChecked){
      this.checkValidityAndDisplayAgreementModal();
    }
    else if(localStorage.getItem('domain') === 'TH'){
      if(!this.isChecked){
        this.checkValidityAndDisplayAgreementModal();
      }
      else if(!this.marketingSettingResponse){
        this.validationCheck = false;
        this.updateMarketingConsentVisibility(true);
      }
    }
    return this.validationCheck;
  }

  dataTransform() {
    this.signUpFormGroup.value.privacyconsent1 = 'Y';
    if (this.validationForItaly) {
      this.signUpFormGroup.value.privacyconsent2 = 'Y';
    } else if (this.validationForCA) {
      this.signUpFormGroup.value.privacyconsent2 = this.signUpFormGroup.value.privacyconsent2 !== true ? 'N' : 'Y';
      this.signUpFormGroup.value.privacyconsent3 = this.signUpFormGroup.value.privacyconsent3 !== true ? 'N' : 'Y';
    }
  }

  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }
  getPrivacyConsent2Checked($event: boolean) {
    this.getPrivacyConsent2=$event;
    this.expressConsent = this.isPrivacyConsentEnabled && $event? "Y":"N";
  }

  onEmailChanged(emailAddress) {
    this.emailError = false;
    this.emailAddress = emailAddress.target.value;
    this.dataShareService.setEmailId(this.emailAddress);
    let EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    if (this.emailAddress) {
      if (!this.emailAddress.trim() || !EMAIL_REGEXP.test(this.emailAddress.trim())) {
        this.emailError = true;
      }
    } else {
      this.emailError = true;
    }
  }

  showMarketingPopup() {
    this.isRegisterComponent = true;
    this.dataShareService.setOpenMarketContentPopup(true);
  }
  crossClicked(event){
    this.crossclick = event;
    this.marketConsentPopup=false;
  }
  onMobileChanged(event){
    if(this.dialingCode && this.mobileNumber) {
      const PHONE_REGEXP = /[0-9]{10}/;
     // const mobileNumber =  this.mobileNumber;
      if (PHONE_REGEXP.test(this.mobileNumber)) {
        this.mobileError = false;
      }else {
        this.mobileError = true;
      }
    }else {
        this.mobileError = true;
    }
    if(this.mobileError){
      this.signUpFormGroup.controls["mobileNumber"].setErrors({'invalid':true});
    }else {
      this.signUpFormGroup.controls["mobileNumber"].setErrors(null);
    }
  }
  ngOnDestroy(): void {
    this.openMarketPopupRef.unsubscribe();
    this.legalConsentRef.unsubscribe();
    this.isMarketRef.unsubscribe();
    this.jobShredConfigRef.unsubscribe();
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){ subscribe.unsubscribe();
      }
      });
    }   
  /**
   * Listens to the pop up close event
   * @param eventPayload 
   */
  public onPopUpClose(eventPayload : boolean){
    this.isPrivacyPopUpDisplayed = eventPayload
  }
  /**
   * Sets the privacy popup content
   */
  public getFlowSpecificPopUpMessages(){
    const getFlowSpecificPopUpMessagesTranslateGetTranslationSubscription= this._translate.getTranslation(this._site.getSiteLocale()).subscribe(response => {
      let register_link = 'register';
    if(this.routing_url.register){
      register_link = this.routing_url.register;
    }
      if(this._router.url.indexOf(this.routing_url.speculativeCV) > -1){
        this.privacyPopUpContent = response['privacy-consent-submit-cv-contents'];
      }
      else if(this._router.url.indexOf(register_link) > -1 || this._router.url.indexOf(this.routing_url.login) > -1){
        this.privacyPopUpContent = response['privacy-consent-register-login-contents'];
      }
    });
    this.SubscriptionList.push(getFlowSpecificPopUpMessagesTranslateGetTranslationSubscription);
  }
  /**
   * Sets the visibility of marketing consent pop up for TH domain
   * @param visibilityFlag 
   */
  public updateMarketingConsentVisibility(visibilityFlag: boolean){
    this.isMarketingConsentPopUpDisplayed = visibilityFlag;
  }
  /**
   * Called in response to marketing setting pop up close event
   * Captures the response
   * Removes the pop up from the view
   * @param eventPayload 
   */
  public onMarketingConsentResponse(eventPayload: string){
    this.marketingSettingResponse = eventPayload;
    this.updateMarketingConsentVisibility(false);
  }
  /**
   * Called when user clicks submit button for specified domains and sets the following properties:
   * Sets validation check to false
   * Displayd the privacy pop up
   * Calls the flow specific messages
   */
  private checkValidityAndDisplayAgreementModal(){
    this.validationCheck = false;
    this.isPrivacyPopUpDisplayed = true;
    this.getFlowSpecificPopUpMessages();
  }
  sanitizePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');
    // Remove emojis from the pasted content
    const sanitizedText = pastedText.replace(/[\p{Emoji_Presentation}]/gu, '');
    // Insert sanitized text into the input field
    document.execCommand('insertText', false, sanitizedText);
  }
}
