import {Component, OnDestroy, OnInit} from '@angular/core';
import {RequestService} from '../../../../../global/services/request/request.service';
import {MyProfileAdapter, MyProfileData, MyProfilePageData, MyProfileRequest} from '../../../../model/request/myprofile.request';
import {ActivatedRoute, Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import {PersonalDetails} from '../../../../model/response/my-profile';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {SetTitleService} from '../../../../service/set-title.service';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {TokenStorage} from '../../../../../global/services/tokenStorage/token-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-my-profile',
  templateUrl: './jp-my-profile.component.html',
  styleUrls: ['./jp-my-profile.component.scss'],
  providers: [MyProfileAdapter]
})
export class JpMyProfileComponent implements OnInit, OnDestroy {

  public personalDetails:PersonalDetails;
  public myProfileData:MyProfilePageData[]=[];
  public loader=false;
  public isWhatsapp:string;
  public emailAddress:string;
  public hostName:string;
  public isRedirectURL: boolean;
  public valiateOrgName: string;
  SubscriptionList:Subscription[]=[];
  private siteBaseURL: string;
  constructor(private requestService:RequestService,private activateRoute:ActivatedRoute, private tokenStorage: TokenStorage,
              private myProfileAdapter:MyProfileAdapter, private dataSharingService: DataSharingService,
              private translate:TranslateService,private titleService:SetTitleService, private _siteService:SiteService,public router :Router) {
    const translateGetTranslationSubscription = this.translate.getTranslation(_siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['my_profile_page_title']);
    });
    this.dataSharingService.getWidgetListName(keywords.myProfileWidget);
    this.SubscriptionList.push(translateGetTranslationSubscription);
    this.siteBaseURL = environment[config.theme + this._siteService.getSiteLocale()]['siteBaseURL'];
  }


  ngOnInit() {
    this.isRedirectURL=!!localStorage.getItem("requstedRedirectURL");
    const urlParams = new URL(window.location.href);
    this.valiateOrgName = urlParams.pathname.split('/')[1];

    if(this.isRedirectURL) {
      let url=localStorage.getItem("requstedRedirectURL");
      localStorage.removeItem("requstedRedirectURL");
      window.location.href = url;
    }
    this.hostName=window.location.hostname;
      const dataSharingServiceSharedPersonalDetailsSubscription= this.dataSharingService.sharedPersonalDetails.subscribe(profileData=>{
        if(profileData){
          this.personalDetails=profileData;
          this.myProfileRequest();
        }
      })
      this.SubscriptionList.push(dataSharingServiceSharedPersonalDetailsSubscription);
  }

  private myProfileRequest(){
    let routingUrl = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.myProfile];
    let requestData=new MyProfileRequest('',routingUrl);
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.pagemanagementSecure,requestData).pipe(
      map((res:MyProfileData)=>this.myProfileAdapter.adapt(res)))
      .subscribe((res)=>{
        if(res.status==config.SUCCESS && res.data) {
          this.myProfileData = [];
          res.data['PageData']['Page']['Top'].forEach((res)=>{
            let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
            if(res['resource_url'].indexOf("portal")>-1 || res['resource_url'].indexOf("prjsauthapp")>-1) {
              res['resource_url'] = res['resource_url'] + "&workFlow=changePass";
              //Replace domain and hostname for PP staging environment(testsg-> webtestsg) 
              const domainToBeChange = res['resource_url'].substring(res['resource_url'].indexOf('//') + 2,res['resource_url'].indexOf('.'));
              const currentDomain =  this.siteBaseURL.substring(this.siteBaseURL.indexOf('//') + 2,this.siteBaseURL.indexOf('.'));
              res['resource_url'] = res.resource_url.replaceAll(domainToBeChange, currentDomain);
              if(orgName){
                res['resource_url'] = res['resource_url'].replace("&client=hays&","&client="+orgName+"&").replace("&workFlow=","&workFlow=changePass") ;

                // res['resource_url'] = res['resource_url'].replace("/hays/","/"+orgName+"/") + "&workFlow=changePass";
              }
            }
            else {
              if (orgName) {

                // if((this.hostName.indexOf('testsg')>-1) || (this.hostName.indexOf('mcoat')>-1) )
                // {
                //   res['resource_url'] = res['resource_url'].replace("&client=hays&","&client="+orgName+"&").replace("&workFlow=","&workFlow=changePass") ;
                // }
                // else
                // {
                 
                  if((orgName==this.valiateOrgName))
                  {
                    res['resource_url'] = orgName + res['resource_url'];
                  }
                  else
                  {
                    res['resource_url']= res['resource_url'].split('/')[1]  + res['resource_url'].replace(res['resource_url'].split('/')[1],orgName); 
                 
                  }
                  //res['resource_url'] = orgName + res['resource_url'];
                //}

              }
            }

            this.loader=false;
            this.myProfileData.push(res);
            if((res['resource_url'].indexOf('whatsapp')>0) && (this.personalDetails.whatsappNumberStatus !='Y'))
              this.myProfileData.pop();
          });
        }
        //this.ngxUiLoader.stop();
      },(error)=>{
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  clearCacheCookie(buttonsUrl: string): void {
    if (buttonsUrl) {
      const resourceUrllist = buttonsUrl.split('&');
      const changePassflag = resourceUrllist.includes('workFlow=changePass');
      if (changePassflag) {
        const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.logoutUser,null,null).pipe().
        subscribe(response => {
          this.tokenStorage.clearCacheAndCookie();
        }, error => {
          this.tokenStorage.clearCacheAndCookie();
        });
        this.SubscriptionList.push(requestServiceGetRequestSubscription);
      }
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
