import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "../../../service/modal.service";
import {ActivatedRoute, Route, Router} from '@angular/router';
import {GetSavedJobAlertResponse} from "../../../model/request/erp-job-alert";
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from "../../../../../environments/root/environment";
import {Subscription, merge} from "rxjs";
import {RequestService} from "../../../../global/services/request/request.service";
import {TranslateService} from "@ngx-translate/core";
import {SiteService} from "../../../../global/services/site/site.service";
import {TokenStorage} from "../../../../global/services/tokenStorage/token-storage";
import {DataSharingService} from "../../../../jobportal/service/data-sharing.service";


@Component({
  selector: 'app-erp-delete-all-job-alerts-popup',
  templateUrl: './erp-delete-all-job-alerts-popup.component.html',
  styleUrls: ['./erp-delete-all-job-alerts-popup.component.scss']
})
export class ErpDeleteAllJobAlertsPopupComponent implements OnInit, OnDestroy {
  @Input() jobAlertResponse: GetSavedJobAlertResponse[] = [];

  public delete: boolean;
  public isLoggedIn: boolean;
  public errorRoute: string;
  public secureJobAlert : string;
  public identifier : string;
  public jobSource : string;
  SubscriptionList : Subscription[]=[];
public erpManageJobAlert: string;
  constructor(private modalService: ModalService,private el: ElementRef,public router:Router,private activatedRoute: ActivatedRoute,
              private requestService: RequestService, private _translate: TranslateService, private siteService: SiteService,private tokenStorage: TokenStorage,
              private dataSharingService: DataSharingService)
  {

  }

  ngOnInit() {
    this.identifier = this.activatedRoute.snapshot.queryParamMap.get('identifier');
    let defaultJobSource='';
   const dataSharingServiceSharedClientConfigSubscription = this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
    this.jobSource = this.activatedRoute.snapshot.queryParamMap.get('jobSource')? this.activatedRoute.snapshot.queryParamMap.get('jobSource') : defaultJobSource;

    this.isLoggedIn=false;
    if (this.tokenStorage.getLoggedInToken())
      this.isLoggedIn = true;

    this.getRouteUrls();
    this.SubscriptionList.push(dataSharingServiceSharedClientConfigSubscription);

  }

  getRouteUrls(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.secureJobAlert  = (base[routingEndpoint.secureErpManageJobAlert]).replace(':orgName',orgName);
      this.erpManageJobAlert  = (base[routingEndpoint.erpManageJobAlert]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.secureJobAlert  = base[routingEndpoint.secureErpManageJobAlert ];
      this.erpManageJobAlert  = base[routingEndpoint.erpManageJobAlert ];
      this.errorRoute = base[routingEndpoint.errorPage];
    }


  }
  closeModal(id: string)
  {
    this.modalService.close(id);
  }
  deleteAllJobAlerts(jobAlertResponse)
    {

        let alertList =[];
        this.jobAlertResponse.forEach(item => alertList.push(item.alertprofileid));
        alertList=alertList.map(String);
        let deleteJson={"alertProfileId": alertList} ;
        const postRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.deleteAllAlerts, deleteJson).subscribe(res => {
          if (res.status === config.SUCCESS) {
            jobAlertResponse=[];
            if(this.isLoggedIn)
              window.location.href = window.location.origin + [this.secureJobAlert];
            else
              window.location.href = window.location.origin + [this.erpManageJobAlert]+'?identifier='+this.identifier+'&jobSource='+this.jobSource;


          }
        }, error => {
          this.router.navigate([this.errorRoute], {queryParams: {deleteJobAlert: 'N'}})

        }
        )
        this.SubscriptionList.push(postRequestGateWaySubscription);

    }
    ngOnDestroy(): void {
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){
          subscribe.unsubscribe();
      }
      });
    } 
  }
