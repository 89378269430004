import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {map} from 'rxjs/operators';
import {JsonRequest, OfficeLocator, OfficeLocatorAdapt, OfficeLocatorData} from '../../../model/response/office-locator';
import {RequestService} from '../../../../global/services/request/request.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {apiEndPoints, config} from '../../../../../environments/root/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-jp-subspecialism',
  templateUrl: './jp-subspecialism.component.html',
  styleUrls: ['./jp-subspecialism.component.scss'],
  providers:[OfficeLocatorAdapt]
})
export class JpSubspecialismComponent implements OnInit , OnDestroy{
  @Input() specialismId : string;
  public subSpecialismList : OfficeLocatorData[];
  @Output() selectedSubSpecialism = new EventEmitter<any>();
  public showSubspecialism : boolean;
  requestServicePostRequestGateWaySubscription:Subscription;
  constructor(private officeLocatorAdapt:OfficeLocatorAdapt,private  requestService:RequestService, private _translate : TranslateService,
              private siteService:SiteService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this.showSubspecialism=false;
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
    if(this.specialismId)
      this.getSubspecialismList();
  }
  getSubspecialismList(){
    let json= new JsonRequest("",this.specialismId);
    this.requestServicePostRequestGateWaySubscription =this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getSubSpecialism,json).pipe(
      map((res: OfficeLocator) => this.officeLocatorAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.showSubspecialism=true;
          if(res.data["results"].length >0) {
            this.subSpecialismList = res.data["results"];
            this.selectedSubSpecialism.emit(this.subSpecialismList[0].id);
          }
        }
      }, (error) => {
      });
  }
  selectedSubspecialism(event){
    let subSpecialismId=event.currentTarget.value;
    this.selectedSubSpecialism.emit(subSpecialismId);
  }
  ngOnDestroy(): void {
    if(this.requestServicePostRequestGateWaySubscription){
    this.requestServicePostRequestGateWaySubscription.unsubscribe();}
  }
}

