import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonalDetails} from '../../../../../model/response/my-profile';
import {apiEndPoints, config} from '../../../../../../../environments/root/environment';
import {JobAlertRequest} from '../../../../../model/request/job-alert-request';
import {JobSearchService} from '../../../../../service/job-search.service';
import {JobSearchFilter} from '../../../../../model/response/google-job-response';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {ActivatedRoute} from '@angular/router';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {DataSharingService} from '../../../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-email-alert',
  templateUrl: './jp-email-alert.component.html',
  styleUrls: ['./jp-email-alert.component.scss']
})
export class JpEmailAlertComponent implements OnInit {
  @Input() public personalDetails: PersonalDetails;
  @Input() public keyWord: string;
  @Output() public isEmailAlertSaved: EventEmitter<boolean> = new EventEmitter<any>();
  @Output() public ismaximumAlertExceeds: EventEmitter<boolean> = new EventEmitter<any>();
  @Output() public isAlertClosed: EventEmitter<boolean> = new EventEmitter<any>();
  public disableEmailButton: boolean;
  public email: string='';
  public savedEmailAlert: boolean;
  public emailErrorFlag: boolean;
  private jobSearchFilter: JobSearchFilter;
  public isLoggedIn: boolean;
  private maximumAlertExceeds: boolean;

  constructor(private jobSearchService: JobSearchService, private requestService: RequestService, private activatedRoute: ActivatedRoute, private tokenStorage: TokenStorage,private dataShareService:DataSharingService) {
  }

  ngOnInit() {
    if (this.tokenStorage.getLoggedInToken()) {
      this.isLoggedIn = true;
    }

    this.jobSearchService.sharedJobSearchFilterData.subscribe(searchFilter => {
      this.jobSearchFilter = searchFilter;
    });
    this.dataShareService.sharedPersonalDetails.subscribe(personalDetails=>{
      this.personalDetails=personalDetails;
    })

  }

  validateEmail(email) {
    if (email && email.trim()) {
      let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!emailRegex.test(email)) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.emailErrorFlag = false;
      }
    }
  }

  saveJobAlert() {
   // console.log("email address..",this.personalDetails);
    if (this.personalDetails && this.personalDetails.email) {
      this.email = this.personalDetails.email;
    }
    if (!this.email) {
      this.emailErrorFlag = true;
    }
    if (this.email && !this.email.trim() || this.emailErrorFlag) {
      //console.log("inside if of check email");
      this.emailErrorFlag = true;
      return false;
    } else {
      //console.log("inside else of check email");
      let searchedLocation = '';
      let locationFilter = '';
      let industryFilter = '';
      let specialismId = '';
      let subSpecialismId = '';
      let jobSource = '';
      let alertId = '';
      let saveURL = '';
      this.disableEmailButton = true;
      let defaultJobSource='';
      this.dataShareService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      this.activatedRoute.queryParamMap.subscribe(param => {
        if (param.get('location')) {
          searchedLocation = param.get('location');
        }
        if (param.get('locationf')) {
          locationFilter = param.get('locationf');
        }
        if (param.get('industryf')) {
          industryFilter = param.get('industryf');
        }
        if (param.get('specialismId')) {
          specialismId = param.get('specialismId');
        }
        if (param.get('subSpecialismId')) {
          subSpecialismId = param.get('subSpecialismId');
        }
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        if (param.get('alertid')) {
          alertId = param.get('alertid');
        }
        if (param.get('q')) {
          this.savedEmailAlert = false;
        }
        saveURL = 'specialismId=' + specialismId + '&' + 'subSpecialismId=' + subSpecialismId;
      });
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWord, jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', '',
        '', this.email,
        specialismId, subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '',
        this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(saveURL),
        this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        searchedLocation, locationFilter, industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', alertId ? alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res && (res.status === config.SUCCESS)) {
          if (res.data.statuscode === 'WC023') {
            this.maximumAlertExceeds = true;
            this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
            this.email = '';
          }else {
            this.maximumAlertExceeds=false;
            this.email = '';
            this.savedEmailAlert = true;
            this.isEmailAlertSaved.emit(this.savedEmailAlert);
            this.ismaximumAlertExceeds.emit(this.maximumAlertExceeds);
          }
        }

      }, error => {
        this.disableEmailButton = false;
      });
    }
  }

  closeAlert() {
    this.keyWord = '';
    this.isAlertClosed.emit(true);
  }
}
