import { Component, OnDestroy, OnInit } from '@angular/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment} from '../../../../../../environments/root/environment';
import {InterviewAndCvLinks} from '../../../../model/response/my-profile';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-interview-and-cv-tips',
  templateUrl: './jp-interview-and-cv-tips.component.html',
  styleUrls: ['./jp-interview-and-cv-tips.component.scss']
})
export class JpInterviewAndCvTipsComponent implements OnInit, OnDestroy {

  public interviewCvTipsLink:InterviewAndCvLinks[]=[];
  public liferayBaseUrl:string;
  requestServiceGetRequestSubscription:Subscription;
  constructor(private siteService:SiteService, private translation:TranslateService,private requestService:RequestService) {
    this.translation.setDefaultLang(this.siteService.getSiteLocale());
    this.liferayBaseUrl=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
  }

  ngOnInit() {
    this.requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.interviewCvTips).subscribe((res)=>{
      if(res.data && res.status==config.SUCCESS)
        this.interviewCvTipsLink=res.data;
    }),(error)=>{

    };
  }
  ngOnDestroy(): void {
    if(this.requestServiceGetRequestSubscription){
    this.requestServiceGetRequestSubscription.unsubscribe();}
  }
}
