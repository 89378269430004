
import { Component, OnDestroy, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import {MarketingPrefAdapt, MarketingPrefData, MarketingPrefJsonRequest} from '../../../model/response/marketing-pref';
import {RequestService} from '../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DecryptIdentifierJsonRequest} from '../../../model/response/decrypt-identifier';
import {SetTitleService} from '../../../service/set-title.service';
import {TokenStorage} from '../../../../global/services/tokenStorage/token-storage';
import {DataSharingService} from '../../../service/data-sharing.service';
import {PersonalDetails} from '../../../model/response/my-profile';
import {JpDkUpdateMarketingPref} from "../../../model/request/jp-dk-update-marketing-pref";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-dk-marketing-pref',
  templateUrl: './jp-dk-marketing-pref.component.html',
  styleUrls: ['./jp-dk-marketing-pref.component.scss'],
  providers: [MarketingPrefAdapt]
})
export class JpDkMarketingPrefComponent implements OnInit, OnDestroy {
  public buttonDisable: boolean;
  public signedurl: string;
  public emailAdd: string;
  public baseRoute: string;
  public errorRoute: string;
  private personalDetails:PersonalDetails;
  public marketingPrefData: MarketingPrefData;
  public isDataLoaded :boolean;
  private yesText: string;
  public updateClicked: boolean;
  SubscriptionList:Subscription[]=[];
  constructor(private activatedRoute: ActivatedRoute,private router: Router,private requestService: RequestService, private dataSharingService: DataSharingService,
              private _translate: TranslateService, private siteService: SiteService, private marketingPrefAdapt: MarketingPrefAdapt,
              private titleService:SetTitleService,private token:TokenStorage) {
    const dataSharingServiceSharedPersonalDetailsSubscription = this.dataSharingService.sharedPersonalDetails.subscribe(personalData=>{
      this.personalDetails = personalData;
    });
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    const translateGetTranslationMarketingPreferenceSubscription= this._translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['marketing_preference_heading_text']);
    });
    const translateGetTranslationYesTextSubscription = this._translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.yesText = (res['yes_text']);
    });
    this.buttonDisable=true;
    this.emailAdd = localStorage.getItem('emailAdd');
    this.SubscriptionList.push(dataSharingServiceSharedPersonalDetailsSubscription);
    this.SubscriptionList.push(translateGetTranslationMarketingPreferenceSubscription);
    this.SubscriptionList.push(translateGetTranslationYesTextSubscription);
  }

  ngOnInit() {
    this.getRouteUrls();
    this.dataSharingService.getWidgetListName(keywords.marketingPrefPageName);
      this.getConsent();
  }

  getRouteUrls(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.baseRoute = (base[routingEndpoint.gdprThankyouPage]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.baseRoute = base[routingEndpoint.gdprThankyouPage];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }

  getConsent(){
    this.signedurl = this.activatedRoute.snapshot.queryParamMap.get('signedurl');
    if(this.signedurl && this.signedurl.length>0) {
      this.getMarketingPrefConsent(this.signedurl);
    }
    else{
      this.router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
    }
  }

  getMarketingPrefConsent(signedurl){
    const requestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getMarketingPreferencesDK,signedurl).pipe(
      map((res: MarketingPrefAdapt) => this.marketingPrefAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.marketingPrefData=res.data;
          this.isDataLoaded=true;
        }else{
          this.isDataLoaded=true;
          this.marketingPrefData=new MarketingPrefData(null,null,null,null,null,null,null,false,false,false,false,false,false,false,false,null,null,null);
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{gdprStatus:keywords.fail}});
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }


  updateMarketingPref() {
    if(this.signedurl && this.signedurl.length>0) {
      this.saveMarketingPrefConsent(this.signedurl);
    }
    else{
      this.router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
    }
  }

  saveMarketingPrefConsent(signedurl){
    this.updateClicked=true;
    this.buttonDisable=true;
    let jsonData = new JpDkUpdateMarketingPref(signedurl, this.marketingPrefData.consent_email);
    const saveMarketingPrefConsentRequestServicePostRequestGateWaySubscription= this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI, apiEndPoints.updateMarketingPreferencesDK, jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.router.navigateByUrl(this.baseRoute);
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{gdprSubmit:keywords.fail}});
      });
      this.SubscriptionList.push(saveMarketingPrefConsentRequestServicePostRequestGateWaySubscription);
  }
  setService(event,type){
    this.buttonDisable=false;
    switch (type){
      case keywords.email:
        this.marketingPrefData.consent_email = event.currentTarget.innerText === this.yesText;
        break;

    }

  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}

