import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleJob} from '../../../../../model/request/google-job';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment, keywords} from '../../../../../../../environments/root/environment';
import {
  ExtraFieldsConfig,
  GoogleJobResponse,
  Industries,
  JobSearchFilter,
  JobType,
  LocationFilter
} from '../../../../../model/response/google-job-response';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {JobSearchService} from '../../../../../service/job-search.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {PersonalDetails} from '../../../../../model/response/my-profile';
import {Meta} from '@angular/platform-browser';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-jp-search',
  templateUrl: './jp-search.component.html',
  styleUrls: ['./jp-search.component.scss']
})
export class JpSearchComponent implements OnInit,OnDestroy {

  private specialismId: string;
  private subSpecialismId: string;
  private location: string="";
  public locationf: string;
  private industryf: string;
  private sortType: string;
  private jobType: string;
  public keyWord: string="";
  private googleJobRequest: GoogleJob;
  private userAgent: string;
  public JobType: JobType;
  public industriesList: Industries[] = [];
  public slicedIndustries: Industries[] = [];
  public locationFilterList: LocationFilter[] = [];
  public locationList: LocationFilter[] = [];
  public googleJobResponseList: GoogleJobResponse[] = [];
  public domainConfig: ExtraFieldsConfig;
  private pageToken: string;
  private searchPerformed: boolean;
  public noResultFound: boolean;
  public jobSearchFilter: JobSearchFilter;
  private isScrolled: boolean;
  private loadMore = true;
  private activatedRouteParam: Subscription;
  public hostName: string;
  public showFilters: boolean;
  private cookieDomain:string;
  private searchCalled: boolean;
  public dataLoaded: boolean;
  public showPopup: boolean = false;
  private jobMedium: string;
  public searchPageDesc: string="";
  orgName: any;

  constructor(private activatedRoute: ActivatedRoute, private requestService: RequestService, private siteService: SiteService,private translate: TranslateService,
              private sharedService: DataSharingService, private jobSearchService: JobSearchService, private meta: Meta,
              private route: Router, private tokenStorage: TokenStorage, public xmlParser: NgxXml2jsonService, private cookieService: CookieService,private deviceDetector:DeviceDetectorService) {
    this.userAgent = this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    if(!route.navigated){
      if(sessionStorage.getItem("jobTypeFilter")){
        this.jobSearchFilter = JSON.parse(sessionStorage.getItem("jobTypeFilter"));
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
      }
    }
  }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    this.orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    // this.orgName = this.siteService.getOrgName();
    this.sharedService.getWidgetListName(keywords.searchPageName);
    this.getParamValues();
    this.hostName = config.baseWebAppUrl;
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyWord?this.keyWord+" ":"";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name'];
      if (res['meta_desc_text1'])
        this.searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];
    });
  }
  private getParamValues() {
      this.sharedService.sharedLocation.subscribe(location => this.location = location);
      this.sharedService.sharedIndustry.subscribe(industry => this.industryf = industry);
      this.activatedRouteParam = this.activatedRoute.queryParamMap.subscribe(param => {
        this.jobSearchService.sharedSearchPerformedValue.subscribe(searchPerformed => this.searchPerformed = searchPerformed);
        if (this.searchPerformed) {
          this.isScrolled = false;
          this.jobSearchService.setScrolledValue(this.isScrolled);
          this.jobSearchService.pageNo = 0;
          this.jobSearchService.totalPage = 1;
          this.loadMore = true;
          this.industryf = '';
          this.location = '';
          this.specialismId="";
          this.subSpecialismId="";
          this.sharedService.sendIndustry(this.industryf);
          this.sharedService.sendLocation(this.location);
          this.jobSearchService.sharedKeyWord.subscribe(keyWord => this.keyWord = keyWord);
          this.jobSearchService.sharedLocation.subscribe(location => this.locationf = location);
          if(!this.jobSearchFilter)
            this.jobSearchService.sharedJobSearchFilterData.subscribe(searchFilter => this.jobSearchFilter = searchFilter);
          this.jobSearchService.sharedJobTypeFilterupdated.subscribe(jobtype => this.jobType = jobtype);
        } else {
          this.isScrolled = false;
          this.jobSearchService.setScrolledValue(this.isScrolled);
          this.loadMore = true;
          this.specialismId="";
          this.subSpecialismId="";
          this.industryf = '';
          this.location = '';
          this.sharedService.sendIndustry(this.industryf);
          this.sharedService.sendLocation(this.location);
          this.jobSearchService.pageNo = 0;
          this.jobSearchService.totalPage = 1;
          if(this.route.navigated)
            this.jobSearchService.sharedJobSearchFilterData.subscribe(searchFilter => this.jobSearchFilter = searchFilter);

        }
        if (param.get('q')) {
          this.keyWord = param.get('q');
          if (this.searchPerformed) {
            this.industryf = '';
            this.location = '';
            this.sharedService.sendIndustry(this.industryf);
            this.sharedService.sendLocation(this.location);
          }
        }
        if (param.get('locationf')) {
          this.location = param.get('locationf');
          this.sharedService.sendLocation(this.location);
        }
        if (param.get('specialismId')) {
          this.specialismId = param.get('specialismId');
        }
        if (param.get('subSpecialismId')) {
          this.subSpecialismId = param.get('subSpecialismId');
        }
        if (param.get('location')) {
          this.locationf = param.get('location');
        }
        if (param.get('industryf')) {
          this.industryf = param.get('industryf');
          this.sharedService.sendIndustry(this.industryf);
        }
        if (param.get('jobType')) {
          this.jobType = param.get('jobType');
        }
        this.googleJobResponseList = [];
        this.jobSearchService.googleJobResponseList = [];
        this.locationFilterList = [];
        this.locationList = [];
        this.industriesList = [];
        this.slicedIndustries = [];
        this.jobSearchService.workingPatternFilterList = [];
        this.jobSearchService.sortTypeFilter = [];
        this.jobSearchService.JobTypeFilterList = [];
        if (param.get('jobType')) {
          this.pageToken='0';
          this.getExtraFields(this.jobSearchFilter);
        }
      });
  }

  private getSponsoredJob(jobFilter,type):Observable<any> {
    //console.log("inside getSponsoredJob",type);
    let sponsored :any;
    if (this.jobSearchService.pageNo % 10 === 0) {
      //console.log("inside if getsponsored");
      sponsored=this.setGetJobJson(true,jobFilter,'sponsored');
      //console.log("inside getSponsoredJob googleJobRequest..",this.googleJobRequest);
      /*if (this.googleJobRequest) {
        sponsored=this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, this.googleJobRequest)
          /!*if (res.status === config.SUCCESS) {
            this.sponsoredGoogleJobResponseList = this.jobSearchService.processSponsoredJob(res.data.result.jobs, this.domainConfig?this.domainConfig.crossCountryUrl:'', res.data.result.histograms, 'search', this.xmlParser);
          }*!/
      }*/
    }
    return sponsored;

  }

  private getNonSponsoredJob(jobFilter,type):Observable<any> {
    //console.log("inside non sponsored..",type);
    let nonSponsored:any;
    if(type==='scrolled' && this.jobSearchService.pageNo>0) {
      if (this.jobSearchService.pageNo < this.jobSearchService.totalPage) {
        nonSponsored=this.setGetJobJson(false, jobFilter,'nonSponsored');
       /* if (this.googleJobRequest) {
          nonSponsored = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, this.googleJobRequest);
        }*/
      } else {
        this.isScrolled = false;
        this.jobSearchService.setScrolledValue(this.isScrolled);

      }
    }else if(!type){
      //console.log("inside else non sponsored");
      if (this.jobSearchService.pageNo < this.jobSearchService.totalPage) {
        nonSponsored=this.setGetJobJson(false, jobFilter,'nonSponsored');
        //console.log("inside else non sponsored",this.googleJobRequest);
        /*if (this.googleJobRequest) {
          nonSponsored = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, this.googleJobRequest);
        }*/
      } else {
        this.isScrolled = false;
        this.jobSearchService.setScrolledValue(this.isScrolled);

      }
    }else {
      this.isScrolled = false;
      this.jobSearchService.setScrolledValue(this.isScrolled);

    }
    return nonSponsored;
  }

  setGetJobJson(isSponsored,jobFilter,jobType) {
    if (jobFilter) {
      //console.log("inside if set json jobfilter");
      this.googleJobRequest = this.jobSearchService.setJsonRequest(this.locationf, jobFilter.flexibleworking, jobFilter.fullTime, this.industryf, isSponsored,
        jobFilter.jobType,
        jobFilter.partTime, this.keyWord?this.keyWord.replace('%2B','+'):this.keyWord, this.location, jobFilter.sortType ? jobFilter.sortType : 'RELEVANCE_DESC',
        this.specialismId, this.subSpecialismId, jobFilter.minSal ? 'N' : '', this.userAgent, this.domainConfig?this.domainConfig.radius:100,
        this.domainConfig?this.domainConfig.isCrossCountry:false,
        this.domainConfig?this.domainConfig.isResponseJob:false,
        this.domainConfig?this.domainConfig.responseSiteLocale:'', (this.isScrolled && this.pageToken) ? this.pageToken : '0', '', '',
        this.domainConfig?this.domainConfig.crossCountryUrl:'', jobFilter.minSal? jobFilter.minSalValue : '',
        jobFilter.maxSal ? jobFilter.maxSalValue : '', jobFilter.payTypeValue, 'search',this.cookieDomain);
    } else {
      //console.log("inside else set json jobfilter");
      this.googleJobRequest = this.jobSearchService.setJsonRequest(this.locationf, 'false', 'false', this.industryf, isSponsored,
        '',
        'false', this.keyWord?this.keyWord.replace('%2B','+'):this.keyWord, this.location, 'RELEVANCE_DESC',
        this.specialismId, this.subSpecialismId, '', this.userAgent, this.domainConfig?this.domainConfig.radius:100,
        this.domainConfig?this.domainConfig.isCrossCountry:false,
        this.domainConfig?this.domainConfig.isResponseJob:false,
        this.domainConfig?this.domainConfig.responseSiteLocale:'', this.pageToken ? this.pageToken : '0', '', '',
        this.domainConfig?this.domainConfig.crossCountryUrl:'', '',
        '', '', 'search',this.cookieDomain);
    }
    let googleJobResponse:any;
    this.sharedService.sharedClientConfig.subscribe(clientConfig => {
      if(clientConfig?.jobMedium)
      this.jobMedium = clientConfig.jobMedium
    });
    this.jobMedium = this.jobMedium ? this.jobMedium : 'GCJ';
    switch(this.jobMedium) {
      case 'GCJ':
        if(jobType==='sponsored') {
          googleJobResponse=this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, this.googleJobRequest)
        } else {
          googleJobResponse = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getgooglejobs, this.googleJobRequest);
        }
        break;
      case '':
        break;
    }
    return googleJobResponse;
  }

  private getExtraFields(jobFilter) {
   //console.log("extra field called");
     this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
       if (domainConfig) {
         this.searchCalled = true;
         this.domainConfig = domainConfig;
         this.getJobs(jobFilter, '');
       }
     });
  }
  getJobs(jobFilter,type){
    this.dataLoaded=false;
    //console.log("inside get job..");
    let sponsored=this.getSponsoredJob(jobFilter,type);
    let nonSponsored = this.getNonSponsoredJob(jobFilter,type);
    let masterFilterData:any;
    if(!type)
       masterFilterData = this.getJobFilters();
    if(sponsored && nonSponsored && masterFilterData){
      forkJoin([sponsored,nonSponsored,masterFilterData]).subscribe(result=>{
       this.processJobMix(result[0],result[1],result[2])
      });
    }else if (!sponsored && nonSponsored && masterFilterData){
      forkJoin([nonSponsored,masterFilterData]).subscribe(result=>{
        this.processJobMix(null,result[0],result[1]);
      });
    }else if (!sponsored && !nonSponsored && masterFilterData){
      forkJoin([masterFilterData]).subscribe(result=>{
        this.processJobMix(null,null,result[0]);
      });
    }else if (sponsored && !nonSponsored && masterFilterData){
      forkJoin([sponsored,masterFilterData]).subscribe(result=>{
        this.processJobMix(result[0],null,result[1]);
      });
    }
    else if (sponsored && !nonSponsored && !masterFilterData){
      forkJoin([sponsored]).subscribe(result=>{
        this.processJobMix(result[0],null,null);
      });
    }
    else if (sponsored && nonSponsored && !masterFilterData){
      forkJoin([sponsored,nonSponsored]).subscribe(result=>{
        this.processJobMix(result[0],result[1],null);
      });
    }else if (!sponsored && nonSponsored && !masterFilterData){
      forkJoin([nonSponsored]).subscribe(result=>{
        this.processJobMix(null,result[0],null);
      });
    }
    if(this.cookieService.get('alerts').split('|').indexOf(this.keyWord) == -1){
      this.showPopup = true;
    }
  }
  processJobMix(sponsored,nonSponsored,masterFilterData){
    //Start of sponsored//
    if (sponsored && sponsored.status === config.SUCCESS && sponsored.data && (this.orgName !== 'yourrailindustryjob' || this.orgName !== 'geniuswithin')) {
      this.jobSearchService.processSponsoredJob(sponsored.data.result.jobs, this.domainConfig ? this.domainConfig.crossCountryUrl : '', sponsored.data.result.histograms, 'search', this.xmlParser)
    }
    //Start of nonSponsored//
    if (nonSponsored && nonSponsored.status === config.SUCCESS && nonSponsored.data) {
      this.isScrolled = false;
      this.jobSearchService.setScrolledValue(this.isScrolled);
      //if(this.pageToken!==nonSponsored.data.result.nextPageToken) {
        this.pageToken = nonSponsored.data.result.nextPageToken;
        this.googleJobResponseList = this.jobSearchService.processNonSponsoredJob(nonSponsored.data.result, this.domainConfig.crossCountryUrl ? this.domainConfig.crossCountryUrl : '', 'search',
          this.xmlParser, this.requestService, this.siteService, this.cookieService, this.route);
     // }
      this.jobSearchService.totalPage = Math.ceil(nonSponsored.data.result.resultCount / 10);
      this.jobSearchService.pageNo = this.jobSearchService.pageNo + 1;
      this.noResultFound = this.googleJobResponseList.length === 0;
    } else {
      this.noResultFound = this.googleJobResponseList.length === 0;
      this.isScrolled = false;
      this.jobSearchService.setScrolledValue(this.isScrolled);
    }
    //Start of jobFilter//
    if (masterFilterData && masterFilterData.status == config.SUCCESS && masterFilterData.data) {
      this.jobSearchService.procesJobFilter(masterFilterData.data);
      this.sharedService.sendWorkingPatternFilter(this.jobSearchService.workingPatternFilterList);
      this.sharedService.sendSortTypeFilter(this.jobSearchService.sortTypeFilter);
      this.sharedService.sendJobTypeFilterList(this.jobSearchService.JobTypeFilterList);
      this.sharedService.sendPayTypeList(this.jobSearchService.payType);
      this.sharedService.sendPayTypeDataList(this.jobSearchService.payTypeFilter);
      this.locationFilterList = this.jobSearchService.locationFilterList;
      this.industriesList = this.jobSearchService.industriesList;
      this.slicedIndustries = this.jobSearchService.slicedIndustries;
      this.locationList = this.jobSearchService.locationList;
    }
    this.dataLoaded=true;

  }

  private getJobFilters():Observable<any> {
    this.jobSearchService.workingPatternFilterList=[];
    this.jobSearchService.sortTypeFilter=[];
    this.jobSearchService.JobTypeFilterList=[];
    this.jobSearchService.payType=[];
    this.jobSearchService.payTypeFilter=[];
    return this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getJobFilters, null, null);
  }

  onScroll() {
    if(this.dataLoaded){
      //console.log("i am scrolled..");
      this.isScrolled = true;
      this.jobSearchService.setScrolledValue(this.isScrolled);
      this.getJobs(this.jobSearchFilter,'scrolled');
    }else{
      return;
    }

  }


  ngOnDestroy() {
    this.sharedService.clearSearchData();
    this.jobSearchService.clearFilter();
    this.googleJobResponseList = [];
    this.jobSearchService.googleJobResponseList = [];
    this.locationFilterList = [];
    this.locationList = [];
    this.industriesList = [];
    this.slicedIndustries = [];
    this.jobSearchService.workingPatternFilterList = [];
    this.jobSearchService.sortTypeFilter = [];
    this.jobSearchService.JobTypeFilterList = [];
    this.jobSearchService.pageNo = 0;
    this.jobSearchService.totalPage = 1;
    this.noResultFound = false;
    this.isScrolled = false;
    //this.activatedRouteParam.unsubscribe();
    localStorage.removeItem('jobTypeFilter');
    localStorage.removeItem("alertid")
  }

  openFilters($event: boolean) {
    this.showFilters=$event;
  }
}
