<div class="cdk-overlay-dark-backdrop"></div>
<section class="market_popup">
  <div class="row cdk-overlay-container">
    <div class="col-12 col-md-4 border mb-5">
      <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closePopup()">+</a>
      <div class="content-container" *ngIf="consentPopUp">
        <h3 [translate]="'marketing_consent_header'"></h3>
        <p [translate]="'marketing_consent_body'"></p>
        <button *ngIf="isHungaryCountry" id="gtm_signin_btn" type="submit" class="btn btn-secondary" [translate]="'yes_want_text'"  (click)="submit('consentPopUp',true)"></button>
        <button *ngIf="isHungaryCountry" id="gtm_signin_btn" type="submit" class="btn btn-secondary" [translate]="'no_want_text'" (click)="submit('consentPopUp',false)"></button>
        <button *ngIf="!isHungaryCountry" id="gtm_signin_btn" type="submit" class="btn btn-secondary" [translate]="'yes_text'"  (click)="submit('consentPopUp',true)"></button>
        <button *ngIf="!isHungaryCountry" id="gtm_signin_btn" type="submit" class="btn btn-secondary" [translate]="'no_text'" (click)="submit('consentPopUp',false)"></button>
      </div>
      <div class="content-container" *ngIf="!consentPopUp">
        <h3 [translate]="'marketing_popup_title'"></h3>

        <p [translate]="'marketing_popup_desc'"></p>

        <form id="gtm_market_popup" [formGroup]="marketSelectForm" (ngSubmit)="submit('marketSelectForm',false)">
          <div class="form-group">
            <input class="" formControlName="email" type="checkbox">
            <label [textContent]="emailText"></label>
          </div>
          <div class="form-group">
            <input class="" formControlName="sms" type="checkbox">
            <label [textContent]="smsText"></label>
          </div>
          <div class="form-group">
            <input class="" formControlName="whatsapp" type="checkbox">
            <label [textContent]="whatsAppText"></label>
          </div>
          <div class="form-group">
            <input class="" formControlName="dontreceive" type="checkbox">
            <label [textContent]="dontReciveText"></label>
          </div>
          <div _ngcontent-serverapp-c7="" class="action-bar">
            <button id="gtm_signin_btn" type="submit" class="btn btn-secondary" [translate]="'submit_button_modal_popup'"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
