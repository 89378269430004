<div class="new-user bg-white" *ngIf="!isAzureEnabled">
  <form [formGroup]="signUpFormGroup" (ngSubmit)="register()" id="gtm_register_new">
    <div class="registration_title">
      <h2 class="spec-cv-h2" *ngIf="specRoute||registerRoute" [translate]="specRoute?'spec_cv_title_text':'new_to_hays_text'">
      </h2>
      <p class="help-text" *ngIf="registerRoute" [translate]="'new_to_hays_description'"></p>
      <p class="help-text" *ngIf="specRoute" [translate]="'hays_consultant'"></p>
    </div>

    <h2 class="spec-cv-h2" *ngIf="updateDetailsRoute" [translate]="'update_your_details_title_text'">
    </h2>
    <div [ngClass]="specRoute || updateDetailsRoute || registerRoute ?'col  border spec-cv':'col border'">
      <h3 class="new-user" [translate]="'spec_cv_title_text'"
          *ngIf="!specRoute && !updateDetailsRoute && !registerRoute">
        <p class="help-text" [translate]="'new_to_hays_description'"></p>
      </h3>

      <ng-container *ngIf="booleanFlag==='true'">
        <app-jp-register-new-kana [parent]="signUpFormGroup" [formSubmitted]="formSubmitted"
                                  [katkanalastname]="katkanalastname"
                                  [katkanafirstname]="katkanafirstname" [firstName]="firstName"
                                  [lastName]="lastName"></app-jp-register-new-kana>
      </ng-container>
      <ng-container *ngIf="!booleanFlag || booleanFlag==='false'">
        <div class="form-group">
          <label [translate]="'enter_first_name'"></label>
          <input type="text" [(ngModel)]="firstName" class="form-control" id="filed1" autocomplete="off" (paste)="sanitizePaste($event)"
                 placeholder="{{'enter_first_name_placeholder' | translate}}" formControlName="firstname" required>
          <div
            *ngIf="signUpFormGroup['controls'].firstname.invalid && formSubmitted">
        <span class="error-msg"
              *ngIf="signUpFormGroup['controls'].firstname.errors?.required || signUpFormGroup['controls'].firstname.errors?.whitespace"
              [translate]="'invalid_first_name_msg'">
        </span>
          </div>
        </div>

        <div class="form-group">
          <label [translate]="'enter_last_name'"></label>
          <input type="text" [(ngModel)]="lastName" class="form-control" id="filed2" autocomplete="off" (paste)="sanitizePaste($event)"
                 placeholder="{{'enter_last_name_placeholder' | translate}}" formControlName="lastname" required>
          <div
            *ngIf="signUpFormGroup['controls'].lastname.invalid && formSubmitted">
        <span class="error-msg"
              *ngIf="signUpFormGroup['controls'].lastname.errors?.required || signUpFormGroup['controls'].lastname.errors?.whitespace"
              [translate]="'invalid_last_name_msg'">
        </span>
          </div>
        </div>
      </ng-container>
      <div class="form-group" *ngIf="updateDetailsRoute">
        <label [translate]="'enter_mobile_label'"></label>
        <span class="input-symbol-plus">
        <input type="number"  [(ngModel)]="dialingCode" onKeyPress="if(this.value.length==4) return false;" (keyup)="onMobileChanged($event);"  class="form-control dialing-code d-inline-block" autocomplete="off"  formControlName="dialingCode" required />
        <input type="number" [(ngModel)]="mobileNumber" onKeyPress="if(this.value.length==11) return false;" (keyup)="onMobileChanged($event);" class="form-control d-inline-block mobile-no" id="filed4"
               autocomplete="off" placeholder="{{'enter_mobile_placeholder' | translate}}"
               formControlName="mobileNumber" required>
        </span>
        <span class="error-msg" *ngIf="mobileError" [translate]="'invalid_mobile_msg'">
        </span>
      </div>
      <div class="form-group">
        <label [translate]="updateDetailsRoute ? 'update_your_details_email_lable' : 'enter_email_label'"></label>
        <input *ngIf="specRoute || !registerRoute" type="text" [(ngModel)]="emailAddress" (keyup)="onEmailChanged($event);" class="form-control" id="filed3"
               autocomplete="off" placeholder="{{ ( updateDetailsRoute ? 'update_your_details_email_lable' : 'enter_email_placeholder') | translate}}"
               formControlName="email" required>
        <input *ngIf="registerRoute" type="text" [(ngModel)]="emailAddress" (keyup)="onEmailChanged($event);" class="form-control" id="gtm_register_email"
               autocomplete="off" placeholder="{{ ( updateDetailsRoute ? 'update_your_details_email_lable' : 'enter_email_placeholder') | translate}}"
               formControlName="email" required>
        <span class="error-msg" *ngIf="emailError" [translate]="'invalid_email_msg'">
        </span>
        <!--<div
          *ngIf="signUpFormGroup['controls'].email.invalid && formSubmitted">
          <span class="error-msg" *ngIf="signUpFormGroup['controls'].email.errors?.required || signUpFormGroup['controls'].email.errors?.pattern || emailError"
            [translate]="'invalid_email_msg'">
          </span>
        </div>-->
      </div>

      <div class="info-msg" [translate]="'upload_issue_info'"></div>
      <div>
        <app-jp-upload-cv [parent]="signUpFormGroup"
                          (showValidationMessage)="showValidation($event)"></app-jp-upload-cv>
        <div
          *ngIf="!signUpFormGroup['controls'].cvData.value && formSubmitted && showFileValidation">
        <span *ngIf="signUpFormGroup['controls'].cvData.errors?.required" class="error-msg"
              [translate]="'invalid_cv_msg'"></span>
        </div>

        <span *ngIf="showPopUp" class="error-msg" [translate]="'password_protected_warning'">
      </span>

      </div>
      <div class="form-group mb-0" *ngIf="languageProficiencyData && languageProficiencyData.length > 0" formArrayName="languageProficiency">
        <label class="languages-spoken-color" [translate]="'languages_spoken_label'"></label>
        <div class="form-group mb-0"  *ngFor="let languageProficiency of signUpFormGroup.get('languageProficiency')['controls']; let i = index"  [formGroup]="languageProficiency"> 
          <div class="row">
            <div class="col-sm-2 pr-md-0">
          <div class="form-check-inline">
            <label class="languages-spoken-color">{{languageProficiencyData[i].language}}</label>
          </div>
          </div>
          <div class="col-sm-10 pl-md-0">
          <div class="form-check-inline" *ngFor="let choice of languageProficiencyData[i].prof">
            <label class="form-check-label">
              <input type="radio" class="form-check-input"  formControlName="proficiency" [value]="choice.code_id">{{choice.level}}
            </label>
          </div>
          </div>
        </div>
        </div>

        <div
          *ngIf="signUpFormGroup['controls'].languageProficiency.invalid && formSubmitted">
        <span class="error-msg"
              [translate]="'languages_spoken_required'"></span>
        </div>
      </div>

      <div>
        <app-jp-sector [isAUSHaysOffice]="isAUSHaysOffice" [parent]="signUpFormGroup" [sector]="sector"
                       [officeSpecialism]="officeSpecialism"
                       [specialismId]="signUpFormGroup.value.expertise?signUpFormGroup.value.expertise:sector"></app-jp-sector>
        <div
          *ngIf="signUpFormGroup['controls'].expertise.invalid && formSubmitted">
        <span *ngIf="signUpFormGroup['controls'].expertise.errors?.required" class="error-msg"
              [translate]="'invalid_specialism_msg'"></span>
        </div>
      </div>

      <div>
        <app-jp-office-for-specialism [isAUSHaysOffice]="isAUSHaysOffice" [parent]="signUpFormGroup"
                                      [specialismId]="signUpFormGroup.value.expertise?signUpFormGroup.value.expertise:sector"
                                      [officeSpecialism]="officeSpecialism"></app-jp-office-for-specialism>
        <div
          *ngIf="signUpFormGroup['controls'].officeForSpecialism.invalid && formSubmitted">
        <span *ngIf="signUpFormGroup['controls'].officeForSpecialism.errors?.required" class="error-msg"
              [translate]="'invalid_office_msg'"></span>
        </div>
      </div>
      <app-jp-marketing-consent *ngIf="isMarketingConsentEnabled"
                                [isChecked]="isChecked" [parent]="signUpFormGroup"
                                [type]="'specCV'" (isConsentChecked)="getConsentChecked($event)"
                                [formSubmitted]="formSubmitted" [validationCheck]="validationCheck">
      </app-jp-marketing-consent>
      <div>
        <app-term-and-privacy *ngIf="!isMarketingConsentEnabled"
                              [isChecked]="isChecked" [parent]="signUpFormGroup"
                              (isConsentChecked)="getConsentChecked($event)" (privacyconsent2)="getPrivacyConsent2Checked($event)" [type]="'specCV'" 
                              [currentUserFlow] = 'currentUserFlow'>
        </app-term-and-privacy>
        <div
          *ngIf="formSubmitted && !validationCheck && !isChecked && !isMarketingConsentEnabled">
        <span class="error-msg"
              [translate]="'policy_validation_error'"></span>
        </div>
      </div>

      <ng-container *ngIf="!isCaptchaDisable">
        <app-jp-captcha [parent]="signUpFormGroup">
        </app-jp-captcha>
        <div
          *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === '' && formSubmitted">
        <span class="error-msg"
              [translate]="'captcha_validation_text'"></span>
        </div>
        <div
          *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === null"><span class="error-msg"
                                                                                      [translate]="'captcha_validation_text'"></span>
        </div>
      </ng-container>

      <div *ngIf="legalConsentVisible">
        <app-jp-legal-consent-popup [legalConsentVisible]=legalConsentVisible
                                    (consentFlag)="legalConsentFlag($event)"></app-jp-legal-consent-popup>
      </div>
      <div class="action-bar text-center" *ngIf="!specRoute && !updateDetailsRoute">
        <button id="gtm_submit_and_register_btn"
                [ngClass]="(showPopUp||disableButton)?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                type="submit" [disabled]="showPopUp || disableButton" [translate]="'Submit_and_register'"></button>
      </div>
      <div class="action-bar text-center" *ngIf="specRoute || updateDetailsRoute">
        <button id="gtm_submit_btn"
                [ngClass]="disableButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                type="submit" [disabled]="showPopUp || disableButton" [translate]="'submit_button'"></button>
      </div>
    </div>
  </form>
  <app-jp-marketing-consent-popup
    *ngIf="((isRegisterComponent && dataShareService.$openMarketContentPopup | async) || marketConsentPopup ) && !isPrivacyConsentEnabled" (consentFlag)="legalConsentFlag($event)" [consentPopUp]="marketConsentPopup"(crossClicked)="crossClicked($event)"></app-jp-marketing-consent-popup>
</div>


<app-jp-privacy-modal *ngIf = "isPrivacyPopUpDisplayed" (popUpCloseEvent) = 'onPopUpClose($event)' [modalInnerText] = "privacyPopUpContent">

</app-jp-privacy-modal>

<app-jp-marketing-setting *ngIf = 'isMarketingConsentPopUpDisplayed' (marketingConsentCloseEvent) = 'onMarketingConsentResponse($event)'>

</app-jp-marketing-setting>