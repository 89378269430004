

<div [ngClass]="showSingleAlert?'logged-in-user job-alert-container  whatsapp-active':isLoggedin?'logged-in-user job-alert-container':'job-alert-container'" *ngIf="keyWord && extraConfig && !isEmailAlertSaved && isEmailAlert">
  <div *ngIf="!isLoggedin" class="col-sm-12 col-md-12 my-3 b_middle text-center" (isEmailAlertButton)="toggleButton($event)">
    <button   id="gtm_email_alert_anonymous_save" [ngClass]="'btn btn-default'" type="submit" (click)="$event.preventDefault();openingModal();"[translate]="'get_job_alert_text'" ></button>
  </div>
  <ul *ngIf="extraConfig.isWhatsappAlert && (!emailWhatsappAlertSaved && !maximumAlertExceeds) && isLoggedin">
    <li class="item" [translate]="'job_alert_label'"></li>
    <li [ngClass]="disableEmailButton?'disabled email':'email'" *ngIf="isLoggedin"><i class="icon-mail-alt"></i>
      <button  id="gtm_email_alert_loggedIn_save" [ngClass]="'btn btn-default'" (click)="saveJobAlert('email')" [disabled]="disableEmailButton"
               [translate]="saved?'alertSaved_text':hideWhatsapp?'email_and_whatsapp_text':'email_job_alert_text'"></button>
    </li>
    <!-- Whatsapp Alert -->
    <li *ngIf="!showSingleAlert || !hideWhatsapp" [ngClass]="disableWhatsapp?'disabled whatsapp':'whatsapp'"><i class=""></i>
      <input type="text" [ngClass]="waCodeError?'country-code form-control error-input':'country-code form-control'" [disabled]="disableWhatsapp"  [(ngModel)]="whatsappCountryCode"
             [placeholder]="extraConfig.wspCountryCode" (keyup)="validateInput('',whatsappCountryCode,'','')" maxlength="5">
      <input type="tel" [ngClass]="whatsappNumberErrorFlag?'form-control phone-no error-input':'form-control phone-no'" [disabled]="disableWhatsapp" placeholder="{{'enter_mobile_number_text'|translate}}" [(ngModel)]="whatsappNumber"
             (keydown)="validateInput('','',whatsappNumber,$event)" maxlength="11">
      <button id="gtm_whtsapp_alert_save" class="btn whatsapp" (click)="saveJobAlert('whatsapp')" [disabled]="disableWhatsapp" [translate]="saveWhatsapp?'alertSaved_text':'whatsapp_alert_text'"></button>
      <!--Error essage for whatsapp country code-->
      <label class="displayMessage" *ngIf="waCodeError" [translate]="'whatsapp_country_code_error_text'"></label>
    </li>
    <li class="item">
      <a class="icon-cancel" (click)="keyWord=''"></a>
    </li>
  </ul>
  <!--Email and Whatspp alert in case of loggedinIn-->


  <!--For non whatsapp countries-->
  <app-jp-email-alert (isEmailAlertSaved)="getSavedEmailAlertValue($event)" *ngIf="!extraConfig.isWhatsappAlert && extraConfig.isEmailAlert && !isEmailAlertSaved && !maximumAlertExceeds && isLoggedin"
                      [personalDetails]="personalDetails" [keyWord]="keyWord" (isAlertClosed)=isAlertClosed($event) (ismaximumAlertExceeds)="isMaxAlert($event)"></app-jp-email-alert>
  <!--in case of success messages after email and whatsapp alert saved-->
  <div *ngIf="(emailWhatsappAlertSaved || maximumAlertExceeds) && isLoggedin ">
    <div [ngClass]="maximumAlertExceeds?'maximum-alerts saved-success-msg':'saved-success-msg'">
      <i class="icon-ok"></i>
      <p [translate]="maximumAlertExceeds?'error_msg_text':'email_whatsapp_success_msg'"></p>
      <a class="icon-cancel" (click)="keyWord=''"></a>

    </div>
  </div>
</div>
