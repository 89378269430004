import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RequestService} from "../../../../global/services/request/request.service";
import {DataSharingService} from "../../../../jobportal/service/data-sharing.service";
import {TranslateService} from "@ngx-translate/core";
import {SiteService} from "../../../../global/services/site/site.service";
import {SetTitleService} from "../../../../jobportal/service/set-title.service";
import {apiEndPoints, config, environment, keywords, routingEndpoint} from "../../../../../environments/root/environment";
import {JobAlertResponse} from "../../../../jobportal/model/response/job-alert-response";
import {TokenStorage} from "../../../../global/services/tokenStorage/token-storage";
import {GetSavedJobAlertRequest, GetSavedJobAlertResponse} from "../../../model/request/erp-job-alert";
import {CookieService} from 'ngx-cookie-service';
import {ModalService} from "../../../service/modal.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-erp-manage-job-alert',
  templateUrl: './erp-manage-job-alert.component.html',
  styleUrls: ['./erp-manage-job-alert.component.scss']
})
export class ErpManageJobAlertComponent implements OnInit, OnDestroy {


  public jobAlertResponse :GetSavedJobAlertResponse[]=[];
  public hasAlerts : boolean = false;
  public errorRoute: string;
  public search: string;
  private confirmMsg: string;
  public delete: boolean;
  private signedurl: string;
  private jobSource: string;
  private identifier: string;
  public isDataLoaded:boolean = false;
  public loggedIn:boolean = false;
  public separator3 = ' in ';
  SubscriptionList:Subscription[]=[];

  constructor(private activatedRoute: ActivatedRoute,private router: Router,
              private requestService: RequestService, private dataSharingService: DataSharingService,
              private _translate: TranslateService, private siteService: SiteService,
              private titleService:SetTitleService, private token:TokenStorage,  private cookieService: CookieService,
              private modalService:ModalService)
  {
    const translateGetTranslationSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['manage_job_alert']);
      this.confirmMsg = (res['confirm_job_alert_removal_msg']);
    });
    this.SubscriptionList.push(translateGetTranslationSubscription);

  }

  ngOnInit() {
    this.getRouteUrls();
    this.getAlerts();
  }

  getRouteUrls() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.search = (base[routingEndpoint.search]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.search = base[routingEndpoint.search];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }

  getAlerts(){
    this.signedurl = this.activatedRoute.snapshot.queryParamMap.get('signedurl');
    this.identifier = this.activatedRoute.snapshot.queryParamMap.get('identifier');
    let defaultJobSource='';
    const dataSharingServiceSharedClientConfigSubscriptionSubscription = this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
    this.jobSource = this.activatedRoute.snapshot.queryParamMap.get('jobSource')? this.activatedRoute.snapshot.queryParamMap.get('jobSource') : defaultJobSource;
    if(this.signedurl && this.signedurl.length>0 && this.jobSource == keywords.hays) {  //from SFMC
        this.getSavedjobAlerts(this.signedurl, this.jobSource);
    }
    else if(this.identifier && this.identifier.length>0){ //from IS
        this.getSavedjobAlerts(this.identifier, this.jobSource);
    }
    else if(this.token.getLoggedInToken()){
      this.loggedIn = true;
      this.getSavedjobAlerts('', '');
    }
    else{
      this.router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
    }
    this.SubscriptionList.push(dataSharingServiceSharedClientConfigSubscriptionSubscription);

  }


  getSavedjobAlerts(identifier, jobsource){
    let encryptedIdentifier= localStorage.getItem("encryptedIdentifier");
    let payload =null;
    if(encryptedIdentifier)
       payload = new GetSavedJobAlertRequest(encryptedIdentifier, jobsource);
    else payload = new GetSavedJobAlertRequest(encodeURI (identifier), jobsource);
      const getSavedjobAlertsRequestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getJobAlerts, payload)
          .subscribe( response => {
            localStorage.removeItem("encryptedIdentifier");
            if(response.status === config.SUCCESS && response.data) {
                this.jobAlertResponse=response.data.alertprofileList;
                if(this.jobAlertResponse.length>0){
                    this.hasAlerts=true;
                    this.isDataLoaded = true;
                    let alertList = [ ];
                    this.jobAlertResponse.forEach(item => alertList.push(item.alertname));
                    this.cookieService.set('alerts', alertList.join('|'), 0,'/');
                }
                else{
                  this.hasAlerts = false;
                  this.isDataLoaded = true;
                  this.jobAlertResponse=undefined;
                }
            } else {
                  this.hasAlerts = false;
                  this.jobAlertResponse=undefined;
            }
          },error => {
              this.router.navigate([this.errorRoute],{queryParams:{erpStatus:keywords.fail}});
      });
      this.SubscriptionList.push(getSavedjobAlertsRequestServicePostRequestGateWaySubscription);

  }

  redirectToSearch(){
    //this.router.navigate(this.search);
    window.location.href = window.location.origin + [this.search];
  }

  editSavedJobAlert(alert: JobAlertResponse) {
    let searchURL = window.location.origin+
      [this.search]+
      "?alertid="+alert.alertprofileid+"&q="+alert.alertname;
    window.location.href=searchURL;
  }
  openModal(id:string)
  {
    this.modalService.open(id);
  }

  deleteSavedJobAlert(alert: JobAlertResponse) {
    if (confirm(this.confirmMsg)) {
      this.delete = true;
      let deleteJson = {"alertId": alert.alertprofileid};
      const deleteSavedJobAlertRequestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.deleteJobAlert, deleteJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          this.jobAlertResponse.forEach((jobAlert, index) => {
            if (jobAlert.alertprofileid === alert.alertprofileid) {
              this.jobAlertResponse.splice(index, 1);
            }
          });
          if (this.jobAlertResponse && this.jobAlertResponse.length === 0)
            this.jobAlertResponse = undefined;
            this.delete = false;
        }
      }, error => {
        this.router.navigate([this.errorRoute], {queryParams: {deleteJobAlert: 'N'}})
        this.delete = false;
      })
      this.SubscriptionList.push(deleteSavedJobAlertRequestServicePostRequestGateWaySubscription);
    }

  }
  
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
