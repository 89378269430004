<section class="unsubscribeBox">
  <div class="unsubscribe">
    <h2 *ngIf="isEshotUpdateValue=='Y'"><img src="../assets/images/check-circle.png" class="mr-3"/><span [translate]="'unsubscribe_you_have'"></span><span [translate]="'unsubscribe_successfully'"></span></h2>
    <h2 *ngIf="isEshotUpdateValue=='N'"><img src="../assets/images/check-circle.png" class="mr-3"/><span [translate]="'resubscribe_you_have'"></span><span [translate]="'resubscribe_successfully'"></span></h2>

    <div class=" unsubscribeMsg">
      <div class="" *ngIf="isEshotUpdateValue=='Y'">
        <!--<img class="imageMsg" src="../assets/images/eo_circle_green_checkmark.png" alt="Green checkmark">-->
        <span class="content" [translate]="'unsubscribe_receive_marketing_emails'"></span>
        <span class="content" [translate]="'unsubscribe_mistake_please_click'"></span>
        <a [id]="'gtm_unsubscribe_submit_btn'" router="currentUrl" (click)="unsubscribe('N')">{{'resubscribe'|translate}}</a>
        <span class="content" *ngIf="isJapanCountry || isChinaCountry || isSwedenCountry || isCzechCountry || isPolandCountry || isRomaniaCountry " [translate]="'unsubscribe_please_register'"></span>
      </div>

      <div class="" *ngIf="isEshotUpdateValue=='N'">
        <!--<img class="imageMsg" src="../assets/images/eo_circle_green_checkmark.png" alt="Green checkmark">-->
        <span class="content" [translate]="'resubscribe_receive_marketing_emails'"></span>
        <span class="content" [translate]="'resubscribe_mistake_please_click'"></span>
        <a [id]="'gtm_unsubscribe_submit_btn'" router="currentUrl" (click)="unsubscribe('Y')">{{'unsubscribe'|translate}}</a>
        <span class="content" *ngIf="isJapanCountry || isChinaCountry || isSwedenCountry || isCzechCountry || isPolandCountry " [translate]="'resubscribe_please_stop_distribution'"></span>
      </div>
    </div>

    <div class="sign-in mt-4" *ngIf="isEshotUpdateValue=='Y' && isNewZealandCountry ||isAustraliaCountry ">
      <span>{{'unsubscribe_hays_account_click'|translate}} </span>
      <a id="gtm_unsubs_login_redirect" [href]="loginUrl">{{'unsubscribe_signin'|translate}}</a>
      <span>{{'unsubscribe_manage_marketing_preferences'|translate}} </span>
    </div>
  </div>
</section>
