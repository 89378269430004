import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RequestService} from "../../../../global/services/request/request.service";
import {DataSharingService} from "../../../../jobportal/service/data-sharing.service";
import {TranslateService} from "@ngx-translate/core";
import {SiteService} from "../../../../global/services/site/site.service";
import {SetTitleService} from "../../../../jobportal/service/set-title.service";
import {TokenStorage} from "../../../../global/services/tokenStorage/token-storage";
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from "../../../../../environments/root/environment";
import {PersonalDetails} from "../../../../jobportal/model/response/my-profile";
import {
  GetConsentRequest,
  GetConsentResponseData, PrivacyPolicyRequest, PrivacyPolicyResponse,
  UpdateConsentRequest
} from "../../../model/request/erp-marketing-pref";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-marketing-preferences',
  templateUrl: './erp-marketing-preferences.component.html',
  styleUrls: ['./erp-marketing-preferences.component.scss'],
  providers:[]
})
export class ErpMarketingPreferencesComponent implements OnInit, OnDestroy {

  public buttonDisable: boolean;
  public isDataLoaded :boolean;
  public updateClicked: boolean;
  public emailAdd: string;
  public portalProfileId: string;
  public errorRoute: string;
  public erpThankyouPage: string;
  public secureJobAlert: string;
  public marketingPref: string;
  public jobAlert: string;
  public getConsentResponseData: GetConsentResponseData;
  private yesText: string;
  private personalDetails:PersonalDetails;
  private signedurl: string;
  public consentUpdated: boolean;
  public manageJobAlertUrl: string;
  public privacyPolicyData:PrivacyPolicyResponse;
  private isLoggedIn:boolean;
  SubscriptionList:Subscription[]=[];

  constructor(private activatedRoute: ActivatedRoute,private router: Router,
              private requestService: RequestService, private dataSharingService: DataSharingService,
              private _translate: TranslateService, private siteService: SiteService,
              private titleService:SetTitleService,private token:TokenStorage) {
   const dataSharingServiceSharedPersonalDetailsSubscription =  this.dataSharingService.sharedPersonalDetails.subscribe(personalData=>{
      this.personalDetails = personalData;
    });
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    const translategetTranslationMarketingPreferenceSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['marketing_preference_heading_text']);
    });
    const translategetTranslationYesTextSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.yesText = (res['yes_text']);
    });
    this.buttonDisable=true;
    this.consentUpdated=false;
    this.SubscriptionList.push(dataSharingServiceSharedPersonalDetailsSubscription);
    this.SubscriptionList.push(translategetTranslationMarketingPreferenceSubscription);
    this.SubscriptionList.push(translategetTranslationYesTextSubscription);

  }

  ngOnInit() {
    this.isLoggedIn=false;
    if (this.token.getLoggedInToken()){
      this.isLoggedIn = true;
    }
    this.getRouteUrls()
    if(this.router.url.indexOf('thank-you')>-1 || this.router.url.indexOf('marketing-preferences-confirmed') >-1){
      this.consentUpdated=true;
    }else{
      this.getConsent();
    }
    this.getPrivacyPolicy();
  }

  getRouteUrls() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.secureJobAlert = (base[routingEndpoint.secureErpManageJobAlert]).replace(':orgName',orgName);
      this.jobAlert = (base[routingEndpoint.erpManageJobAlert]).replace(':orgName',orgName);
      this.erpThankyouPage = (base[routingEndpoint.erpThankyouPage]).replace(':orgName',orgName);
      this.marketingPref = (base[routingEndpoint.erpMarketingPref]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.secureJobAlert = base[routingEndpoint.secureErpManageJobAlert];
      this.jobAlert = base[routingEndpoint.erpManageJobAlert];
      this.marketingPref = base[routingEndpoint.erpMarketingPref];
      this.errorRoute = base[routingEndpoint.errorPage];
      if(this.isLoggedIn &&  base[routingEndpoint.secureErpThankyouPage]){
        this.erpThankyouPage = base[routingEndpoint.secureErpThankyouPage];
      }else {
        this.erpThankyouPage = base[routingEndpoint.erpThankyouPage];
      }
    }
  }

  getConsent(){
    this.signedurl = this.activatedRoute.snapshot.queryParamMap.get('signedurl');
    if(this.signedurl && this.signedurl.length>0) {
      this.getMarketingPrefConsent(this.signedurl);
      this.manageJobAlertUrl = this.jobAlert+encodeURI("?signedurl="+this.signedurl+"&jobSource="+keywords.hays);
    }
    else if(this.token.getLoggedInToken()){
      this.getMarketingPrefConsent(null);
      this.manageJobAlertUrl = this.secureJobAlert;
    }
    else{
      this.router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
    }
  }

  getMarketingPrefConsent(signedurl){
    let jsonData = new GetConsentRequest(signedurl,this.router.url.split('?')[0]);
    const getMarketingPrefConsentrequestServicepostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getErpMarketingPref, jsonData)
      .subscribe( response => {
        if(response.status === config.SUCCESS && response.data) {
            this.getConsentResponseData = response.data;
          this.isDataLoaded = true;
        } else {
          // this.router.navigate([this.baseRoute[routingEndpoint.errorPage]],{queryParams:{erpStatus:keywords.fail}});
          this.getConsentResponseData = new GetConsentResponseData(true,true);
          this.isDataLoaded = true;
        }
      },error => {
        this.getConsentResponseData = new GetConsentResponseData(true,true);
        this.isDataLoaded = true;
        //this.router.navigate([this.baseRoute[routingEndpoint.errorPage]],{queryParams:{erpStatus:keywords.fail}});
      });
      this.SubscriptionList.push(getMarketingPrefConsentrequestServicepostRequestGateWaySubscription);

  }


  updateMarketingPref() {
    if(this.signedurl && this.signedurl.length>0) {
      this.saveMarketingPrefConsent(this.signedurl);
    }
    else if(this.token.getLoggedInToken()){
      this.saveMarketingPrefConsent(null);
    }
    else{
      this.router.navigate([this.errorRoute], {queryParams:{erpExpire:'Y'}});
    }
  }

  saveMarketingPrefConsent(signedurl){
    this.updateClicked = true;
    this.buttonDisable = true;
    let jsonData = new UpdateConsentRequest(this.signedurl, this.getConsentResponseData.eshot, this.getConsentResponseData.sms,this.router.url.split('?')[0]);
   const saveMarketingPrefConsentRequestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.updateErpMarketingPref, jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.consentUpdated = true;
          this.router.navigate([this.erpThankyouPage],{queryParams:{update:keywords.success}});
        }
        else{
          this.router.navigate([this.errorRoute],{queryParams:{erpStatus:keywords.fail}});
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{erpStatus:keywords.fail}});
      });
      this.SubscriptionList.push(saveMarketingPrefConsentRequestServicePostRequestGateWaySubscription);

  }

  setService(event,type){
    this.buttonDisable=false;
    switch (type){
      case keywords.email:
        this.getConsentResponseData.eshot = event.currentTarget.innerText === this.yesText;
        break;
      case keywords.sms:
        this.getConsentResponseData.sms = event.currentTarget.innerText === this.yesText;
        break;
    }
  }

  private getPrivacyPolicy(){
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    let widgetJson=new PrivacyPolicyRequest('', environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.erpMarketingPref]);
    const getPrivacyPolicyrequestServicepostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.pagemanagement,widgetJson)
      .subscribe((res)=>{
        if(res.status==config.SUCCESS && res.data){
          let data = res.data['PageData']['Widgets']['RightTop'][0];
          this.privacyPolicyData = new PrivacyPolicyResponse(liferayBaseUrl+data.resource_url, data.linkName);
        }
      },(error)=>{
      });
      this.SubscriptionList.push(getPrivacyPolicyrequestServicepostRequestGateWaySubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
