import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../environments/root/environment';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {map} from "rxjs/operators";
import {CustomerServiceEmail, EmailConfirmation} from "../../../../jobportal/model/request/email-confirmation";
import {RequestService} from "../../../services/request/request.service";
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import {Request , Response } from 'express';
import { isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss']
})
export class ErrorPagesComponent implements OnInit, OnDestroy {

  public query:any;
  private baseUrl: string;
  public loginUrl: string;
  public emailConfirmation: string;
  public apacCountry: boolean;
  public erpCustomerCareId: string;
  public mailtoText: string = '';
  SubscriptionList:Subscription[]=[];

  constructor(private activateRoute:ActivatedRoute, private translate:TranslateService,
              public jobSearchService: JobSearchService, public _siteService:SiteService,
              private dataSharingService : DataSharingService, private requestService : RequestService, private router:Router,private titleService:SetTitleService,
              @Optional() @Inject(REQUEST) private request: Request,
              @Optional() @Inject(RESPONSE) private response: Response, @Inject(PLATFORM_ID) private platformId: any) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    if(environment.platform.indexOf('apac')>-1)
      this.apacCountry=true;
    // this.dataSharingService.getWidgetListName(keywords.errorPageWidget);
    this.getRouteUrls();
    const activateRouteQueryParamsSubscription = this.activateRoute.queryParams.subscribe((params)=>{
      if(params)
        this.query=params;
      if(params['lang']) {
        let locale=params['lang'];
        let domain=this._siteService.getDomain();
        this._siteService.setSite2(domain,locale);
        this.translate.setDefaultLang(this._siteService.getSiteLocale());
      }
      if(params['notFound']){
        this.titleService.setTitleForPage('Hays 404 Page');
        if (isPlatformServer(this.platformId)) {
          this.response.status(404);
        }
      }
      this.SubscriptionList.push(activateRouteQueryParamsSubscription);
    });

  }

  ngOnInit() {
    if(this.query.erpStatus === 'FAIL')
      this.getErpCustomerCareId();
  }

  getErpCustomerCareId(){
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getCustomerServiceEmail)
      .subscribe(response => {
        if (response.status === config.SUCCESS) {
          this.erpCustomerCareId = response.data.customerServiceEmail;
          this.mailtoText = keywords.mailto;
      };
    }, (error) => {
    });
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
  }

  getRouteUrls(){
  let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
  this.loginUrl = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
  this.emailConfirmation = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.emailConfirmationPage];
  if(orgName){
    this.loginUrl = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);
    this.emailConfirmation = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.emailConfirmationPage]).replace(':orgName',orgName);
  }
}
ngOnDestroy(): void {
  this.SubscriptionList.forEach(subscribe=>{
    if(subscribe){
      subscribe.unsubscribe();
  }
  });
}
}
