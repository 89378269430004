<form id="gtm_send_cv" [formGroup]="jobApplyForm">
  <div class="row">

    <div class="col-sm-12 col-md-6">
      <div class="" *ngIf="!loggedInUser && showApplyForm">
        <h3 [translate]="'enter_details_text'"></h3>
        <div class="form-group">
          <label [translate]="'whats_your_emailId'"></label>
          <input type="text" class="form-control" id="emailAddress_jobApply" formControlName="emailAddress" [ngModel]="emailAddress"
                 [ngClass]="{'is-invalid':f.emailAddress.errors}"
                 placeholder="{{'email_example_placeholder'|translate}}">
          <span class="error-msg" *ngIf="f.emailAddress.errors || emailMsgError"
                [translate]="'invalid_email_msg'"></span>
        </div>
        <ng-container *ngIf="isKanak">
          <app-jp-register-new-kana [parent]="jobApplyForm" [formSubmitted]="formSubmitted" [firstName]="firstName"
                                    [lastName]="lastName"
                                    [katkanafirstname]="katkanafirstname"
                                    [katkanalastname]="katkanalastname"></app-jp-register-new-kana>
        </ng-container>
        <div class="form-group row" *ngIf="!isKanak">
          <div class="col">
            <label [translate]="'enter_first_name'"></label>
            <input type="text" class="form-control" formControlName="firstname" [ngModel]="firstName" (paste)="sanitizePaste($event)"
                   placeholder="{{'enter_first_name_placeholder'|translate}}">
            <span class="error-msg" *ngIf="f.firstname.errors && errorMsgShow"
                  [translate]="'invalid_first_name_msg'"></span>
          </div>
          <div class="col">
            <label [translate]="'enter_last_name'"></label>
            <input type="text" class="form-control" formControlName="lastname" [ngModel]="lastName" (paste)="sanitizePaste($event)"
                   placeholder="{{'enter_last_name_placeholder'|translate}}">
            <span class="error-msg" *ngIf="f.lastname.errors && errorMsgShow"
                  [translate]="'invalid_last_name_msg'"></span>
          </div>
        </div>
        <div class="info-msg" [translate]="'upload_issue_info'"></div>

      </div>
      <div class="manage-cv" *ngIf="loggedInUser">
        <app-jp-get-cv (selectedFileData)="loggedInCvData($event)" [flow]="jobApplyFlow"></app-jp-get-cv>
      </div>
      <div *ngIf="showApplyForm || loggedInUser">
        <h3 [translate]="'new_CV_text_apply'"></h3>
        <app-jp-upload-cv [parent]="jobApplyForm"
                          (showValidationMessage)="showValidationMessage($event)"></app-jp-upload-cv>
        <span class="error-msg" *ngIf="uploadCvError && !existingValidateMsg" [translate]="'invalid_cv_msg'"></span>
        <span *ngIf="showPopUp" class="error-msg" [translate]="'password_protected_warning'"></span>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 apply-section">
      <div class="other-apply-option"
           *ngIf="orgCode != 'HCP005' && pageConfig && (pageConfig.applyOptions.isIndeedApply || pageConfig.applyOptions.isLinkedinApply || pageConfig.applyOptions.isSeekApply)">
        <app-jp-apply-option></app-jp-apply-option>
      </div>
      <div *ngIf="isKillerQuestion">
        <app-qualifying-questions (questionData)="killerquestionData($event)"></app-qualifying-questions>
        <span class="error-msg" *ngIf="killerQuestionErrorMsg" [translate]="'Qualifying_question_mandatory'"></span>
      </div>
      <div *ngIf="!loggedInUser">
        <app-jp-marketing-consent *ngIf="isMarketingConsentEnabled"
                                  [isChecked]="isChecked" [privacyPolicyErrorMsg]="privacyPolicyErrorMsg"
                                  [parent]="jobApplyForm" [type]="'sendCV'"
                                  (allCheckBox)="getCheckBoxData($event)"
                                  [firstPrivacyError]="firstPrivacyError">
        </app-jp-marketing-consent>
        <app-term-and-privacy *ngIf="!isMarketingConsentEnabled" (privacyconsent2)="getPrivacyConsent2Checked($event)"
                              [isChecked]="isChecked" [privacyPolicyErrorMsg]="privacyPolicyErrorMsg"
                              (allCheckBox)="getCheckBoxData($event)" (isConsentChecked) = "getIsConsentCheckedStatus($event)" [parent]="jobApplyForm" [type]="'sendCV'"
                              [currentUserFlow] = 'currentUserFlow'>
        </app-term-and-privacy>
        <span class="error-msg" *ngIf="firstPrivacyError && !isMarketingConsentEnabled" [translate]="'policy_validation_error'"></span>
        <div *ngIf="!isCaptchaDisable">
          <app-jp-captcha [parent]="jobApplyForm"></app-jp-captcha>
          <span class="error-msg" *ngIf="captchaErrorMsg" [translate]="'captcha_validation_text'"></span>
        </div>
      </div>
      <div *ngIf="legalConsentVisible && !loggedInUser">
        <app-jp-legal-consent-popup [legalConsentVisible]=legalConsentVisible (consentFlag)="legalConsentFlag($event)"
                                    [domainConfig]="domainConfig"></app-jp-legal-consent-popup>
      </div>
      <div *ngIf="showLeagalCheckbox && !showApplyForm && !loggedInUser">
        <app-jp-legal-consent-checkbox-popup (consentFlag)="legalConsentFlag($event)"
                                             [domainConfig]="domainConfig"></app-jp-legal-consent-checkbox-popup>
      </div>

      <button id="gtm_job_apply_btn" class="apply-btn"
              [ngClass]="disableApplyButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
              [disabled]="disableApplyButton ||(JobResponse && JobResponse.length>0 && JobResponse[0].isJobApplied)"
              [translate]="(JobResponse && JobResponse.length>0 && JobResponse[0].isJobApplied)?'apply_button_text_disabled':'job_apply_breadcrumb'"
              (click)="jobApplySubmit()"></button>

    </div>
  </div>
</form>
<app-jp-marketing-consent-popup
  *ngIf="(isSendCvComponent && dataShareService.$openMarketContentPopup | async) || marketConsentPopup  " (consentFlag)="legalConsentFlag($event)" [consentPopUp]="marketConsentPopup" (crossClicked)="crossClicked($event)"></app-jp-marketing-consent-popup>

<app-jp-privacy-modal *ngIf = "isPrivacyPopUpDisplayed" (popUpCloseEvent) = 'onPopUpClose($event)' [modalInnerText] = "privacyPopUpContent">

</app-jp-privacy-modal>

<app-jp-marketing-setting *ngIf = 'isMarketingConsentPopUpDisplayed' (marketingConsentCloseEvent) = 'onMarketingConsentResponse($event)'>

</app-jp-marketing-setting>
