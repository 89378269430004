import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestService } from 'src/app/global/services/request/request.service';
import { SiteService } from 'src/app/global/services/site/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePassword } from 'src/app/jobportal/model/request/change-password';
import {
  apiEndPoints,
  environment,
  keywords,
  routingEndpoint,
  config
} from '../../../../../../environments/root/environment';
import { TokenStorage } from 'src/app/global/services/tokenStorage/token-storage';
import { DataSharingService } from 'src/app/jobportal/service/data-sharing.service';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../service/set-title.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-jp-change-password',
  templateUrl: './jp-change-password.component.html',
  styleUrls: ['./jp-change-password.component.scss']
})
export class JpChangePasswordComponent implements OnInit, OnDestroy {
  public changePassForm: FormGroup;
  public emailAdd: string;
  public identifier: string;
  public isCaptchaDisable = false;
  public formSubmitted: boolean;
  public validationFlag = {
    required: false,
    pattern: false,
    passwordMatch: false,
    oldNewDiffer: false
  }
  public incorrectOldPass = false;
  captchaValue: string;
  captchaValidate: boolean;
  captchaVisible = false;
  public buttonChnagePasswordClicked: boolean;
  SubscriptionList:Subscription[]=[];
  constructor(private _requestService:RequestService,private siteService: SiteService, private router: Router,private activatedRoute:ActivatedRoute,
    private tokenStorage:TokenStorage, private dataSharing:DataSharingService,private translate:TranslateService,private titleService:SetTitleService) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    const translateGetTranslationSubscription= this.translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['change_password_page_title']);
    });
    this.dataSharing.getWidgetListName(keywords.changePasswordWidget);
    this.SubscriptionList.push(translateGetTranslationSubscription);
  }


  ngOnInit() {
    this.createFormGroup();
  }

  protected createFormGroup(): void {
    this.changePassForm = new FormGroup({
      currentPassword: new FormControl('', {
        validators: [Validators.required, Validators.pattern(config.passwordPattern)]
      }),
      newPassword: new FormControl('', {
        validators: [Validators.required, Validators.pattern(config.passwordPattern)]
      }
      ),
      confirmPassword: new FormControl('', {
        validators: [Validators.required]
      }
      ),
      reCaptchaReactive: new FormControl('',
      {updateOn: 'change'}
    )
    });
    if (localStorage.getItem('disableCaptcha') === 'true'){
      this.isCaptchaDisable = true;
    }
  }

  submit(){
    this.buttonChnagePasswordClicked=true;
    this.formSubmitted = true;
    this.captchaValue='';
    this.captchaValidate=false;

    if(!this.isCaptchaDisable && this.captchaVisible){
      this.captchaValue=this.changePassForm.value.reCaptchaReactive;
      this.captchaValidate=true;
      this.buttonChnagePasswordClicked=false;
    }
    this.validationFlag.oldNewDiffer = this.changePassForm['controls'].currentPassword.value === this.changePassForm['controls'].newPassword.value;

    this.validationFlag.passwordMatch = this.changePassForm['controls'].newPassword.value !== this.changePassForm['controls'].confirmPassword.value;

    this.validationFlag.required = (this.changePassForm.invalid && ((this.changePassForm['controls'].newPassword.errors && this.changePassForm['controls'].newPassword.errors.required) || (this.changePassForm['controls'].currentPassword.errors && this.changePassForm['controls'].currentPassword.errors.required) || (this.changePassForm['controls'].confirmPassword.errors && this.changePassForm['controls'].confirmPassword.errors.required))) || this.captchaValue == null;

    this.validationFlag.pattern = this.changePassForm.invalid && ((this.changePassForm['controls'].newPassword.errors && this.changePassForm['controls'].newPassword.errors.pattern) || (this.changePassForm['controls'].currentPassword.errors && this.changePassForm['controls'].currentPassword.errors.pattern));

    if (!this.validationFlag.passwordMatch && !this.validationFlag.required && !this.validationFlag.pattern && !this.validationFlag.oldNewDiffer) {
      this.changePassword();
    }else{
      this.buttonChnagePasswordClicked=false;
    }
  }

  changePassword(){
    let payload = new ChangePassword(btoa(this.changePassForm['controls'].currentPassword.value), btoa(this.changePassForm['controls'].newPassword.value), btoa(this.changePassForm['controls'].confirmPassword.value), this.captchaValue, this.captchaValidate);
    const requestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.changePassword,payload ).subscribe(response => {
    if (response.status === config.SUCCESS && response.data['statuscode'] === config.portalSuccessCode){
      this.incorrectOldPass = false;
      this.tokenStorage.loggedInSignOut(keywords.changePassword,'Y',this.dataSharing,this._requestService,'loggedin',0);
    }
    if(response.status === config.SUCCESS && response.data['statuscode'] === config.passwordIncorrect && response.data['Counter'] !== 3 && response.data['Counter'] !== 5){
      this.incorrectOldPass = true;
      this.buttonChnagePasswordClicked=false;
      this.captchaVisible = false;
      this.changePassForm.reset();
    }if(response.status === config.SUCCESS && response.data['statuscode'] === config.passwordIncorrect && response.data['Counter'] === 3){
      this.incorrectOldPass = false;
      //this.isCaptchaDisable = false;
      this.captchaVisible = true;
      this.changePassForm.reset();
      this.buttonChnagePasswordClicked=false;
    }
    if(response.status === config.SUCCESS && response.data['statuscode'] === config.passwordIncorrect && response.data['Counter'] >= 5){
      this.incorrectOldPass = false;
      this.tokenStorage.loggedInSignOut(keywords.changePassword,'N',this.dataSharing,this._requestService,'loggedin',0);
    }
}, (error) => {
      let errorRoute = '';
      let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
      if(orgName)
        errorRoute = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName',orgName);
      else
        errorRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
      this.router.navigate([errorRoute],{queryParams:{changePassword:'N',error: error.status}});
      this.buttonChnagePasswordClicked=false;
});
  this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
