import {ChangeDetectorRef, Component, OnDestroy, OnInit,} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from 'src/app/global/services/site/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CustomerServiceAdapt,
  CustomerServiceData,
  CustomerServiceEmail,
  EmailConfirmation
} from 'src/app/jobportal/model/request/email-confirmation';
import {RequestService} from 'src/app/global/services/request/request.service';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {DataSharingService} from "../../../../../service/data-sharing.service";

@Component({
  selector: 'app-jp-email-confirmation',
  templateUrl: './jp-email-confirmation.component.html',
  styleUrls: ['./jp-email-confirmation.component.scss'],
  providers: [CustomerServiceAdapt]
})
export class JpEmailConfirmationComponent implements OnInit , OnDestroy{

  public mailConfirmationForm: FormGroup;
  public isCaptchaDisable = true;
  public siteLocale: any;
  public formSubmitted = false;
  public signInUrl: any;
  public isGDPRTracking: CustomerServiceData;
  public patternValidation = false;
  public disableBtn = false;
  public errorRoute : string;
  public successPageRoute: string;
  SubscriptionList:Subscription[]=[];
  constructor(private _cdr: ChangeDetectorRef, private _translate: TranslateService, public _site: SiteService,
    private activatedRoute: ActivatedRoute, private _requestService: RequestService, private _router: Router, private customerServiceAdapt: CustomerServiceAdapt,private dataShareService: DataSharingService) {
    const activatedRouteQryParamsLangSubscription = this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['lang']) {
        let locale=params['lang'];
        let domain=this._site.getDomain();
        this._site.setSite2(domain,locale);
      }
    });
    this._translate.setDefaultLang(this._site.getSiteLocale());
    this.SubscriptionList.push(activatedRouteQryParamsLangSubscription);
  }

  ngOnInit() {
    this.checkCaptchaDisabled();
    this.createFormGroup();
    this.getRouteUrls();
  }
  private checkCaptchaDisabled() {
    const checkCaptchaDisabledRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isCaptchaDisabled,null,null).pipe().
    subscribe(response => {
      localStorage.setItem("disableCaptcha",response.data);
      this.dataShareService.setIsCaptchaDIsabled(response.data.toString());
    });
   }

  protected createFormGroup(): void {
    this.mailConfirmationForm = new FormGroup({
      emailaddress: new FormControl('', {
        validators: [Validators.required, Validators.pattern(config.emailPattern)],
        updateOn: 'change'
      }
      ),
      reCaptchaReactive: new FormControl('',
        { updateOn: 'change' }
      )
    });
    if(localStorage.getItem("disableCaptcha")) {
      if(localStorage.getItem("disableCaptcha") == "true") {
        this.isCaptchaDisable = true;
      } else {
        this.isCaptchaDisable = false;
        this.mailConfirmationForm['controls'].reCaptchaReactive.setValidators([Validators.required]);
      }
    }
    this.dataShareService.sharedIsCaptchaDisabled.subscribe(res => {
      if (res == "true") {
        this.isCaptchaDisable = true;
      } else if(res == "false") {
        this.isCaptchaDisable = false;
        this.mailConfirmationForm['controls'].reCaptchaReactive.setValidators([Validators.required]);
        this.mailConfirmationForm.get('reCaptchaReactive').updateValueAndValidity();
      }
    })
  }

  confirm() {
    this.formSubmitted = true;
    this.patternValidation = this.mailConfirmationForm.invalid && (this.mailConfirmationForm['controls'].emailaddress.errors && (this.mailConfirmationForm['controls'].emailaddress.errors.pattern || this.mailConfirmationForm['controls'].emailaddress.errors.required)) ? true : false;
    if (this.mailConfirmationForm.valid) {
      this.disableBtn = true;
       const confirmRequestServiceGetRequestSubscription= this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getCustomerServiceEmail).pipe(
          map((response: CustomerServiceEmail) => this.customerServiceAdapt.adapt(response))
        ).subscribe(response => {
          if (response.status === config.SUCCESS) {
            if(response.data.erpMailConfirm){
                this.erpMailConfirm(response.data.customerServiceEmail);
            }else{
              this.isGDPRTracking = response.data;
              let payload = new EmailConfirmation(
                this.mailConfirmationForm.value.emailaddress,
                this.mailConfirmationForm.value.reCaptchaReactive,
                this.isGDPRTracking.isGDPRTrackingEnabled);
              this.submit(payload)
            }
          };
        }, (error) => {
          this.disableBtn=false;
        });
        this.SubscriptionList.push(confirmRequestServiceGetRequestSubscription);
    }
  }

  submit(payload) {
   const submitRequestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.submitEmail, payload).subscribe(response => {
      if ((response.status === config.SUCCESS) && (response.data.statuscode===config.gdprEmailNotExist || response.data.statuscode===config.portalSuccessCode)) {
        //this.ngxUiLoader.stop();
        window.location.href=window.location.origin+this.successPageRoute+'?emailConfirmation=Y'+'&userEmailId='+this.mailConfirmationForm['controls'].emailaddress.value+'&customerService='+this.isGDPRTracking.customerServiceEmail ;
      }
      else {
        this.disableBtn=false;
        this._router.navigate([this.errorRoute], { queryParams: { emailConfirmation: 'N', error: response.data.statusmsg} });
      }
    }, (error) => {
      this.disableBtn=false;
      this._router.navigate([this.errorRoute], { queryParams: { emailConfirmation: 'N', error: error.status } });
    });
    this.SubscriptionList.push(submitRequestServicePostRequestGateWaySubscription);

  }

  redirect(){
    this._router.navigate([this.signInUrl]);
  }

  erpMailConfirm(customerServiceEmail){

    let requestData = { 'candidateEmail': this.mailConfirmationForm.value.emailaddress,
      'appUrl': environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureErpMarketingPref]};
    const erpMailConfirmrequestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.erpValidateEmail, requestData)
      .subscribe( response => {
        if(response.status === config.SUCCESS) {
          window.location.href=window.location.origin+this.successPageRoute+'?emailConfirmation=Y'+'&userEmailId='+this.mailConfirmationForm['controls'].emailaddress.value+'&customerService='+customerServiceEmail ;
        } else {
          this.disableBtn=false;
          this._router.navigate([this.errorRoute], { queryParams: {erpStatus:keywords.fail}});
        }
      },error => {
        this.disableBtn=false;
        this._router.navigate([this.errorRoute],{queryParams:{erpStatus:keywords.fail}});
      });
      this.SubscriptionList.push(erpMailConfirmrequestServicePostRequestGateWaySubscription);
  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      if(base[routingEndpoint.successUpdateEmailPrefences]){
      this.successPageRoute = (base[routingEndpoint.successUpdateEmailPrefences]).replace(':orgName',orgName);
      }else{
        this.successPageRoute = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
      }
      this.signInUrl = (base[routingEndpoint.login]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      if(base[routingEndpoint.successUpdateEmailPrefences]){
        this.successPageRoute = (base[routingEndpoint.successUpdateEmailPrefences])
        }else{
          this.successPageRoute = (base[routingEndpoint.successPage]);
        }
      this.signInUrl = base[routingEndpoint.login];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
