
<ul class="breadcrumb-cont" *ngIf="(currentUrl.indexOf(jobDetailsUrl)>-1 || currentUrl.indexOf(jobDetailsUrl)>-1) && currentUrl.indexOf(jobApplyUrl) ===-1">
  <li><a href="javascript:void(0)" id="gtm_detailpage_breadcrum_redirecttosearch" (click)="redirectToSearch()" [translate]="'search_result_breadcrumb'"></a></li>
  <li [translate]="'job_desc_breadcrumb'"></li>
</ul>


<ul class="breadcrumb-cont" *ngIf="currentUrl.indexOf(jobApplyUrl)>-1">
  <li><a href="javascript:void(0)" id="gtm_apply_breadcrum_redirecttosearch" (click)="redirectToSearch()" [translate]="'search_result_breadcrumb'"></a></li>
  <li><a href="javascript:void(0)" id="gtm_apply_breadcrum_redirecttodetails" (click)="redirectToDetails()" [translate]="'job_desc_breadcrumb'"></a></li>
  <li [translate]="'job_apply_breadcrumb'"></li>
</ul>
