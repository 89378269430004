import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {CvData, JobApplyRequest} from '../../../../../model/request/job-apply-request';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SetTitleService} from '../../../../../service/set-title.service';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {MarketingConsent} from '../../../../../model/request/marketing-consent';
import {Subscription} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-send-cv',
  templateUrl: './send-cv.component.html',
  styleUrls: ['./send-cv.component.scss']
})
export class SendCvComponent implements OnInit, OnDestroy, AfterViewInit {


  @Input() public parent: FormGroup;
  @Input() public showApplyForm: boolean;
  @Input() public showSendCV: string;
  @Input() public JobResponse: GoogleJobResponse[];
  public formSubmitted = false;
  public loggedInUser: boolean;
  public jobApplyForm: FormGroup;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public isChecked: boolean;
  public disableApplyButton: boolean;
  public errorMsgShow = false;
  public emailMsgError = false;
  public captchaErrorMsg = false;
  public isCaptchaDisable = true;
  public uploadCvError = false;
  private selectedCvData: any;
  public isKanak = false;
  public jobApplyFlow = 'jobApplyFlow';
  public showPopUp = false;
  private isCVSelected: boolean;
  public privacyPolicyValidate = false;
  public privacyPolicyErrorMsg = false;
  public firstPrivacyError = false;
  public extraPrivacyData = false;
  public legalConsentVisible = false;
  public existingValidateMsg = false;
  public pageConfig: ExtraFieldsConfig;
  public killerQuestionErrorMsg = false;
  private killerQuestionJson = [];
  public isKillerQuestion = false;
  public domainConfig: ExtraFieldsConfig;
  public showLeagalCheckbox: boolean;
  public katkanalastname: string;
  public katkanafirstname: string;
  public isMarketingConsentEnabled: boolean;
  private submitClicked: Boolean;
  public marketingData: MarketingConsent;
  private updatedMarketingData;
  private marketingWhatsApp: string;
  private marketingSms: string;
  private marketingEmail: string;
  private shredCVRef: Subscription = new Subscription();
  private sharedConfigRef: Subscription = new Subscription();
  private marketRef: Subscription = new Subscription();
  private openMarketPopupRef: Subscription = new Subscription();
  private crossclick: boolean;
  public isSendCvComponent: boolean;
  public isMarketingConsentForHU: boolean;
  public getPrivacyConsent2: boolean;
  public marketConsentPopup: boolean;
  public isPrivacyConsentEnabled: boolean;
  orgCode: string;
  public applyId : string;
  public recordId: string;
  /**
   * Captures whether privacy pop up is displayed or not for CHN
   */
  public isPrivacyPopUpDisplayed : boolean = false;
  /**
   * Captures privacy pop up inner text depending upon the route
   */
  public privacyPopUpContent : string;
  /**
  * Determines whether marketing consent pop up is displayed for TH
  */
  public isMarketingConsentPopUpDisplayed: boolean;
  /**
  * Captures user response for the marketing setting for TH domain
  */
  public marketingSettingResponse: string | undefined = undefined;
  /**
   * Captures the current user flow
   */
  @Input() public currentUserFlow: string = '';
  SubscriptionList:Subscription[]=[];

  constructor(private tokenStorage: TokenStorage, private activatedRoute: ActivatedRoute,
              private cookieService: CookieService, public dataShareService: DataSharingService, private siteService: SiteService,
              private translateService: TranslateService, private requestService: RequestService, private deviceDetector: DeviceDetectorService,
              private router: Router, private titleService: SetTitleService,
              private searchService: JobSearchService,
              private changeDetectorRef: ChangeDetectorRef , @Inject(PLATFORM_ID) private platformId: object) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
    this.isKanaEnabled();
    this.isKillerQuestionEnable();
  }

  ngDoCheck() {
    if(localStorage.getItem("disableCaptcha")) {
      if(localStorage.getItem("disableCaptcha") == "true") {
        this.isCaptchaDisable = true;
      } else {
        this.isCaptchaDisable = false;
      }
    }
    this.dataShareService.sharedIsCaptchaDisabled.subscribe(res => {
      if (res == "true") {
        this.isCaptchaDisable = true;
      } else if(res == "false") {
        this.isCaptchaDisable = false;
      }
    }) 
  }

  ngOnInit() {
    this.checkCaptchaDisabled();
    if (localStorage.getItem('profileData') && localStorage.getItem('profileData') !== 'undefined') {
      this.showLeagalCheckbox = true;
    }
    const searchServiceSharedJobDetailDataSubscription= this.searchService.sharedJobDetailData.subscribe(jobDetailData => {
      this.JobResponse = jobDetailData;
      if (this.JobResponse && this.JobResponse[0].orgCode)
      this.orgCode = this.JobResponse[0].orgCode;
    });
    this.shredCVRef = this.dataShareService.sharedCVSelectedInfo.subscribe(cvSelected => {
      this.isCVSelected = cvSelected;
    });
    this.sharedConfigRef = this.searchService.sharedConfigList.subscribe(config => {
      this.pageConfig = config;
      if (config && config.isMarketingConsentEnabled) {
        this.isMarketingConsentEnabled = config.isMarketingConsentEnabled;
      }
      if (config && config.isMarketingConsentForHU) {
        this.isMarketingConsentForHU = config.isMarketingConsentForHU;
      }
      if (config && config.isPrivacyConsentEnabled) {
        this.isPrivacyConsentEnabled = config.isPrivacyConsentEnabled;
      }
    });
    if (this.tokenStorage.getLoggedInToken()) {
      this.loggedInUser = true;
      this.firstName = localStorage.getItem("firstName");
      this.lastName = localStorage.getItem("lastName");
      this.emailAddress = localStorage.getItem("emailAdd");
    }
    if(isPlatformBrowser(this.platformId)) {
    this.activatedRoute.queryParamMap.subscribe(param => {  
      if ((!param.get("path") && !param.get('drive'))) {
        this.cookieService.delete('jobApplicantDetails', '/');
      }
    });
  }
    const activatedRouteParamMapSubscription= this.activatedRoute.paramMap.subscribe(param=>{
      this.recordId = encodeURIComponent(param.get('recordId'));
    });
    if (!this.loggedInUser) {
      this.jobApplyForm = new FormGroup({
        emailAddress: new FormControl('', [Validators.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)]),
        firstname: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        cvData: new FormControl('', Validators.required),
        privacyconsent1: new FormControl(''),
        privacyconsent2: new FormControl(''),
        privacyconsent3: new FormControl(''),
        privacyconsentAll: new FormControl(''),
        reCaptchaReactive: new FormControl('')
      });
    } else {
      this.jobApplyForm = new FormGroup({
        cvData: new FormControl(''),
      });
    }
    this.dataShareService.setSignUpForm(this.jobApplyForm);

    let applicantCookie: any;
    if (this.cookieService.get('jobApplicantDetails')) {
      applicantCookie = decodeURIComponent(this.cookieService.get('jobApplicantDetails'));
      applicantCookie = JSON.parse(applicantCookie);
      this.firstName = applicantCookie.firstName ? applicantCookie.firstName : '';
      this.lastName = applicantCookie.lastName ? applicantCookie.lastName : '';
      this.katkanalastname = applicantCookie.katkanalastname ? applicantCookie.katkanalastname : "";
      this.katkanafirstname = applicantCookie.katkanafirstname ? applicantCookie.katkanafirstname : "";
      this.emailAddress = applicantCookie.email ? applicantCookie.email : '';
      this.isChecked = applicantCookie.marketingConsents;
    }
    this.marketRef = this.dataShareService.$isMarketingConsent.subscribe(data=>this.marketingData = data)
    this.SubscriptionList.push(searchServiceSharedJobDetailDataSubscription);
    this.SubscriptionList.push(activatedRouteParamMapSubscription);
  }

  private checkCaptchaDisabled() {
    const checkCaptchaDisabledRequestServiceGetRequestSubscription = this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isCaptchaDisabled,null,null).pipe().
    subscribe(response => {
      localStorage.setItem("disableCaptcha",response.data);
      this.dataShareService.setIsCaptchaDIsabled(response.data.toString());
    });
  }

  ngAfterViewInit(): void {
      this.changeDetectorRef.detectChanges();
  }

  get f() {
    if (this.jobApplyForm.value.emailAddress) {
      this.emailMsgError = false;
    }
    if (this.jobApplyForm.value.firstname && this.jobApplyForm.value.lastname) {
      this.errorMsgShow = false;
    }
    if (this.jobApplyForm.value.cvData) {
      this.uploadCvError = false;
    }
    if (this.jobApplyForm.value.reCaptchaReactive && !this.loggedInUser) {
      this.captchaErrorMsg = false;
    }
    if (this.jobApplyForm.value.privacyconsent1 && this.privacyPolicyValidate && !this.loggedInUser) {
      this.privacyPolicyErrorMsg = false;
    }
    if ((this.jobApplyForm.value.privacyconsent2 && this.privacyPolicyValidate && !this.loggedInUser && !this.marketConsentPopup) || (this.jobApplyForm.value.privacyconsent1 && !this.privacyPolicyValidate && !this.loggedInUser)) {
      this.firstPrivacyError = false;
    }
    return this.jobApplyForm.controls;
  }

  loggedInCvData(data) {
    this.selectedCvData = data;
    if (this.uploadCvError || this.selectedCvData) {
      this.dataShareService.sendClearCvData(false);
      this.jobApplyForm.controls.cvData.patchValue(null);
      this.uploadCvError = false;
      this.existingValidateMsg = false;
    }
  }

  isKanaEnabled() {
   const isKanaEnabledRequestServiceGetRequestSubscription= this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getIsKanaEnabled)
      .subscribe(response => {
        if (response.status === config.SUCCESS) {
          this.isKanak = true;
          this.jobApplyForm.addControl('katkanalastname', new FormControl('', { updateOn: 'change' }));
          this.jobApplyForm.addControl('katkanafirstname', new FormControl('', { updateOn: 'change' }));
        }
      }, (error) => {
      });
      this.SubscriptionList.push(isKanaEnabledRequestServiceGetRequestSubscription);
  }

  getCheckBoxData(data) {
    if (data['checkBox1'].length > 0 && data['checkBox3'].length == 0 && data['checkBox2'].length > 0 && !this.loggedInUser) {
      this.privacyPolicyValidate = true;
    }
    if (data['checkBox1'].length > 0 && data['checkBox3'].length > 0 && data['checkBox2'].length > 0 && !this.loggedInUser) {
      this.extraPrivacyData = true;
    }
    if (data['checkBox1'].length > 0 && data['checkBox2'].length > 0 && !this.loggedInUser) {
      this.extraPrivacyData = true;
    }
  }

  showValidationMessage(event) {
    if (event) {
      this.uploadCvError = false;
      this.showPopUp = false;
      this.existingValidateMsg = false;
    } else {
      this.existingValidateMsg = true;
    }
  }

  isKillerQuestionEnable() {
    const isKillerQuestionEnableSearchServiceSharedConfigListSubscription= this.searchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isKillerQuestionsEnabled) {
        this.isKillerQuestion = true;
        this.domainConfig = domainConfig;
      }
    });
    this.SubscriptionList.push(isKillerQuestionEnableSearchServiceSharedConfigListSubscription);
  }


  jobApplySubmit() {
    this.submitClicked = true;
    if (this.isKillerQuestion) {
      if (this.killerQuestionJson.length > 0) {
        this.killerQuestionJson.forEach((item) => {
          if (item.answer == '' || item == '') {
            this.killerQuestionErrorMsg = true;
          }
        });
      }
    }
    this.privacyPolicyErrorMsg = false;
    this.firstPrivacyError = false;
    if (!this.jobApplyForm.value.emailAddress && !this.loggedInUser) {
      this.emailMsgError = true;
    }
    if (!this.isCaptchaDisable && !this.jobApplyForm.value.reCaptchaReactive && !this.loggedInUser) {
      this.captchaErrorMsg = true;
    }
    if (!this.selectedCvData) {
      if (!this.jobApplyForm.value.cvData && !this.existingValidateMsg) {
        this.uploadCvError = true;
      }
    }
    if(this.isKanak){
      this.formSubmitted = true;
    }
    if (this.privacyPolicyValidate && !this.jobApplyForm.value.privacyconsent1 && !this.loggedInUser) {
      this.privacyPolicyErrorMsg = true;
      this.firstPrivacyError = true;
    }
    if ((this.jobApplyForm.value.privacyconsent2 == '' && this.privacyPolicyValidate && !this.loggedInUser &&
      !this.isMarketingConsentForHU && !this.isPrivacyConsentEnabled) || (!this.jobApplyForm.value.privacyconsent1 && !this.privacyPolicyValidate && !this.loggedInUser)) {
      this.firstPrivacyError = true;
    }
    this.errorMsgShow = this.jobApplyForm.invalid;

    if (this.checkValidationStatus() && (!this.uploadCvError || this.selectedCvData) && !this.existingValidateMsg && !this.captchaErrorMsg && !this.emailMsgError && !this.firstPrivacyError && !this.privacyPolicyErrorMsg && !this.killerQuestionErrorMsg) {
      if (this.isMarketingConsentEnabled && !this.marketingData.email && !this.marketingData.sms && !this.marketingData.whatsApp && !this.loggedInUser) {
        this.showMarketingPopup();
      }
      if(this.isMarketingConsentEnabled && !this.loggedInUser){
        this.openMarketPopupRef = this.dataShareService.$openMarketContentPopup.subscribe(data => {
          if(this.crossclick)
            return;
          if (!data && this.submitClicked) {
            this.disableApplyButton = true;
            this.legalConsentPopup();
            this.submitClicked = false;
          }
        });
      }else if(!this.getPrivacyConsent2 && this.isMarketingConsentForHU && !this.loggedInUser) {
        this.disableApplyButton = true;
        this.submitClicked = false;
        this.marketConsentPopup = true;
      }
      else if(this.getPrivacyConsent2 && this.isMarketingConsentForHU) {
        this.disableApplyButton = true;
        this.legalConsentFlag(true);
        this.submitClicked = false;
      }
      else{
        this.disableApplyButton = true;
        this.legalConsentPopup();
        this.submitClicked = false;
      }
    }
  };
  /**
   * Checks job apply form validity
   * @returns
   */
  private checkValidationStatus(){
    if((localStorage.getItem('domain') === 'CHN' || localStorage.getItem('domain') === 'TH') && !this.isChecked && !this.loggedInUser){
      this.isPrivacyPopUpDisplayed = true;
      this.getPrivacyPopUpContents();
      return false;
    }
    else if(localStorage.getItem('domain') === 'TH' && !this.loggedInUser && !this.marketingSettingResponse && this.isChecked){
      this.isMarketingConsentPopUpDisplayed = true;
      return false;
    }
    else{
      return this.jobApplyForm.valid;
    }
  }

  legalConsentPopup() {
    let showLeagalPopup = false;
    if (localStorage.getItem('profileData') && localStorage.getItem('profileData') !== 'undefined') {
      showLeagalPopup = true;
    }
    const legalConsentPopupSearchServiceSharedConfigListSubscription= this.searchService.sharedConfigList.subscribe(domainConfig => {
      if (((domainConfig && domainConfig.isLegalConsentPopupEnabled && !domainConfig.isMultiPrivacyPolicyEnabled) || (domainConfig && domainConfig.isLegalConsentPopupEnabled && !domainConfig.isMultiPrivacyPolicyEnabled&& showLeagalPopup)) && !this.loggedInUser) {
        this.legalConsentVisible = true;
      } else if ((domainConfig && !domainConfig.isLegalConsentPopupEnabled && domainConfig.isMultiPrivacyPolicyEnabled) || (domainConfig && !domainConfig.isLegalConsentPopupEnabled && domainConfig.isMultiPrivacyPolicyEnabled&&showLeagalPopup)){
        this.showLeagalCheckbox=true;
      }else {
        this.submitCall('');
      }
    });
    this.SubscriptionList.push(legalConsentPopupSearchServiceSharedConfigListSubscription);
  }

  public legalConsentFlag(temp) {
    let flag = temp === true ? 'Y' : 'N';
    this.jobApplyForm.value.privacyconsent2=temp;
    this.submitCall(flag);
    this.marketConsentPopup=false;
  }
  getPrivacyConsent2Checked($event: boolean) {
    this.getPrivacyConsent2=$event;
    if(this.isPrivacyConsentEnabled)
      this.jobApplyForm.value.privacyconsent2 = $event;
  }

  killerquestionData(event) {
    if (event.length > 0) {
      this.killerQuestionJson = event;
      this.killerQuestionErrorMsg = false;
    }
  }

  submitCall(flag) {
    if (this.tokenStorage.getLoggedInToken()) {
      this.loggedInUser = true;
      this.firstName = localStorage.getItem("firstName");
      this.lastName = localStorage.getItem("lastName");
      this.emailAddress = localStorage.getItem("emailAdd");
    }
    let token = '';
    const submitCallActivatedRouteQueryParamMapSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      token = param.get('accessTokenDB') ? param.get('accessTokenDB') : param.get('accessTokenGD');
    });
    if (this.isChecked) {
      this.jobApplyForm.value.privacyconsent1 = this.isChecked;
    }
    let cvData: CvData;
    if ((!this.isCVSelected && this.loggedInUser) || !this.loggedInUser) {
      cvData = this.jobApplyForm.value.cvData;
    }
    let keyWord = '';
    let jobSource = '';
    let location = '';
    let specialismId = '';
    let subSpecialismId = '';
    let applyId = '';
    let jobName = '';
    const marketingConsentSetting = localStorage.getItem('domain') === 'TH' ? this.marketingSettingResponse : '';
    const activatedRouteQueryParamSubscription= this.activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('q')) {
        keyWord = param.get('q');
      }
      let defaultJobSource='';
      const dataShareServiceSharedClientConfigSubscription = this.dataShareService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      if (param.get('location')) {
        location = param.get('location');
      }
      if (param.get('specialismId')) {
        specialismId = param.get('specialismId');
      }
      if (param.get('subSpecialismId')) {
        subSpecialismId = param.get('subSpecialismId');
      }
      if (param.get('applyId')) {
        applyId = param.get('applyId');
      }else if (param.get('id')){
        applyId=param.get('id');
      } else if (param.get('applyid')){
        applyId=param.get('applyid');
      }
      if (param.get('jobName')) {
        jobName = param.get('jobName');
      }
      this.applyId = applyId;
      this.SubscriptionList.push(dataShareServiceSharedClientConfigSubscription);
    });
    
    let candJobSource='';
    if(this.JobResponse && this.JobResponse.length>0 && this.JobResponse[0].jobSource){
      candJobSource=this.JobResponse[0].jobSource;
    }
    if(this.isMarketingConsentEnabled && !this.loggedInUser)
      this.setmarketingData();
    let jobApplyJson = new JobApplyRequest(
      this.deviceDetector.isDesktop()?jobSource+'-Desktop':this.deviceDetector.isMobile()?jobSource+'-Mobile':jobSource+'-Tablet',
      this.loggedInUser ? this.firstName : this.jobApplyForm.value.firstname,
      this.loggedInUser ? this.lastName : this.jobApplyForm.value.lastname,
      this.loggedInUser ? this.emailAddress : this.jobApplyForm.value.emailAddress,
      this.loggedInUser?'Y': this.jobApplyForm.value.privacyconsent1 ? 'Y' : 'N',
      applyId,
      this.isKanak ? this.jobApplyForm.value.katkanafirstname : '',
      this.isKanak ? this.jobApplyForm.value.katkanalastname : '',
      cvData,
      (this.isCaptchaDisable && !this.loggedInUser) ? '' : this.jobApplyForm.value.reCaptchaReactive,
      (this.loggedInUser && this.selectedCvData) ? this.selectedCvData[1] : '',
      (this.loggedInUser && this.selectedCvData) ? this.selectedCvData[0] : '',
      this.extraPrivacyData ? (this.jobApplyForm.value.privacyconsent2 ? 'Y' : 'N') : '',
      this.extraPrivacyData ? (this.jobApplyForm.value.privacyconsent3 ? 'Y' : 'N') : '',
      this.legalConsentVisible || this.isMarketingConsentForHU ? flag :this.isMarketingConsentEnabled ? this.marketingEmail: '',
      this.legalConsentVisible ? flag : marketingConsentSetting ? marketingConsentSetting : '',
      this.legalConsentVisible || this.isMarketingConsentForHU ? flag :this.isMarketingConsentEnabled ? this.marketingSms: '',
      this.killerQuestionJson,
      token,candJobSource,
      this.isMarketingConsentEnabled ? this.marketingWhatsApp: '',
      this.extraPrivacyData ? (this.jobApplyForm.value.privacyconsent2 ? 'Y' : 'N') : '',
      this.orgCode = this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].orgCode : ''
    );
    let thankYouUrl = '';
    let jobTitle = this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '';
    jobTitle=encodeURIComponent(jobTitle);
    let locationDesc= this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : '';
    let jobRef= this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '';
    const submitCallRequestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobApply, jobApplyJson).subscribe((res) => {
      if (res.status === config.SUCCESS && res.data && res.data.statuscode === config.portalSuccessCode) {
        this.cookieService.set('applyJobid', applyId, 7, '/');

        if (this.tokenStorage.getLoggedInToken()) {
          thankYouUrl = this.getThankYouUrl(routingEndpoint.secureThankYou);
          //thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureThankYou];
        } else {
          thankYouUrl = this.getThankYouUrl(routingEndpoint.thankyou);
          //thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.thankyou];
        }

        // this.orgCode == "" ? window.location.href=window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+'&locationDesc='+locationDesc
        // :  window.location.href=window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+'&locationDesc='+locationDesc+'&orgName='+'careers';
        switch(this.orgCode) {
          case "":
            window.location.href=window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+'&locationDesc='+locationDesc
            break;
          case config.HaysCareerJob:
            window.location.href=window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+'&locationDesc='+locationDesc+'&orgName='+'careers';
            break;
          case config.HaysHTSJob:
            window.location.href=window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+'&locationDesc='+locationDesc+'&orgName='+'hts';
            break;
        }
        /*this.router.navigate([thankYouUrl], {
          queryParams: {
            q: keyWord, jobName: jobName,
            applyId: applyId,
            jobSource: jobSource,
            jobTitle: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].JobTitle : '',
            jobRef: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '',
            locationDesc: this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].locationDesc : ''
          }
        });*/

      } else if ((res.status === config.SUCCESS && res.data && res.data.statuscode === config.portalFailureCode)) {
        if (this.tokenStorage.getLoggedInToken()) {
          thankYouUrl = this.getThankYouUrl(routingEndpoint.secureThankYou);
          //thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureThankYou];
        } else {
          thankYouUrl = this.getThankYouUrl(routingEndpoint.thankyou);
          //thankYouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.thankyou];
        }
        let jobRef= this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '';
        window.location.href = window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+
          '&jobApply=N&locationDesc='+locationDesc;
      } else {
        this.disableApplyButton = false;
      }
      this.SubscriptionList.push(submitCallRequestServicePostRequestGateWaySubscription);
    }, error => {
      this.disableApplyButton = false;
      if (error.error.data === config.protectedFileUpload) {
        this.showPopUp = true;
      } else if (error.error.data === config.infectedFileUpload)
        this.disableApplyButton = true;
       else {
        let jobRef= this.JobResponse && this.JobResponse.length > 0 ? this.JobResponse[0].jobRef : '';
        window.location.href = window.location.origin+thankYouUrl+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+'&jobTitle='+jobTitle+'&jobRef='+jobRef+
          '&jobApply=N&locationDesc='+locationDesc;
      }
    });
    this.SubscriptionList.push(submitCallActivatedRouteQueryParamMapSubscription);
  }

  getThankYouUrl(endpointUrl){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
    let thankyouUrl ='';
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl];
      thankyouUrl = url.replace(':orgName',orgName);
    }
    else
      thankyouUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
      thankyouUrl = thankyouUrl.replace(':recordId',this.recordId);
      return thankyouUrl;
  }

  showMarketingPopup() {
    this.isSendCvComponent = true;
    this.dataShareService.setOpenMarketContentPopup(true);
  }

  setmarketingData(){
    let data = this.marketingData;
    if(data.email)
      this.marketingEmail = 'Y';
    else if(!data.email)
      this.marketingEmail = 'N';
    if(data.sms)
      this.marketingSms = 'Y';
    else if(!data.sms)
      this.marketingSms = 'N';
    if(data.whatsApp)
      this.marketingWhatsApp = 'Y';
    else if(!data.whatsApp)
      this.marketingWhatsApp = 'N';
  }

  crossClicked(event){
    this.crossclick = event;
    this.marketConsentPopup=false;
  }
  /**
   * Captures privacyconsent1 status
   * @param eventPayload
   */
  public getIsConsentCheckedStatus(eventPayload : boolean){
    this.isChecked = eventPayload;
  }
  /**
   *Sets the privacy pop up contents
   */
  private getPrivacyPopUpContents(){
    const translateServiceGetTranslationSubscription= this.translateService.getTranslation(this.siteService.getSiteLocale()).subscribe(response => {
      this.privacyPopUpContent = response['privacy-consent-anonymous-apply-contents'];
    });
    this.SubscriptionList.push(translateServiceGetTranslationSubscription);
  }
  /**
   * Listens to privacy pop up close event
   * @param eventPayload
   */
  public onPopUpClose(eventPayload : boolean){
    this.isPrivacyPopUpDisplayed = eventPayload;
  }
  /**
   * Called in response to marketing setting pop up close event
   * Captures the response
   * Removes the pop up from the view
   * @param eventPayload 
   */
  public onMarketingConsentResponse(eventPayload: string){
    this.marketingSettingResponse = eventPayload;
    this.isMarketingConsentPopUpDisplayed = false;
  }

  ngOnDestroy(): void {
    this.openMarketPopupRef.unsubscribe();
    this.marketRef.unsubscribe();
    this.sharedConfigRef.unsubscribe();
    this.shredCVRef.unsubscribe();
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){
          subscribe.unsubscribe();
      }
      });   
  }
  sanitizePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');
    // Remove emojis from the pasted content
    const sanitizedText = pastedText.replace(/[\p{Emoji_Presentation}]/gu, '');
    // Insert sanitized text into the input field
    document.execCommand('insertText', false, sanitizedText);
  }
}
