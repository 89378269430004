import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {SignIn} from '../../../../../model/request/sign-in';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {LoginCounter, SignInResponse} from '../../../../../model/response/sign-in-response';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {HeaderRequestJson} from '../../../../../../global/models/header/header-menu';
import {SiginPopupService} from '../../service/sigin-popup.service';
import {merge, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-jp-signin',
  templateUrl: './jp-signin.component.html',
  styleUrls: ['./jp-signin.component.scss']
})
export class JpSigninComponent implements OnInit, OnDestroy {
  public signInFormGroup: FormGroup;
  private singInRequest: SignIn;
  public signInResponse: SignInResponse;
  public loginCounterResponse: LoginCounter;
  public isAuthFail: boolean;
  public formNotValid: boolean;
  public forgetPasswordLink: string;
  public disableBtn = false;
  public captchaShow = false;
  private passChangedSubRef: Subscription = new Subscription();
  public isChangePassV2enabled: boolean
  public identifier: string;
  private popupSubRef: Subscription = new Subscription();
  private combineObs: Observable<boolean>;
  public isAzureEnabled: boolean;
  SubscriptionList:Subscription[]=[];
  constructor(private requestService: RequestService, private tokenStorage: TokenStorage, public router: Router, private dataSharing: DataSharingService,
              private siteService: SiteService, private translate: TranslateService, private cookieService: CookieService, private activatedRoute: ActivatedRoute,
              private siginPopupService: SiginPopupService) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());

    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      this.forgetPasswordLink =(environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.forgetPassword]).replace(':orgName',orgName);
    }
    else
      this.forgetPasswordLink =environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.forgetPassword];
  }

  ngOnInit() {
    this.isAzureEnabled =environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    const activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param => {
      /*if (param.get('logout') || this.router.url.indexOf('logout') > -1) {
        let azureLoginURL =environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL']?environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL']:null;
        if(this.isAzureEnabled){
          this.tokenStorage.clearCacheAndCookie();
          window.location.href=azureLoginURL;
        }else
           this.tokenStorage.loggedInSignOut('', '', this.dataSharing, this.requestService, 'loggedin',0);
      }*/
      if (param.get('access') === 'denied') {
        this.signInFormGroup.reset();
      }
      if (param.get('ChangePassword') === 'N' || param.get('ChangeEmail') === 'N') {
        this.isAuthFail = true;
      }
    });
    this.signInFormGroup = new FormGroup({
      emailAddress: new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }
      ),
      password: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      reCaptchaReactive: new FormControl('',
        {updateOn: 'change'}
      )
    });
     this.popupSubRef = this.siginPopupService.$openPopup.subscribe(value=>{
      this.isChangePassV2enabled = value;
    })
    this.SubscriptionList.push(activatedRouteQueryParamMapSubscription);
  }

  public submitSignIn() {
    this.isAuthFail = false;
    this.formNotValid = false;
    let loginUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName)
      loginUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);
    this.validations();
    if (this.signInFormGroup.valid && !this.formNotValid) {
      this.disableBtn = true;
      let username = this.signInFormGroup.controls.emailAddress.value;
      let password = btoa(this.signInFormGroup.controls.password.value);
      this.singInRequest = new SignIn(password, true, null, username.trim(),
        (this.loginCounterResponse && this.loginCounterResponse.Counter === 3) ? this.loginCounterResponse.Counter : null,
        (this.loginCounterResponse && this.loginCounterResponse.Counter === 3) ? this.signInFormGroup.controls.reCaptchaReactive.value : '','false');
      const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.createToken, this.singInRequest).subscribe(res => {
        if (res.status === config.SUCCESS) {
          if (res.data.statuscode) {
            this.isAuthFail = true;
            this.loginCounterResponse = res.data;
            this.captchaShow = this.loginCounterResponse['Counter'] == 3;
            this.disableBtn = false;
            this.signInFormGroup.reset();
          } else {
            this.signInResponse = res.data;
            if (this.signInResponse.identityMigration === 'N') {
              this.identifier = this.signInResponse.identifier;
              this.siginPopupService.setOpenPopup(true);
              this.combineObs = merge(this.siginPopupService.$changeSuccess, this.siginPopupService.$openPopup)
              this.passChangedSubRef = this.combineObs.subscribe(value => {
                if (!value && !this.isChangePassV2enabled) {
                  this.onChangePassV2(false);
                } else if(value && !this.isChangePassV2enabled){
                  this.onChangePassV2(true);
                }
              });
            } else {
              this.tokenStorage.saveToken(this.signInResponse.token);
              this.tokenStorage.saveSessionId(this.signInResponse.userSession);
              this.tokenStorage.saveLoggedInUserDetails(this.signInResponse.firstName, this.signInResponse.lastName, this.signInResponse.emailAddress,
                this.signInResponse.whatsappNumber ? 'Y' : 'N');

              this.setCookie(this.signInResponse.portalProfileId);
            }
          }
          this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
        }
        },
        error => {
          this.disableBtn = false;
          /* if(error.error.status==='HAYS_401'){*/
          this.isAuthFail = true;
          this.signInFormGroup.reset();
          if (this.router.url.indexOf('login') > -1) {
            this.router.navigate([loginUrl],
              {queryParams: {access: 'denied'}});
          }
        })
    } else {
      this.isAuthFail = true;
      this.formNotValid = true;
      this.disableBtn = false;
    }


  }

  onChangePassV2(success: boolean) {
    this.disableBtn = false;
    this.signInFormGroup.reset();
    let param = success ? {'ChangePasswordV2': 'Y'} : {'ChangePasswordV2': 'N'};
    if (this.router.url.indexOf('login') > -1) {
      this.router.navigate([environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login]],
        {queryParams: param});
    }
  }

  private setCookie(portalProfileId: string) {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let liferaybaseUrl;
    if(orgName=='nhs')
       liferaybaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    else if(orgName=='cognizant')
      liferaybaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayCognizantUrl];
    else if(orgName == 'yourrailindustryjob' || orgName === 'geniuswithin')
      liferaybaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    else
      liferaybaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    let cookieDomain = location.hostname;
    let len = cookieDomain.length;
    let n = cookieDomain.indexOf('.');
    if (cookieDomain.indexOf('.') > -1) {
      cookieDomain = cookieDomain.substring(n, len);
    }
    if (this.cookieService.get('usrname')) {
      this.cookieService.delete('usrname', '/');
    }
    let cookieDomainName = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.set('usrname', this.signInResponse.firstName + ' ' + this.signInResponse.lastName, 0, '/', cookieDomainName);
    if (this.cookieService.get('PortalId')) {
      this.cookieService.delete('PortalId', '/');
    }
    this.cookieService.set('PortalId',portalProfileId,0,'/',cookieDomainName);

    if(this.router.url.indexOf('apply')>-1){
      let keyWord='';
      let jobName='';
      let applyId='';
      let jobSource='';
      let specialismId='';
      let subSpecialismId='';
      let defaultJobSource='';
     const dataSharingSharedClientConfigSubscription= this.dataSharing.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      const activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param=>{
        if(param.get('q'))
          keyWord=param.get('q');
        if(param.get('jobName'))
          jobName=param.get('jobName');
        if(param.get('applyId'))
          applyId=param.get('applyId');
        else if (param.get('id'))
          applyId=param.get('id');
        jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        if(param.get('specialismId'))
          specialismId=param.get('specialismId');
        if(param.get('subSpecialismId'))
          subSpecialismId=param.get('subSpecialismId');

        let applyUrl='';
        if(orgName){
          let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.secureJobApply];
          applyUrl = url.replace(':orgName',orgName);
        }
        else
          applyUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureJobApply];

        if(applyUrl.indexOf(':recordId')>-1){
          let applyRedirectUrl = window.location.origin+applyUrl.replace(':recordId',applyId)+'?q='+keyWord+'&jobName='+jobName+'&applyId='+applyId+'&jobSource='+jobSource+
            '&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId+'&signed=Y';
          window.location.href=applyRedirectUrl;
        }else {
          this.router.navigate([applyUrl], {
            queryParams: {
              q: keyWord, jobName: jobName, applyId: applyId, jobSource: jobSource,
              specialismId: specialismId, subSpecialismId: subSpecialismId, signed: 'Y'
            }
          });
        }
      });
      let headerJson = new HeaderRequestJson(
        '',
        environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
      );
      let endPoint = apiEndPoints.pagemanagement;
      const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, headerJson)
        .subscribe((res) => {
          if (res.status == config.SUCCESS && res.data) {
            this.dataSharing.clearHeader();
            this.dataSharing.sendHeaderData(res);
          }
        });
        this.SubscriptionList.push(dataSharingSharedClientConfigSubscription);
        this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
        this.SubscriptionList.push(activatedRouteQueryParamMapSubscription);
    }
    else {
      if(orgName)
        liferaybaseUrl = liferaybaseUrl+'/'+ orgName;
      window.location.href = liferaybaseUrl;
    }
  }

  validations() {
    if (!this.signInFormGroup.value.emailAddress || !this.signInFormGroup.value.password) {
      this.isAuthFail = true;
      return false;
    }

    if (this.captchaShow && !this.signInFormGroup.value.reCaptchaReactive) {
      this.formNotValid = true;
      return false;
    }
  }

  closePopup(event: boolean){
    this.isChangePassV2enabled = event
  }

  ngOnDestroy(): void {
      this.passChangedSubRef.unsubscribe();
      this.popupSubRef.unsubscribe();
        this.SubscriptionList.forEach(subscribe=>{
          if(subscribe){
            subscribe.unsubscribe();
        }
        });
      }   
}
