import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {map} from "rxjs/operators";
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from "../../../../../../environments/root/environment";

import {TranslateService} from "@ngx-translate/core";
import {SiteService} from "../../../../../global/services/site/site.service";
import {RequestService} from "../../../../../global/services/request/request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerServiceAdapt, CustomerServiceEmail} from "../../../../model/request/email-confirmation";
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-jp-unsubscribe-email-confirmation',
  templateUrl: './jp-unsubscribe-email-confirmation.component.html',
  styleUrls: ['./jp-unsubscribe-email-confirmation.component.scss'],
  providers:[CustomerServiceAdapt]
})
export class JpUnsubscribeEmailConfirmationComponent implements OnInit, OnDestroy {
  public emailConfirmationForm: FormGroup;
  public isCaptchaDisable = false;
  public siteLocale: any;
  public formSubmitted = false;
  public signInUrl: any;
  public patternValidation = false;
  public disableBtn = false;
  public errorRoute : string;
  public email : string;
  public successPageRoute: string;
  SubscriptionList:Subscription[]=[];

  constructor(private _cdr: ChangeDetectorRef, private _translate: TranslateService, public _site: SiteService, private customerServiceAdapt: CustomerServiceAdapt,
              private activatedRoute: ActivatedRoute, private _requestService: RequestService, private _router: Router) {
    const activatedRouteQueryParamsLangSubscription = this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['lang']) {
        let locale=params['lang'];
        let domain=this._site.getDomain();
        this._site.setSite2(domain,locale);
      }
    });
    this._translate.setDefaultLang(this._site.getSiteLocale());
    this.SubscriptionList.push(activatedRouteQueryParamsLangSubscription);
  }

  ngOnInit() {
    this.createFormGroup();
    this.getRouteUrls();
  }


  protected createFormGroup(): void {
    this.emailConfirmationForm = new FormGroup({
      emailaddress: new FormControl('', {
          validators: [Validators.required, Validators.pattern(config.emailPattern)],
          updateOn: 'change'
        }
      ),
      reCaptchaReactive: new FormControl('',
        { updateOn: 'change' }
      )
    });
   // if (localStorage.getItem('disableCaptcha') === 'true')
      this.isCaptchaDisable = true;
    if (!this.isCaptchaDisable && this.emailConfirmationForm) {
      this.emailConfirmationForm['controls'].reCaptchaReactive.setValidators([Validators.required]);
    }
  }

  submit() {
    this.formSubmitted = true;
    this.patternValidation = this.emailConfirmationForm.invalid && (this.emailConfirmationForm['controls'].emailaddress.errors && (this.emailConfirmationForm['controls'].emailaddress.errors.pattern || this.emailConfirmationForm['controls'].emailaddress.errors.required)) ? true : false;
    if (this.emailConfirmationForm.valid) {
      this.disableBtn = true;
      const submitRequestServiceGetRequestSubscription = this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getCustomerServiceEmail).pipe(
        map((response: CustomerServiceEmail) => this.customerServiceAdapt.adapt(response))
      ).subscribe(response => {
        if (response.status === config.SUCCESS) {
          this.mailConfirm(response.data.customerServiceEmail, this.emailConfirmationForm['controls'].emailaddress.value);
        }
      }, (error) => {
        this.disableBtn = false;
      });
      this.SubscriptionList.push(submitRequestServiceGetRequestSubscription);
    }
  }

  redirect(){
    this._router.navigate([this.signInUrl]);
  }

  mailConfirm(customerServiceEmail,email){
    let requestData = { 'candidateEmail': email, 'appUrl':""};
    const RmailConfirmequestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.sendUnsubscribeEmail, requestData)
      .subscribe( response => {
        if(response.status === config.SUCCESS) {
          window.location.href=window.location.origin+this.successPageRoute+'?emailSent=Y'+'&userEmailId='+email;
        } else {
          this.disableBtn=false;
          this._router.navigate([this.errorRoute], { queryParams: {emailSent:keywords.fail}});
        }
      },error => {
        this.disableBtn=false;
        this._router.navigate([this.errorRoute],{queryParams:{emailSent:keywords.fail}});
      });
      this.SubscriptionList.push(RmailConfirmequestServicePostRequestGateWaySubscription);
  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      this.successPageRoute = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
      this.signInUrl = (base[routingEndpoint.login]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      this.successPageRoute = base[routingEndpoint.successPage];
      this.signInUrl = base[routingEndpoint.login];
      this.errorRoute = base[routingEndpoint.errorPage];
      if(base['unsubscribeEmailConfirmedPage']){
        this.successPageRoute = base['unsubscribeEmailConfirmedPage'];
      }
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
