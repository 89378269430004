import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { config } from 'src/environments/root/environment';

@Component({
  selector: 'app-jp-application-process-details',
  templateUrl: './jp-application-process-details.component.html',
  styleUrls: ['./jp-application-process-details.component.scss']
})
export class JpApplicationProcessDetailsComponent implements OnInit, OnDestroy {
  isOrgName: boolean;
  orgName: string;
  haysCareers = config.ExpertClientsName;
  activatedRouteQueryParamMapSubscription:Subscription;
  constructor(private activatedRoute:ActivatedRoute, private router : Router) { }

  ngOnInit() {
    // this.isOrgName = (this.router.url.indexOf(config.HaysCareer) == 1)  ? true : false;
    this.activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('orgName'))
        this.orgName= param.get('orgName');
    });
  }
  ngOnDestroy(): void {
    if(this.activatedRouteQueryParamMapSubscription){
    this.activatedRouteQueryParamMapSubscription.unsubscribe();}
  }
}
