import {CvData} from './cv-data';

export class Register {
  constructor(public cvData:CvData,public cvname: string,public docId: string,public emailaddress:string, public privacyconsent1: string,
              public privacyConsent2, public privacyconsent3,
              public eshotconsent:string,public expertise:string,public firstname:string,public captchaResponse:string,
              public katkanafirstname:string,public katkanalastname:string,public lastname:string,public marketingconsent:string,
              public nearestoffice:string,public nearestofficeemail:string,public smsconsent:string,public token:string,
              public  trainingUser:string, public  trainingPartner:string, public  trainingId :string, public whatsupconsents: string, public expressConsent: string, public mobilenumber: string = null, public requestType: string = null, public languageProficiency = null,
              public candidateOnetouchID: string = null ){}
}

