import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from 'src/app/global/services/site/site.service';
import {config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../../service/set-title.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {DOCUMENT} from '@angular/common';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {RequestService} from '../../../../../../global/services/request/request.service';
import { Subscription } from 'rxjs';
import { AzureGaService } from 'src/app/global/services/azure-ga/azure-ga.service';

@Component({
  selector: 'app-jp-login',
  templateUrl: './jp-login.component.html',
  styleUrls: ['./jp-login.component.scss']
})
export class JpLoginComponent implements OnInit, OnDestroy {
  public query: any;
  public forgetFlag = false;
  public resetFlag = false;
  public changePassSuccFlag = false;
  public changePassFailFlag = false;
  public changeEmail: boolean;
  public showRegister: boolean;
  public showLogin: boolean;
  public changePassSuccFlagV2: boolean;
  public isAzureEnabled: boolean;
  /**
   * Captures the azure login URL
   */
  private azureLoginURL: string = '';
  SubscriptionList:Subscription[]=[];

  constructor(public activateRoute: ActivatedRoute, private dataSharingService: DataSharingService,
              public router: Router, private siteService: SiteService, private translate: TranslateService,
              private titleService: SetTitleService, @Inject(DOCUMENT) private document: Document, private tokenStorage: TokenStorage, private requestService: RequestService, 
              private gaService: AzureGaService) {
    const translateGetTranslationSubscription = this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      this.titleService.setTitleForPage(res['logIn'] + ' - ' + res['page_title']);
    });
    this.isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    this.dataSharingService.getWidgetListName(keywords.loginPageWidget);
    this.SubscriptionList.push(translateGetTranslationSubscription);

  }

  ngOnInit() {
    const activateRouteQueryParamsSubscription = this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if (!this.isAzureEnabled && this.query.logout === 'success') {
          this.tokenStorage.loggedInSignOut('', '', this.dataSharingService, this.requestService, 'loggedin', 0);
        }
        if (this.router.url.indexOf('logout') > -1 || (this.router.url.indexOf('azureLogout') > -1 && this.query.e !== 'failure')) {
          if (this.isAzureEnabled) {
            this.tokenStorage.clearCacheAndCookie();
            this.getAzureLoginURL(true);
          } else {
            this.tokenStorage.loggedInSignOut('', '', this.dataSharingService, this.requestService, 'loggedin', 0);
          }
        }
        if ((this.query.e === 'failure' || this.query.ChangeEmail === 'Y' || this.query.ChangeEmail === 'N' || this.query.logout === 'success' || this.query.q === 'unauthorised') && this.isAzureEnabled) {
          this.tokenStorage.clearCacheAndCookie();
          this.getAzureLoginURL(false, false, true);
        }
        if (this.query.status === 'forgotSuccess') {
          this.forgetFlag = true;
        }
        if (this.router.url.indexOf('lang') > -1 || this.router.url.indexOf('?_ga') > -1) {
          if (this.isAzureEnabled) {
            this.tokenStorage.clearCacheAndCookie();
            const gaworkflow=this.activateRoute.snapshot.queryParamMap.get('_ga');
            const lang=this.activateRoute.snapshot.queryParamMap.get('lang');
            if(gaworkflow !=null ||  gaworkflow !=undefined)
            {
              this.getAzureLoginURL(false, true, false, lang, gaworkflow);
             }
            else
            {
              this.getAzureLoginURL(true);
            }
          }
        }
        if (this.query.status === 'resetPasswordSuccess') {
          this.resetFlag = true;
        }
        if (this.query.ChangePassword === 'Y') {
          this.changePassSuccFlag = true;
        }
        if (this.query.ChangeEmail === 'Y') {
          this.changeEmail = true;
        }
        if (this.query.ChangePasswordV2 === 'Y') {
          this.changePassSuccFlagV2 = true;
        }
        if (Object.keys(this.query).length === 0 && this.isAzureEnabled) {
          this.getAzureLoginURL(true);
        }

      }
    });
    this.SubscriptionList.push(activateRouteQueryParamsSubscription);
  }

  scrollToBottom(e: HTMLElement, type: string): void {
    try {
      type === 'register' ? this.showRegister = true : this.showLogin = true;
      setTimeout(() => {
        e.scrollIntoView();
      }, 200);

    } catch (err) {
    }
  }


  cancel() {
    this.forgetFlag = false;
    this.resetFlag = false;
    this.changePassSuccFlag = false;
    this.changePassFailFlag = false;
    this.changeEmail = false;
    this.changePassSuccFlagV2 = false;
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this.router.navigate([baseRoute[routingEndpoint.login]]);
  }
  /**
   * Method to be called to get the azure login URL and call the executeAzureFlows
   * @param redirectToAzure 
   * @param redirectToGAWorkflow 
   * @param azureLogout 
   * @param lang 
   * @param gaWorkflow 
   */
  private getAzureLoginURL(redirectToAzure?: boolean, redirectToGAWorkflow?: boolean, azureLogout?: boolean, lang?: string, gaWorkflow?: string){
    if(this.gaService.azureLoginURL){
      this.azureLoginURL = this.gaService.azureLoginURL;
      this.executeAzureFlows(redirectToAzure, redirectToGAWorkflow, azureLogout, lang, gaWorkflow);
    }
    else{
      this.gaService.azureURLObservable.subscribe((response) => {
        if(response){
          this.azureLoginURL = this.gaService.azureLoginURL;
          this.executeAzureFlows(redirectToAzure, redirectToGAWorkflow, azureLogout, lang, gaWorkflow);
        }
      })
    }
    if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
      this.gaService.generateAzureURL();
    }
  }
  /**
   * Method to be called to redirect to Azure in different flows
   * @param redirectToAzure 
   * @param redirectToGAWorkflow 
   * @param azureLogout 
   * @param lang 
   * @param gaworkflow 
   */
  private executeAzureFlows(redirectToAzure?: boolean, redirectToGAWorkflow?: boolean, azureLogout?: boolean, lang?: string, gaworkflow?: string){
    if(redirectToAzure){
      window.location.href = this.azureLoginURL;
    }
    else if(redirectToGAWorkflow){
      window.location.href = this.azureLoginURL +'?lang='+lang+'&_ga='+gaworkflow;
    }
    else if(azureLogout){
      let searchParams = new URLSearchParams(this.azureLoginURL);
      searchParams.set("action", "logout");
      this.azureLoginURL = decodeURIComponent(searchParams.toString());
      window.location.href = this.azureLoginURL;
    }
  }

  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
