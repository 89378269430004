<div [ngClass]="showSingleAlert?'logged-in-user job-alert-container  whatsapp-active':isLoggedin?'logged-in-user job-alert-container':'job-alert-container'" *ngIf="keyWord && extraConfig && !isEmailAlertSaved && isEmailAlert"></div>


<section *ngIf="displayPopUp" class="market_popup">
  <div  class="row cdk-overlay-container" id="blurID">
    <div  class="col-12 col-md-4 border mb-5 py-4">
      <div class="d-flex align-items-center justify-content-between">
        <h1>{{'get_job_alert_text' | translate}}</h1>
        <span> <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closeModal()">+</a></span>
      </div>
      <h2>{{keyWordName}}<span *ngIf="locationTitleName!=''">- {{locationTitleName}}</span> </h2>
      <div class="d-flex mb-4 flex-wrap">
        <span *ngIf="jobSearchFilter?.minSal" class="tag mb-3">{{jobSearchFilter?.minSal}}-{{jobSearchFilter?.maxSal}}</span>
        <span *ngIf="jobSearchFilter?.jobTypeSelected" class="tag mb-3">{{jobSearchFilter?.jobTypeSelected}}</span>
        <span *ngIf="industryFilterName!='' && industryFilterName!=null" class="tag mb-3">{{industryFilterName}}</span>
        <span *ngIf="locationFilterName!='' && locationFilterName!=null" class="tag mb-3">{{locationFilterName}}</span>

      </div>
       <ul  class="ng-star-inserted" style="" *ngIf="extraConfig.isWhatsappAlert && (!emailWhatsappAlertSaved && !maximumAlertExceeds) && isNetherlandCountry">
        <li  class="email ng-star-inserted form-group" [ngClass]="disableEmailButton?'disabled email':'email'" *ngIf="!isLoggedin">
          <input  type="text"  class="form-control ng-pristine ng-valid ng-touched" [ngClass]="emailErrorFlag?'form-control error-input':'form-control'" [(ngModel)]="email" [disabled]="disableEmailButton"
                 (keyup)="validateInput(email,'','','')" placeholder="{{'gdpr_email_placeholder_text' | translate}}">
        </li>
        <li  class="form-group whatsapp ng-star-inserted" *ngIf="!showSingleAlert || !hideWhatsapp" [ngClass]="disableWhatsapp?'disabled whatsapp':'whatsapp'">
          <div class="d-flex">
            <input  type="text"  class="country-code form-control ng-untouched ng-pristine ng-valid w-80" [ngClass]="waCodeError?'country-code form-control error-input':'country-code form-control'" [disabled]="disableWhatsapp"  [(ngModel)]="whatsappCountryCode"
                   [placeholder]="extraConfig.wspCountryCode" (keyup)="validateInput('',whatsappCountryCode,'','')" maxlength="5">
            <input  type="tel"  class="form-control phone-no ng-untouched ng-pristine ng-valid" [ngClass]="whatsappNumberErrorFlag?'form-control phone-no error-input':'form-control phone-no'" [disabled]="disableWhatsapp" placeholder="{{'enter_mobile_number_text'|translate}}" [(ngModel)]="whatsappNumber"
                   (keydown)="validateInput('','',whatsappNumber,$event)" maxlength="11">
          </div>
        </li>
      </ul>

      <ul  class="ng-star-inserted" style="" *ngIf="!isNetherlandCountry">
        <li  class="email ng-star-inserted form-group" [ngClass]="disableEmailButton?'disabled email email-large':'email email-large'">
          <input  type="text"  class="form-control ng-pristine ng-valid ng-touched" [ngClass]="emailErrorFlag?'form-control error-input':'form-control'" [(ngModel)]="email" [disabled]="disableEmailButton" *ngIf="!isLoggedIn"
                 (keyup)="validateEmail(email)" placeholder="{{'gdpr_email_placeholder_text' | translate}}">
        </li>

      </ul>

      <div class="d-flex"> <i class="mr-3"><img src="../assets/images/info.png" /></i>
        <p>{{job_alert_max_number_allowed_text1}}
          <a  [href]="create_account_url">{{job_alert_create_an_account}}</a>.
          {{job_alert_max_number_allowed_text2}}
      </div>
      <button *ngIf="isNetherlandCountry"  class="btn btn-default ng-star-inserted" style="" id="gtm_email_alert_loggedIn_save" [ngClass]="'btn btn-default'" (click)="saveNewJobAlert()" [disabled]="disableEmailButton" [disabled]="disableWhatsapp"
              [translate]="'create_job_alert_text'"></button>
      <button *ngIf="!isNetherlandCountry"  id="gtm_email_alert_loggedIn_save" class="btn btn-default ng-star-inserted" style="" id="gtm_email_alert_save" class="btn btn-default" (click)="saveJobAlertOtherCountry()"
              [disabled]="disableEmailButton" [translate]="'create_job_alert_text'"></button>
      <!----><!----><div >
      {{job_alert_privacy_policy_text}}
      <a  [href]="privacy_policy_url">{{job_alert_privacy_policy_text1}}</a>.
    </div>
    </div>
  </div>
</section>

