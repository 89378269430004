import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalConsentRequest} from '../../../jobportal/model/request/GlobalConsentRequest';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../environments/root/environment';
import {RequestService} from '../../services/request/request.service';
import {SiteService} from '../../services/site/site.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-consent',
  templateUrl: './global-consent.component.html',
  styleUrls: ['./global-consent.component.scss']
})
export class GlobalConsentComponent implements OnInit, OnDestroy {

  private successPageRoute:string;
  private errorRoute:string;
  SubscriptionList:Subscription[]=[];
  constructor(private requestService: RequestService, private activateRoute: ActivatedRoute, private _router: Router, private sitService: SiteService) {
  }

  ngOnInit() {
    const activateRouteQueryParamsSubscription= this.activateRoute.queryParams.subscribe((params) => {
      this.callGlobalConsent(params['OTId'], params['type']);
    });
    this.SubscriptionList.push(activateRouteQueryParamsSubscription);
  }

  callGlobalConsent(otid: string, type: string) {
    this.getRouteUrls();
    let globalConsentJson = new GlobalConsentRequest(
      otid,
      type
    );
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.submitGlobalConsent, globalConsentJson)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data === 'SUCCESS') {
          this._router.navigate([this.successPageRoute], {queryParams: {globalConsent: 'Y'}});
        } else {
          this._router.navigate([this.errorRoute], {queryParams: {globalConsent: 'N'}});
        }
     (error) => {
      this._router.navigate([this.errorRoute], {queryParams: {globalConsent: 'N'}});
    }
  });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.sitService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.successPageRoute = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.sitService.getSiteLocale()][routingEndpoint.routing_url];
      this.successPageRoute = base[routingEndpoint.successPage];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
