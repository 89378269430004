import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {OfficeLocatorResult, OfficeLocatorResultAdapt, OfficeLocatorResultData} from '../../../../model/response/office-locator-result';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {SiteService} from '../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {ApacLocationJsonRequest, LatlongJsonRequest} from '../../../../model/response/office-locator';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-office-locator-searchresult',
  templateUrl: './jp-office-locator-result.component.html',
  styleUrls: ['./jp-office-locator-result.component.scss'],
  providers:[OfficeLocatorResultAdapt]
})
export class JpOfficeLocatorResultComponent implements OnInit, OnDestroy {
  public officeLocatorListData : OfficeLocatorResultData[]=[];
  public viewGoogleLink:string="";
  public routerLink:string="";
  public country:string="";
  public sector:string="";
  public officeNearText:string="";
  public yourNearestOfficeText:string="";
  public mailtoText:string="";
  public telText:string="";
  public distUnit:string="";
  public radius:number;
  public locationid:number;
  public locName:string="";
  public latitude:string="";
  public longitude:string="";
  public specialismnid:string="";
  public subspecialismID:string="";
  public showLoader:boolean;
  public searchAgainClicked: boolean;
  SubscriptionList:Subscription[]=[];
  constructor(private dataSharingService: DataSharingService, private _translate:TranslateService, public siteService:SiteService,
              private router:Router, private activatedRoute:ActivatedRoute, private officeLocatorResultAdapt:OfficeLocatorResultAdapt,
              private requestService:RequestService, private titleService:SetTitleService)
  {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    const translateGetViewGoogleLinkSubscription = this._translate.get('view_google_link').subscribe(val => this.viewGoogleLink = val);
    const translateGetTranslationSubscription = this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['office_locator_page_title']);
    });
    this.routerLink=this.activatedRoute.snapshot['_routerState'].url;
    this.mailtoText=keywords.mailto;
    this.telText=keywords.tel;
    this.dataSharingService.getWidgetListName(keywords.findUsWidget);
    this.SubscriptionList.push(translateGetViewGoogleLinkSubscription);
    this.SubscriptionList.push(translateGetTranslationSubscription);
  }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    this.locName= this.activatedRoute.snapshot.queryParamMap.get('loc');
    this.country= this.activatedRoute.snapshot.queryParamMap.get('geoLoc');
    this.sector=this.activatedRoute.snapshot.queryParamMap.get('spec');
    this.distUnit = this.activatedRoute.snapshot.queryParamMap.get('distUnit');
    this.radius = parseInt(this.activatedRoute.snapshot.queryParamMap.get('radius'));
    this.locationid = parseInt(this.activatedRoute.snapshot.queryParamMap.get('locId'));
    this.latitude = this.activatedRoute.snapshot.queryParamMap.get('lat');
    this.longitude = this.activatedRoute.snapshot.queryParamMap.get('long');
    this.specialismnid = this.activatedRoute.snapshot.queryParamMap.get('specId');
    this.subspecialismID = this.activatedRoute.snapshot.queryParamMap.get('subSpec');

    if (this.locationid) {
      let json = new ApacLocationJsonRequest(this.distUnit, this.radius, this.locationid, this.specialismnid, this.subspecialismID);
      //API calling for apac location on the basis of location ID
      this.apiCalling(json, apiEndPoints.gateWayAPI, apiEndPoints.getOfficeResultForAPAC);
    } else {
      let json = new LatlongJsonRequest(this.distUnit, this.radius, this.latitude, this.longitude, this.specialismnid, this.subspecialismID);
      //API calling onthe basis of lat long
      this.apiCalling(json, apiEndPoints.gateWayAPI, apiEndPoints.getLatLongOfficeResult);
    }
  }
  searchAgain(){
    this.searchAgainClicked=true;
    let routeUrl= '';
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.officeLocatorPage];
      routeUrl = url.replace(':orgName',orgName);
    }
    else
      routeUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorPage];
    this.router.navigateByUrl(routeUrl);
  }

  apiCalling(json,baseendpoint,endpoint){
    this.showLoader=true;
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme,baseendpoint,endpoint,json).pipe(
      map((res: OfficeLocatorResult) => this.officeLocatorResultAdapt.adapt(res)))
      .subscribe((res) => {
        this.showLoader=false;
        if (res.status === config.SUCCESS && res.data) {
          this.officeLocatorListData = res.data;
          const translateGetOfficesNearTextSubscription = this._translate.get('offices_near_text').subscribe(val => this.officeNearText = val);
          const translateGetYourNearestOfficesSubscription = this._translate.get('your_nearest_offices').subscribe(val => this.yourNearestOfficeText = val);
          this.SubscriptionList.push(translateGetOfficesNearTextSubscription);
          this.SubscriptionList.push(translateGetYourNearestOfficesSubscription);
        }else{
          this.showLoader=false;
        }
      }, (error) => {
        this.showLoader=false;
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
