import { Component, OnDestroy, OnInit } from '@angular/core';
import {GlobalConsentRequest} from "../../../jobportal/model/request/GlobalConsentRequest";
import {apiEndPoints, config, environment, routingEndpoint} from "../../../../environments/root/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {RequestService} from "../../services/request/request.service";
import {ExpressConsentRequest} from "../../../jobportal/model/request/ExpressConsentRequest";
import {SiteService} from "../../services/site/site.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-update-consent',
  templateUrl: './jp-update-consent.component.html',
  styleUrls: ['./jp-update-consent.component.scss']
})
export class JpUpdateConsentComponent implements OnInit, OnDestroy {
  private successPageRoute:string;
  private errorRoute:string;
  SubscriptionList:Subscription[]=[];
  constructor(private activateRoute: ActivatedRoute,private requestService: RequestService,private _router: Router,
              private sitService: SiteService) { }

  ngOnInit() {
   const activateRouteQueryParamsSubscription = this.activateRoute.queryParams.subscribe((params) => {

      this.callConsent(params['identifier'],params['reference'], params['type']);
    });
    this.SubscriptionList.push(activateRouteQueryParamsSubscription);

  }

  callConsent(otId: string,reference: string, consentType: string) {
    this.getRouteUrls();
    let consentJson = new ExpressConsentRequest(atob(reference),atob(otId), consentType);
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.updateConsent, consentJson)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data === 'SUCCESS') {
          this._router.navigate([this.successPageRoute], {queryParams: {updateConsent: 'Y'}});
        } else {
          this._router.navigate([this.errorRoute], {queryParams: {updateConsent: 'N'}});
        }
      }, (error) => {
      this._router.navigate([this.errorRoute], {queryParams: {updateConsent: 'N'}});
    });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);

  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.sitService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.successPageRoute = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.sitService.getSiteLocale()][routingEndpoint.routing_url];
      this.successPageRoute = base[routingEndpoint.successPage];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
