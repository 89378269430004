import { Component, OnDestroy, OnInit } from '@angular/core';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {RightWidget, RightWidgetAdapter, WidgetData, WidgetJson} from '../../../../../model/response/right-widget';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterState, RouterStateSnapshot} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-right-widget',
  templateUrl: './right-widget.component.html',
  styleUrls: ['./right-widget.component.scss'],
  providers:[RightWidgetAdapter]
})
export class RightWidgetComponent implements OnInit, OnDestroy {

  public widgetData:RightWidget[];
  requestServicePostRequestGateWaySubscription:Subscription;
  constructor(private requestService:RequestService,private siteservice:SiteService,private widgetAdapter:RightWidgetAdapter,
              private activateRoute:ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.getWidgets();
  }

  private getWidgets(){
    //add redirection for orgName
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]

    let widgetJson=new WidgetJson(
      '',
      environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.recruitingNow]
    );
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.pagemanagement,widgetJson).pipe(
      map((res:WidgetData)=>this.widgetAdapter.adapt(res)))
      .subscribe((res)=>{
        if(res.status==config.SUCCESS && res.data) {
          this.widgetData = res.data['PageData']['Widgets']['RightTop'];
          this.widgetData.forEach((item) => {
            if(orgName)
              item.resource_url = orgName + item.resource_url;
          });
         }
        },(error)=>{
      });

  }
  ngOnDestroy(): void {
    if(this.requestServicePostRequestGateWaySubscription){
     this.requestServicePostRequestGateWaySubscription.unsubscribe();}
  }
}
