import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SiteService } from 'src/app/global/services/site/site.service';
import { RequestService } from 'src/app/global/services/request/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CvData } from 'src/app/jobportal/model/request/cv-data';
import { Register } from 'src/app/jobportal/model/request/register';
import { DataSharingService } from 'src/app/jobportal/service/data-sharing.service';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import { environment, config, routingEndpoint, apiEndPoints } from 'src/environments/root/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-update-privacy-acceptance',
  templateUrl: './jp-update-privacy-acceptance.component.html',
  styleUrls: ['./jp-update-privacy-acceptance.component.scss'],
  providers: []

})
export class JpUpdatePrivacyAcceptanceComponent implements OnInit, OnDestroy {


  public signUpFormGroup: FormGroup;
  public siteLocale: any;
  public isCaptchaDisable = true;
  public formSubmitted = false;
  public disableButton: boolean;
  private errorPageRoute: string;
  private successPageRoute: string;
  public displayPopUp = false;
  public isCheckBoxChecked = false;
  SubscriptionList:Subscription[]=[];

  constructor(private _requestService: RequestService, private _translate: TranslateService,
    public _site: SiteService, private _cdr: ChangeDetectorRef,
    private _router: Router, private activatedRoute: ActivatedRoute, public dataShareService: DataSharingService,
    private titleService: SetTitleService) {
    if (localStorage.getItem('domain') !== 'CHN') {
      const base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      const errorPage = base[routingEndpoint.errorPage];
      this._router.navigate([errorPage], { queryParams: { 'orgName': 'Incorrect' } });
    }
    this.siteLocale = this._site.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
    const translateGetTranslationSubscription = this._translate.getTranslation(this._site.getSiteLocale()).subscribe((res) => {
      this.titleService.setTitleForPage(res['update_your_privacy_page_title']);
    });
    this.SubscriptionList.push(translateGetTranslationSubscription);
  }

  ngOnInit() {
    this.checkCaptchaDisabled();
    this.createFormGroup();
  }

  private checkCaptchaDisabled() {
    this._requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.isCaptchaDisabled,null,null).pipe().
    subscribe(response => {
      localStorage.setItem("disableCaptcha",response.data);
      // this.dataSharing.setIsCaptchaDIsabled(response.data.toString());
    });
  }

  protected createFormGroup(): void {
    this.signUpFormGroup = new FormGroup({
      firstname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change',
      }),
      lastname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change'
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email, this.emailValidator],
        updateOn: 'change'
      }),
      mobileNumber: new FormControl('', {
        validators: [this.mobileNumberValidator],
        updateOn: 'change'
      }),
      cvData: new FormControl('', {
        updateOn: 'change'
      }
      ),
      expertise: new FormControl('', {
        updateOn: 'change'
      },
      ),
      officeForSpecialism: new FormControl('', {
        updateOn: 'change'
      },
      ),
      privacyconsent: new FormControl('', {
        validators: [Validators.requiredTrue],
        updateOn: 'change'
      }
      ),
      reCaptchaReactive: new FormControl('',
        { updateOn: 'change' }
      ),
      languageProficiency: new FormArray([])
    });
    if (localStorage.getItem('disableCaptcha') === 'true') {
      this.isCaptchaDisable = true;
    }
    if (!this.isCaptchaDisable && this.signUpFormGroup) {
      this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
    }
  }


  public whitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  public mobileNumberValidator(control: FormControl) {
    const PHONE_REGEXP = /^\+?\d+$/; 
    const value = control.value;
    let isValid = true;
    if (value && !PHONE_REGEXP.test(value)) {
      isValid = false;
    }
    if(value && value.length < 11){
      isValid = false;
    }
    return isValid ? null : { 'invalid': true };
  }
  public emailValidator(control: FormControl) {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    const value = (control.value || '').trim();
    let isValid = true;
    if (value && !EMAIL_REGEXP.test(value)) {
      isValid = false;
    }
    return isValid ? null : { 'email': true };
  }
  checkBoxAcceptEventHander(event: boolean) {
    this.isCheckBoxChecked = event;
  }
  updatePrivacyAcceptance() {
    this.formSubmitted = true;
    if (this.isCheckBoxChecked && this.signUpFormGroup.valid) {
      this.disableButton = true;
      this.initRegister();
    } else {
      if (!this.isCheckBoxChecked) {
        this.displayPopUp = true;
      }
    }
  }


  initRegister() {
    let cvData: CvData;
    cvData = this.signUpFormGroup.controls.cvData.value;
    let registerJson = new Register(this.signUpFormGroup.controls.cvData.value, cvData.name, '', this.signUpFormGroup.value.email, this.signUpFormGroup.value.privacyconsent, null, null,
      null, null, this.signUpFormGroup.value.firstname, this.signUpFormGroup.value.reCaptchaReactive,
      null, null, this.signUpFormGroup.value.lastname, null, null, null, null, null, null, null, null, null, null, this.signUpFormGroup.value.mobileNumber, 'ChinaPrivacyPolicy', null);
    this.submitAndRegister(registerJson);
  }


  submitAndRegister(registerJson) {
    const base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
    this.successPageRoute = base[routingEndpoint.successPage];
    this.errorPageRoute = base[routingEndpoint.successPage];
    const requestServicePostRequestGateWaySubscription = this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.register, registerJson).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this._router.navigate([this.successPageRoute], { queryParams: { updatePrivacy: 'Y' } });
      } else {
        this._router.navigate([this.errorPageRoute], { queryParams: { updatePrivacy: 'N', error: response.status } });
      }
    }, (error) => {
      this._router.navigate([this.errorPageRoute], { queryParams: { updatePrivacy: 'N', error: error.status } });
    });
    this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);
  }
  closePopUp() {
    this.displayPopUp = false;
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 

}

