import {Component, OnDestroy, OnInit} from '@angular/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UnsubscribeRequest} from '../../../../model/request/unsubscribe-request';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-unsubscribe',
  templateUrl: './jp-unsubscribe.component.html',
  styleUrls: ['./jp-unsubscribe.component.scss']
})
export class JpUnsubscribeComponent implements OnInit, OnDestroy {
private identifier: string=null;
private signedUrl: string=null;
public loginUrl: string;
public email: string;
public showPrivacyPolicy: boolean;
public privacyPolicyLink: string;
public unsubscribeBtnClicked: boolean;
public isEshotUpdate: string='Y';
public isEshotUpdateValue: string
public isNewZealandCountry: boolean;
public isAustraliaCountry: boolean;
public isJapanCountry: boolean;
public isChinaCountry: boolean;
public isSwedenCountry: boolean;
public isCzechCountry: boolean;
public isPolandCountry: boolean;
public isRomaniaCountry: boolean;
SubscriptionList:Subscription[]=[];
public isResubscibeEnabled: boolean;
public currentUrl: any;
  constructor(private _translate: TranslateService, private activatedRoute: ActivatedRoute,private siteService: SiteService, private requestService: RequestService, private router: Router) {
   const activatedRouteQueryParamsSubscription= this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['signedurl']) {
        this.signedUrl=params['signedurl'];

      }
    });
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this.loginUrl= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
    this.showPrivacyPolicy=false;
    this.SubscriptionList.push(activatedRouteQueryParamsSubscription);
    this.isNewZealandCountry = this.siteService.getDomain()=='NZL' ? true : false;
    this.isAustraliaCountry = this.siteService.getDomain()=='AUS' ? true : false;
    this.isJapanCountry = this.siteService.getSiteLocale()=='jp_JP' ? true : false;
    this.isChinaCountry = this.siteService.getSiteLocale()=='cn_CHN' ? true : false;
    this.isSwedenCountry = this.siteService.getSiteLocale()=='sv_SE' ? true : false;
    this.isCzechCountry = this.siteService.getSiteLocale()=='cs_CZ' ? true : false;
    this.isPolandCountry = this.siteService.getSiteLocale()=='pl_PL' ? true : false;
    this.isRomaniaCountry = this.siteService.getSiteLocale()=='ro_RO' ? true : false;
    this.isResubscibeEnabled =environment[config.theme + this.siteService.getSiteLocale()]['config']['isResubscibeEnabled'];

  }

  ngOnInit() {
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    const activatedRouteQueryParamsIdentifierSubscription = this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['identifier']) {
        this.identifier=params['identifier'];
        let decodedString = atob(params['identifier']);
        //this.email=decodedString.split("email=")[1];

        this.email=this.getParameterByName('email',decodedString);

      }
      });
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS && res.data) {
          this.showPrivacyPolicy=res.data['applyOptions']['isSeekApply'];
          this.privacyPolicyLink=liferayBaseUrl+res.data['gdprPolicyLink'];
      }
    });
    this.SubscriptionList.push(activatedRouteQueryParamsIdentifierSubscription);
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
    if(this.isResubscibeEnabled==true){
      this.unsubscribe('N');
    }
    else
    {
      localStorage.setItem('isEshotUpdateValue',this.isEshotUpdate);
      this.unsubscribe(this.isEshotUpdate);
      this.isEshotUpdateValue=localStorage.getItem('isEshotUpdateValue');
    }
    this.currentUrl=this.router.url;
    console.log('activatedRoute',this.currentUrl);
  }
  unsubscribe(isEshotUpdate){
    localStorage.setItem('isEshotUpdateValue',isEshotUpdate);
    //this.isEshotUpdateValue=localStorage.getItem('isEshotUpdateValue');
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    let jsonData= new UnsubscribeRequest(this.identifier,this.email,null,this.signedUrl,isEshotUpdate);
    const requestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.unsubscribe,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(res.data.statuscode===config.portalSuccessCode) {
            if(this.isResubscibeEnabled==false){
              this.isEshotUpdateValue=localStorage.getItem('isEshotUpdateValue');
            }
            else {
              this.isEshotUpdateValue='Y'
            }
           }
        }
      }, (error) => {
      });
      this.SubscriptionList.push(requestServicePostRequestGateWaySubscription);

  }


    getParameterByName(name, url) {
       name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    ngOnDestroy(): void {
      this.SubscriptionList.forEach(subscribe=>{
        if(subscribe){
          subscribe.unsubscribe();
      }
      });
    }
}
