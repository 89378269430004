<app-custom-loader *ngIf="loader"></app-custom-loader>
<h4 [translate]="'previous_cv_text'" *ngIf="flow=='manageCvFlow' && cvData && cvData.length>0"></h4>
<h4 [translate]="'select_existing_cv_subheading'" *ngIf="flow=='jobApplyFlow' && cvData && cvData.length>0"></h4>
<ul>
  <li class="recent-cvs " *ngFor="let cvdata of cvData; let i = index">
    <div class="cv-item resume">
      <a href="javascript:void(0)" [textContent]="cvdata.docname"></a>
      <div class="last-updated">
        <span class="last-updated" [translate]="'last_updated_on'"></span>
        <span [textContent]="cvdata.lastediteddate"></span>
      </div>
    </div>
    <div class="cv-item text-right" *ngIf="cvdata.docid != 0">
      <button id="gtm_downloadcv_btn" type="submit" class="btn btn-view" (click)="downloadcv(cvdata.docname,cvdata.document)" [translate]="'view_cv'" ></button>
      <span class="cv-item" *ngIf="flow=='manageCvFlow'">
        <button id="gtm_removecv_btn" class="btn btn-remove" (click)="removecv(cvdata.docname,cvdata.docid)" [disabled]="loader" [translate]="'remove_cv_button_txt'"></button>
      </span>
      <span class="cv-item" [ngClass]="cvdata.selectedFile?'selected':''" *ngIf="flow=='jobApplyFlow'">
        <button id="gtm_selectcv_btn" class="btn btn-view btn-select" (click)="selectCv(cvdata.docname,cvdata.docid,i)"><span [translate]="'select_cv_button_txt'"></span><i></i></button>
      </span>
    </div>
  </li>
</ul>
