import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from '../../services/request/request.service';
import {SiteService} from '../../services/site/site.service';
import {DecryptIdentifierJsonRequest} from '../../../jobportal/model/response/decrypt-identifier';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../environments/root/environment';
import {SetTitleService} from '../../../jobportal/service/set-title.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-view-email-link-alert',
  templateUrl: './jp-view-email-link-alert.component.html',
  styleUrls: ['./jp-view-email-link-alert.component.scss']
})

export class JpViewEmailLinkAlertComponent implements OnInit, OnDestroy {
  public emailtext: any;
  SubscriptionList:Subscription[]=[];
  constructor(private requestService: RequestService,private siteService : SiteService, private activatedRoute: ActivatedRoute,private titleService:SetTitleService) { }

  ngOnInit() {
   const activatedRouteQueryParamsSubscription = this.activatedRoute.queryParams.subscribe((params)=> {
      //this.siteService.setSite(params.state);
      if (params['identifier'])
        this.viewEmailAlert(params['identifier']);

    });
    this.SubscriptionList.push(activatedRouteQueryParamsSubscription);
  }
  viewEmailAlert(identifier){
    let json = new DecryptIdentifierJsonRequest(identifier,"");
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    const viewEmailAlertRequestServicePostRequestGateWaySubscription = this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.viewEmailLink,json)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.titleService.setTitleForPage(res.data.data.split("<title>")[1].split("</title>")[0]);
          this.emailtext=res.data.data;
          //this.emailtext=res.data.data.split("<title>")[0]+res.data.data.split("</title>")[1];
        }
      }, (error) => {
       // this.router.navigate([baseRoute[routingEndpoint.errorPage]],{queryParams:{unsubscribe:keywords.fail}});
      });
      this.SubscriptionList.push(viewEmailAlertRequestServicePostRequestGateWaySubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
