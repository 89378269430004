

export class GetConsentRequest {
  constructor(public identifier: string,
              public appUrl: string) {}
}

export class GetConsentResponse {
  constructor(public status: string,
              public message: string,
              public data: GetConsentResponseData) {}
}

export class GetConsentResponseData {
  constructor(public eshot: boolean,
              public sms: boolean) {}
}


export class UpdateConsentRequest {
  constructor(public signedUrl: string,
              public eshot: boolean,
              public sms: boolean,
              public appUrl: string) {}
}

export class PrivacyPolicyRequest {
  constructor(public sections: string,
              public resource_uris: string) {}
}

export class PrivacyPolicyResponse {
  constructor(public resource_url: string,
              public linkName: string) {}
}
