import {APP_ID, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SiteService} from '../services/site/site.service';
import {LogsService} from '../services/logger/logs.service';
import {Observable} from 'rxjs';
import {apiEndPoints, config, environment} from '../../../environments/root/environment';
import {RequestService} from '../services/request/request.service';
import {TokenStorage} from '../services/tokenStorage/token-storage';
import * as langMapping from '../../../assets/language-mapping.json';
import {map} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import { isPlatformBrowser, isPlatformServer, PlatformLocation } from '@angular/common';
import { TransferState } from '@angular/platform-browser';
import { ROUTES_PATHS } from 'src/app/routes-paths';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  private domainName: string;
  constructor(private _logger: LogsService, private router: Router, private _siteService: SiteService,
              private activatedRoute: ActivatedRoute, private requestService: RequestService, private tokenStorage: TokenStorage, private cookieService: CookieService,
              private state: TransferState, @Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string, private location: PlatformLocation) {
    if (this.activatedRoute.snapshot.queryParamMap.has('logLevel') && this.activatedRoute.snapshot.queryParamMap.get('logLevel') != null) {
      this._logger.level = <any> this.activatedRoute.snapshot.queryParamMap.get('logLevel');
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(isPlatformBrowser(this.platformId)) {
      const token = this.cookieService.get('AhaysToken');
      const Asessionid = this.cookieService.get('Asessionid');
      if(token && Asessionid){
        localStorage.setItem('AhaysToken',token);
        localStorage.setItem('Asessionid', Asessionid);
        localStorage.setItem('client', 'hays');
        localStorage.setItem('entity', 'candidate');
        localStorage.setItem('specialism', 'all');
        localStorage.setItem('project', 'jobportal');
      }
    }
    return this.createSiteLocale(state);

  }


  canActivateChild() {
    return true;
  }

  createAnonymousUser(state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /*return this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.createToken, {}).toPromise();*/
    return this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.createToken, {}).pipe(map(res => {
      //console.log("create anonymous token res..",res);
      if (res.status === config.SUCCESS) {
        localStorage.setItem('AhaysToken', res.data.token);
        localStorage.setItem('Asessionid', res.data.userSession);
        localStorage.setItem('client', 'hays');
        localStorage.setItem('entity', 'candidate');
        localStorage.setItem('specialism', 'all');
        localStorage.setItem('project', 'jobportal');
        if(isPlatformServer(this.platformId)){
          const expiry = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
          this.cookieService.set('AhaysToken',res.data.token,expiry);
          this.cookieService.set('Asessionid',res.data.userSession,expiry);
          this.tokenStorage.setAnonymousSessionId(res.data.userSession);
          this.tokenStorage.saveAnonymousToken(res.data.token);
        }        
       //    this.setCookiesFromServerForToken();
        return true;
      }
    }, error => {
      return false;
    }));
  }


  createSiteLocale(state: RouterStateSnapshot) {
    try {
      if (state.root.children[0]) {
        let orgName = state.root.children[0].params['orgName'];
        this._siteService.setOrgName(orgName ? orgName : 'jobportal');
      } else {
        this._siteService.setOrgName('jobportal');
      }
      let hostName = '';
      if(isPlatformServer(this.platformId)){
        hostName = this.location.hostname;
      }else {
         hostName = location.hostname;
      }
      if (hostName.indexOf('moatvus') > -1 || hostName.indexOf('vereduscorp') > -1) {
        hostName = 'veredus.hays.vus';
      }
      let len = hostName.length;
      let n = hostName.indexOf('.');
      if (hostName.indexOf('.') > -1) {
        hostName = hostName.substring(n, len);
      }
      let runDomain =state.root.queryParams['domain'];
      if (hostName === 'localhost') {
        hostName =runDomain? runDomain:'.hays.com'; //'.hays.co.uk' '.hays.ie' '//.hays.fr'
      }
      this.domainName = hostName;
      let lang = '';
      let domain = '';
      let languageMapping: any;
      let domainDetails: any;
      languageMapping = langMapping;
      languageMapping = languageMapping.default;
      if (hostName && languageMapping) {
        domainDetails = languageMapping[hostName];
      }
      let liferayLocale = domainDetails.liferaySiteLocale;
      domain = domainDetails.domain;
      let langQuery = state.root.children[0].queryParamMap['lang'];
      if (state.url.indexOf('Job/Detail') > -1 || state.url.indexOf('job/detail') > -1 || state.url.indexOf('SeekAuth') > -1) {

        let recordId = state.root.children[0].params['recordId'];
        recordId = state.root.children[0].children[0].params['recordId']; //added
        if (recordId.indexOf('job_') > -1 || recordId.indexOf('JOB_') > -1) {
          if (recordId.indexOf('&') > -1) {
            recordId = recordId.split('&')[0];
          }
          lang = recordId.substring(recordId.length - 2);
          if (recordId.indexOf('HR') > -1 || recordId.indexOf('hr') > -1) {
            lang = 'hr' + lang;
          }
          if (/[a-z]/i.test(lang)) {
            lang = lang.toLowerCase();
          } else if ((!langQuery)) {
            langQuery = state.root.children[0].queryParams['lang'];
            if (langQuery) {
              lang = domainDetails.language[langQuery];
            } else if (this.cookieService.get('multi_country')) {
              let cookieName = this.cookieService.get('multi_country');
              let cookieNameList = cookieName.split('-');
              if (cookieNameList[0] === 'hrcs') {
                lang = 'hrcz';
              } else {
                lang = cookieNameList[0];
              }
            } else {
              lang = domainDetails.defaultLang;
            }
          } else if (langQuery) {
            lang = domainDetails.language[langQuery];
          } else if (this.cookieService.get('multi_country')) {
            let cookieName = this.cookieService.get('multi_country');
            let cookieNameList = cookieName.split('-');
            if (cookieNameList[0] === 'hrcs') {
              lang = 'hrcz';
            } else {
              lang = cookieNameList[0];
            }
          } else {
            lang = domainDetails.defaultLang;
          }
          const siteLocale = lang + '_' + domain;
          const routePath = ROUTES_PATHS[siteLocale];
          if(!routePath){
            this._siteService.setSite('/' + domain + '/' + lang + '/');
            let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
            this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
          }
          // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
          //  //    this.setCookiesFromServerForToken();
          //   return true;
          // } else {
          //   return this.createAnonymousUser(state);
          // }
        return   this.createAnonymousUserToken(state);
        } else {
          let langQuery = state.root.children[0].queryParamMap['lang'];
          if (!langQuery) {
            langQuery = state.root.children[0].queryParams['lang'];
            langQuery = domainDetails.language[langQuery];
          }
          if (langQuery) {
            lang = domainDetails.language[langQuery];
          } else {
            liferayLocale.forEach(locale => {
              if (recordId.indexOf('-'+locale) > -1) {
                lang = domainDetails.language[locale.split('-')[0]];
              }
            });
          }
        }
        const siteLocale = lang + '_' + domain;
        const routePath = ROUTES_PATHS[siteLocale];  
        if(!routePath){
          this._siteService.setSite('/' + domain + '/' + lang + '/');
          let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
          this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
        }
       
        // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
        //  //    this.setCookiesFromServerForToken();
        //   return true;
        // } else {
        //   return this.createAnonymousUser(state);
        // }
      return   this.createAnonymousUserToken(state);
      } else {
        if ((state.url.indexOf('job/') > -1 && state.url.indexOf('/apply') > -1) && (state.root.children[0].params['recordId'] || state.root.children[0].queryParams['applyId'])) {
          let langQuery = state.root.children[0].queryParamMap['lang'];
          if (!langQuery) {
            langQuery = state.root.children[0].queryParams['lang'];
            langQuery = domainDetails.language[langQuery];
          }
          let recordId = '';
          if (state.root.children[0].params['recordId']) {
            recordId = state.root.children[0].params['recordId'];
          } else {
            recordId = state.root.children[0].queryParams['applyId'];
          }
          if (recordId.indexOf('job_') > -1 || recordId.indexOf('JOB_') > -1) {
            lang = recordId.substring(recordId.length - 2);
            lang = lang.toLowerCase();
            if (recordId.indexOf('HR') > -1 || recordId.indexOf('hr') > -1) {
              lang = 'hr' + lang;
            }
            if(langQuery){
              lang = domainDetails.language[langQuery];
            }
            if (/[a-z]/i.test(lang)) {
              lang = lang.toLowerCase();
            } else if ((!langQuery)) {
              langQuery = state.root.children[0].queryParams['lang'];
              if (langQuery) {
                lang = domainDetails.language[langQuery];
              } else if (this.cookieService.get('multi_country')) {
                let cookieName = this.cookieService.get('multi_country');
                let cookieNameList = cookieName.split('-');
                if (cookieNameList[0] === 'hrcs') {
                  lang = 'hrcz';
                } else {
                  lang = cookieNameList[0];
                }
              } else if (this._siteService.getSiteLocale()) {
                let siteLocale = this._siteService.getSiteLocale();
                let siteLocaleList = siteLocale.split('_');
                if (siteLocaleList[0] === 'hrcs') {
                  lang = 'hrcz';
                } else {
                  lang = siteLocaleList[0];
                }
              } else {
                lang = domainDetails.defaultLang;
              }
            } else if (langQuery) {
              lang = domainDetails.language[langQuery];
            } else if (this.cookieService.get('multi_country')) {
              let cookieName = this.cookieService.get('multi_country');
              let cookieNameList = cookieName.split('-');
              if (cookieNameList[0] === 'hrcs') {
                lang = 'hrcz';
              } else {
                lang = cookieNameList[0];
              }
            }
            else if (this._siteService.getSiteLocale()) {
              let siteLocale = this._siteService.getSiteLocale();
              let siteLocaleList = siteLocale.split('_');
              if (siteLocaleList[0] === 'hrcs') {
                lang = 'hrcz';
              } else {
                lang = siteLocaleList[0];
              }
            } else {
              lang = domainDetails.defaultLang;
            }
            const siteLocale = lang + '_' + domain;
            const routePath = ROUTES_PATHS[siteLocale];  
            if(!routePath){
            this._siteService.setSite('/' + domain + '/' + lang + '/');
            let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
            this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
            }
            // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
            //  //    this.setCookiesFromServerForToken();
            //   return true;
            // } else {
            //   return this.createAnonymousUser(state);
            // }
          return   this.createAnonymousUserToken(state);
          }
        } else {
          if (state.root.children[0] && state.root.children[0].params['country']) {
            this._siteService.setSite(state.url);
            // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
            //  //    this.setCookiesFromServerForToken();
            //   return true;
            // } else {
            //   return this.createAnonymousUser(state);
            // }
          return   this.createAnonymousUserToken(state);
          } else {
            let langQuery = state.root.children[0].queryParamMap['lang'];
            if(state.root.children[0].params['lang']){
              langQuery = state.root.children[0].params['lang'];
            }
            if (!langQuery) {
              langQuery = state.root.children[0].queryParams['lang'];
              langQuery = domainDetails.language[langQuery];
            }
            if (langQuery) {
              lang = domainDetails.language[langQuery];
              const siteLocale = lang + '_' + domain;
              const routePath = ROUTES_PATHS[siteLocale];  
              if(!routePath){
              this._siteService.setSite('/' + domain + '/' + lang + '/');
              let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
              this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
              }
              // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
              //  //    this.setCookiesFromServerForToken();
              //   return true;
              // } else {
              //   return this.createAnonymousUser(state);
              // }
            return   this.createAnonymousUserToken(state);
            } else if (this.cookieService.get('multi_country')) {
              let cookieName = this.cookieService.get('multi_country');
              let cookieNameList = cookieName.split('-');
              if (cookieNameList[0] === 'hrcs') {
                lang = 'hrcz';
              } else {
                lang = cookieNameList[0];
              }
              const siteLocale = lang + '_' + domain;
              const routePath = ROUTES_PATHS[siteLocale];  
              if(!routePath){
              this._siteService.setSite('/' + domain + '/' + lang + '/');
              }
              // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
              //  //    this.setCookiesFromServerForToken();
              //   return true;
              // } else {
              //   return this.createAnonymousUser(state);
              // }
            return   this.createAnonymousUserToken(state);
            } else {
            if((this._siteService.getSiteLocale() && isPlatformBrowser(this.platformId)) || (isPlatformServer(this.platformId) && this.cookieService.get('multi_country'))) {
                let siteLocale = isPlatformServer(this.platformId) ? this.cookieService.get('multi_country') : this._siteService.getSiteLocale();
                let siteLocaleList = isPlatformServer(this.platformId)? siteLocale.split('-'):siteLocale.split('_');
                if (siteLocaleList[0] === 'hrcs')
                  lang = 'hrcz';
                else
                  lang=siteLocaleList[0];
              }else
                lang = domainDetails.defaultLang;
              const siteLocale = lang + '_' + domain;
              const routePath = ROUTES_PATHS[siteLocale];  
              if(!routePath){
                this._siteService.setSite('/' + domain + '/' + lang + '/');
                let cookieDomainName = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.cookieDomain];
                this.cookieService.set('multi_country', lang + '-' + domain, 0, '/', cookieDomainName);
              }
            
              // if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
              //  //    this.setCookiesFromServerForToken();
              //   return true;
              // } else {
              //   return this.createAnonymousUser(state);
              // }
            return   this.createAnonymousUserToken(state);


            }
          }
        }
      }
    } catch (e) {
      //console.log("Error inside auth gourd.." + e);
    }

    // });

    //return promise;


  }

  createAnonymousUserToken(state : RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean{
    if(isPlatformServer(this.platformId)) {
      if (this.tokenStorage.getAnonymousTokenFromCookies() && this.tokenStorage.getAnonymousSessionIdFromCookies()) {
          if(!this.tokenStorage.getAnonymousToken()) {
            localStorage.setItem('AhaysToken',this.tokenStorage.getAnonymousTokenFromCookies());
          }
          if(!this.tokenStorage.getAnonymousSessionId()){
            localStorage.setItem('Asessionid', this.tokenStorage.getAnonymousSessionIdFromCookies());
          }
          this.tokenStorage.setAnonymousSessionId(this.tokenStorage.getAnonymousSessionIdFromCookies());
          this.tokenStorage.saveAnonymousToken(this.tokenStorage.getAnonymousTokenFromCookies());
          
        return true;
      } else {
        return this.createAnonymousUser(state);
      }
    }else {
      if (this.tokenStorage.getAnonymousToken() && this.tokenStorage.getAnonymousSessionId()) {
        return true;
      } else {
        return this.createAnonymousUser(state);
      }
    }
  }

  // setCookiesFromServerForToken(){
  //   if(isPlatformServer(this.platformId)) {
  //     const AhaysToken = this.tokenStorage.getAnonymousToken();
  //     const Asessionid = this.tokenStorage.getAnonymousSessionId();
  //     if(AhaysToken) {
  //       this.cookieService.set('AhaysToken',AhaysToken);
  //     }
  //     if(Asessionid) {
  //       this.cookieService.set('Asessionid',Asessionid);
  //     }
  //   }
  // }
}
