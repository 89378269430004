import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {JobSearchService} from '../../../../../service/job-search.service';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {CookieService} from 'ngx-cookie-service';
import {GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../../service/set-title.service';
import {apiEndPoints, config, environment, keywords} from '../../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Meta, Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-thank-you',
  templateUrl: './jp-thank-you.component.html',
  styleUrls: ['./jp-thank-you.component.scss']
})
export class JpThankYouComponent implements OnInit, OnDestroy {
  public googleJobResponse: GoogleJobResponse[];
  public googleSimilarJobResponse: GoogleJobResponse[];
  private userAgent:string;
  private cookieDomain:string;
  orgName: string;
  haysCareers = config.ExpertClientsName;
  SubscriptionList:Subscription[]=[];

  constructor(private searchService:JobSearchService,private activatedRoute:ActivatedRoute,private siteService:SiteService,
              private tokenStorage:TokenStorage,private xmlParserService:NgxXml2jsonService,private cookieService:CookieService,
              private translateService:TranslateService,private titleService:SetTitleService,
              private requestService:RequestService,private router:Router,private dataSharingService: DataSharingService,
              private deviceDetector:DeviceDetectorService,private titleServiceT: Title,private metaService: Meta, @Inject(DOCUMENT) private document: Document) {
    const translateServiceGetTranslationSubscription = this.translateService.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['job_detail_page_title']);
    });
    this.dataSharingService.getWidgetListName(keywords.jobAppliedWidget);
    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.SubscriptionList.push(translateServiceGetTranslationSubscription);
  }

  ngOnInit() {
  let locationDesc='';
  let jobTitle='';
  let applyId='';
   const activatedRouteQueryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('jobTitle'))
        jobTitle= param.get('jobTitle');
      if(param.get('locationDesc'))
        locationDesc= param.get('locationDesc');
      if(param.get("applyId"))
        applyId=param.get("applyId");
      if(param.get('orgName'))
        this.orgName= param.get('orgName');
      this.searchService.getJobDeatils(this.activatedRoute, this.requestService, this.siteService, this.tokenStorage,
        this.xmlParserService, 'similarJob',encodeURIComponent(jobTitle),
        locationDesc, this.cookieService,this.router,this.userAgent,this.cookieDomain, this.dataSharingService,this.translateService,this.titleServiceT,this.metaService,this.document);
      const searchServiceSharedJobResponseListSubscription= this.searchService.sharedJobResponseList.subscribe(jobDetailData => {
        this.googleSimilarJobResponse = jobDetailData;
        if (this.googleSimilarJobResponse && this.googleSimilarJobResponse.length > 0) {
          this.googleSimilarJobResponse.forEach((job,index) => {
              if (job.jobId === applyId)
                this.googleSimilarJobResponse.splice(index, 1);
          });
        }
      });
      this.SubscriptionList.push(searchServiceSharedJobResponseListSubscription);
    });
    this.SubscriptionList.push(activatedRouteQueryParamMapSubscription);
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
