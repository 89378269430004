import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../environments/root/environment';
import {SiteService} from '../site/site.service';
import {HeaderRequestJson} from '../../models/header/header-menu';
import {CookieService} from 'ngx-cookie-service';
import { isPlatformServer, PlatformLocation } from '@angular/common';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { Request, Response } from 'express'
import { HttpErrorResponse } from '@angular/common/http';
import { AzureGaService } from '../azure-ga/azure-ga.service';


const TOKEN_KEY_SECURE = 'haysToken';
const USR_FIRST_NAME = 'firstName';
const USR_LAST_NAME = 'lastName';
const USR_EMAIL_ADD = 'emailAdd';
const USR_ISWHATSAPP = 'isWhatsapp';
const TOKEN_KEY_ANONYMOUS = 'AhaysToken';
const DEBUG_MODE = 'mode';
const SESSION_GUID_SECURE = 'userSession';
const SESSION_GUID_ANONYMOUS = 'Asessionid';
// const EXPIRY_TIME = 'expires_at';

@Injectable()
export class TokenStorage {
  /**
   * Captures the azure login URL
   */
  private azureLoginURL: string = '';

  constructor(private router: Router,private siteService:SiteService,private cookieService:CookieService, @Inject(PLATFORM_ID) private platformId: object,  @Optional() @Inject(REQUEST) private request: Request, private location: PlatformLocation, @Optional() @Inject(RESPONSE) private response: Response, 
              private gaService: AzureGaService) { }
  public removeSecureToken() {
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
  }
  public signOut() {
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    //this.cookieService.delete('usrname','/');
    this.cookieService.delete('alerts','/');
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.deleteAll('/',cookieDomainName);
    this.login('q','unauthorised','','','loggedin',0);
    this.removeLoggedInUserDetails();
  }

  public saveToken(token: string) {
    // localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.setItem(TOKEN_KEY_SECURE,  token);
  }
  public saveLoggedInUserDetails(firstName,lastName,email,isWhatsapp){
    localStorage.setItem(USR_FIRST_NAME,  firstName);
    localStorage.setItem(USR_LAST_NAME,  lastName);
    localStorage.setItem(USR_EMAIL_ADD,  email);
    localStorage.setItem(USR_ISWHATSAPP,  isWhatsapp);
  }
  public removeLoggedInUserDetails(){
    localStorage.removeItem(USR_FIRST_NAME);
    localStorage.removeItem(USR_LAST_NAME);
    localStorage.removeItem(USR_EMAIL_ADD);
    localStorage.removeItem(USR_ISWHATSAPP);
  }
  public saveLoggedInToken(token: string) {
    localStorage.setItem(TOKEN_KEY_SECURE,  token);
  }
  public saveAnonymousToken(token: string) {
    localStorage.setItem(TOKEN_KEY_ANONYMOUS,  token);
    if(isPlatformServer(this.platformId)){
       this.request.session.anonymousToken = token;
    }
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY_SECURE)?localStorage.getItem(TOKEN_KEY_SECURE):localStorage.getItem(TOKEN_KEY_ANONYMOUS);
  }
  public getLoggedInToken(): string {
    return localStorage.getItem(TOKEN_KEY_SECURE);
  }
  public getAnonymousToken(): string {
    if(isPlatformServer(this.platformId)){
      return this.request.session.anonymousToken;
    }else {
      return localStorage.getItem(TOKEN_KEY_ANONYMOUS);
    }
  }
  public getAnonymousTokenFromCookies(): string {
    if(isPlatformServer(this.platformId)) {
      return this.cookieService.get(TOKEN_KEY_ANONYMOUS);
    }else {
      return localStorage.getItem(TOKEN_KEY_ANONYMOUS);
    }
  }

  public saveDebugMode(debugMode: string) {
    localStorage.setItem(DEBUG_MODE,  debugMode);
  }
  public getDebugMode(): string {
    return localStorage.getItem(DEBUG_MODE);
  }
  public saveSessionId(sessionId: string) {
    localStorage.setItem(SESSION_GUID_SECURE,  sessionId);
  }
  public getSessionId(): string {
    return localStorage.getItem(SESSION_GUID_SECURE)?localStorage.getItem(SESSION_GUID_SECURE):localStorage.getItem(SESSION_GUID_ANONYMOUS);
  }
  public getLoggedInSessionId(): string {
    return localStorage.getItem(SESSION_GUID_SECURE);
  }

  public getAnonymousSessionId(): string {
      if(isPlatformServer(this.platformId)){
        return this.request.session.anonymousSessionId;
      }else {
        return localStorage.getItem(SESSION_GUID_ANONYMOUS);
      }
      
  }
  setAnonymousSessionId(anonymousSessionId: string ){
    this.request.session.anonymousSessionId = anonymousSessionId;
  }
  public getAnonymousSessionIdFromCookies(): string {
    if(isPlatformServer(this.platformId)) {
      return this.cookieService.get(SESSION_GUID_ANONYMOUS);
    }else {
      return localStorage.getItem(SESSION_GUID_ANONYMOUS);
    }
  }

  public loggedInSignOut(key,value,dataSharing,requestService,status,code){
    const multi_country = this.cookieService.get('multi_country');
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.delete('alerts','/');
    this.cookieService.deleteAll('/',cookieDomainName);
    this.removeLoggedInUserDetails();
    if(multi_country){
      this.cookieService.set('multi_country', multi_country, 0, '/', cookieDomainName);
    }
    this.login(key,value,dataSharing,requestService,status,code);

  }
  public clearCacheAndCookie(){
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.delete('alerts','/');
    this.cookieService.deleteAll('/',cookieDomainName);
    this.removeLoggedInUserDetails();
  }

  public login(key:string,param: string,dataSharing,requestService,status,code) {
    if (status !== 'loggedin') {
      localStorage.removeItem(TOKEN_KEY_ANONYMOUS);
      localStorage.removeItem(SESSION_GUID_ANONYMOUS);
    }
    if (dataSharing && requestService)
      this.headerCallAfterSignOut(dataSharing, requestService);
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if (key && param) {
      if (status === 'loggedin' || this.router.url.indexOf('my-profile')>-1 || this.router.url.indexOf('/s/')>-1) {
        let isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
        if (isAzureEnabled) {
          this.getAzureLoginURL(param, key);  
        }else{
          let routingUrl = '';
          if(orgName){
            let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login];
            routingUrl = url.replace(':orgName',orgName);
          }
          else
            routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
          window.location.href = window.location.origin + routingUrl + "?" + key + "=" + param;
        }

      } else if(code==403){
        let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
        this.router.navigate([baseRoute],{queryParams:{'orgName':'Incorrect'}});
      }else {
        window.location.reload();
      }
    }
  }

  private headerCallAfterSignOut(dataSharing,requestService){
      let headerJson= new HeaderRequestJson(
        '',
        environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
      );
      let endPoint=apiEndPoints.pagemanagement;
      requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,endPoint,headerJson)
        .subscribe((res)=>{
          dataSharing.clearHeader();
          if(res.status==config.SUCCESS && res.data) {
            dataSharing.sendHeaderData(res);
          }
        });
    }
    public refreshAnonymousToken() {
       localStorage.removeItem(SESSION_GUID_ANONYMOUS);
       localStorage.removeItem(TOKEN_KEY_ANONYMOUS);
       sessionStorage.removeItem(SESSION_GUID_ANONYMOUS);
       sessionStorage.removeItem(TOKEN_KEY_ANONYMOUS);
       this.cookieService.delete(SESSION_GUID_ANONYMOUS);
       this.cookieService.delete(TOKEN_KEY_ANONYMOUS);
       if(isPlatformServer(this.platformId)){
        this.request.session.anonymousToken = '';
        this.request.session.anonymousSessionId = '';
       }
    }

    public navigateToError(){
      const orgName = this.router.routerState.snapshot.root.firstChild.params['orgName'];
      let routeURL = '';
      if(orgName){
        routeURL = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage].replace(':orgName', orgName);
      }
      else{
        routeURL = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
      }
      this.router.navigate([routeURL], {queryParams : {authorizationTimeout : 'Y'}});
    }
    /**
     * Logs following to the console
     * Date time of error
     * API URL
     * Status Code
     * @param error 
     */
    public logToConsole(error : HttpErrorResponse){
      if(isPlatformServer(this.platformId)){
        const refererURL = this.getRefererUrl();
        console.log('Referer URL : ' + refererURL);
        console.log("Date-Time : " + new Date() + ", API URL : " + error.url + ", Status Code: " + error.status);
      }
    }

    public getRefererUrl(){
      let referrer ='';
      if(isPlatformServer(this.platformId)){
        const url = new URL(this.location.href);
        url.port = '';
        referrer = url.toString();
        if(this.location.hostname==='localhost') {
          referrer = this.location.href;
        }
      }
      return referrer;
    }
    /**
     * Method to be called to get the azure login URL
     * @param param 
     */
    private getAzureLoginURL(param, key){
      if(this.gaService.azureLoginURL){
        this.azureLoginURL = this.gaService.azureLoginURL;
        this.addParamsToAzureURL(param, key);
      }
      else{
        this.gaService.azureURLObservable.subscribe((response) => {
          if(response){
            this.azureLoginURL = this.gaService.azureLoginURL;
            this.addParamsToAzureURL(param, key);
          }
        })
      }
      if(!this.azureLoginURL && !this.gaService.isIntervalExecuting){
        this.gaService.generateAzureURL();
      }
    }
    /**
     * Replaces workflow and action in the URL
     * Adds isSuccess param to URL
     * Redirects user to azure login
     * @param param 
     * @param key 
     */
    private addParamsToAzureURL(param, key){
      this.azureLoginURL = this.azureLoginURL.replace('&workFlow=', "&workFlow="+key).replace('&action=', "&action=logout");
      window.location.href = this.azureLoginURL + "&isSuccess"+ "=" + param ;
    }
  }
