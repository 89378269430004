 <jw-modal id="delete-all-job-alerts" class="modal-popup">
   <div class="modal-header">
    <h3>Confirmation<a href="javascript:void(0)" class="close" (click)="closeModal('delete-all-job-alerts')">+</a></h3>
   </div>
   <div class="modal-body">
     <ul class="manage-alert-listing">
       <h5 [translate]="'delete_all_job_alerts_text1'"></h5>
       <p [translate]="'delete_all_job_alerts_text2'"></p>
       <li *ngFor="let alert of jobAlertResponse">

         <h6 [textContent]="alert.alertname"> </h6>
         <span> {{alert.lastediteddate|date: 'dd MMM yyyy'}} </span>

       </li>
     </ul>
   </div>
   <div class="modal-footer">
       <button id="delete_alerts_button" class="btn btn-secondary" [translate]="'delete_all_job_alerts_button'" (click)="deleteAllJobAlerts(jobAlertResponse)"></button>
       <button id="cancel_alerts_button" class="btn btn-cancel" [translate]="'cancel_button'" (click)="closeModal('delete-all-job-alerts')"></button>
   </div>


 </jw-modal>

