import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {LogsService} from '../logger/logs.service';
import {Site} from '../../models/site/site';
import {domain_mapping} from '../../constants/domain-mapping';
import {Route, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
@Injectable({
  providedIn: 'root'
})
export class SiteService {

  site: Site;
  constructor( private _logger: LogsService,private router:Router,private httpClient:HttpClient, @Inject(PLATFORM_ID) private platformId: object,  @Optional() @Inject(REQUEST) private request: Request) {
    // this.site = new Site('GB','en');
  }

  /*setSite(url: string) {
    this.site = new Site(url.split('/')[1], url.split('/')[2]);
    localStorage.setItem('domain', this.site.domain);
    localStorage.setItem('locale', this.site.locale);
    localStorage.setItem('siteLocale', this.site.siteLocale);
  }*/
  setSite(url: string) {
    this.site = new Site(url.split('/')[1],url.split('/')[2]);
    this.setLocalStorage(this.site);

  }

  setSiteLocale(url:string){

  }
  setSitePartner(url: string) {
    this.site = new Site(url.split('/')[3], url.split('/')[4]);
    this.setLocalStorage(this.site);
  }
  setSite2(domain: string, locale: string) {
    this.site = new Site(domain, locale);
    this.setLocalStorage(this.site);

  }
  setLocalStorage(site: Site){
    localStorage.removeItem('domain');
    localStorage.removeItem('locale');
    localStorage.removeItem('siteLocale');
    localStorage.setItem('domain', site.domain);
    localStorage.setItem('locale', site.locale);
    localStorage.setItem('siteLocale', site.siteLocale);
    if(isPlatformServer(this.platformId)){
      this.request.session.siteLocale = site.siteLocale;
    }
  }

  getDomain() {
    return this.site.domain;
  }

  getDomainFromURL(url) {

    this.setSite(url);
    return this.site.domain;
  }

  getLocale() {
    return this.site.locale;
  }

  getSiteLocale() {
    if(isPlatformServer(this.platformId)){
      return this.request.session.siteLocale;
    }else {
      return localStorage.getItem('siteLocale')
    }
   
  }
  setOrgName(orgName) {
    localStorage.removeItem('orgName');
    localStorage.setItem('orgName',orgName);
  }
  getOrgName(){
    return localStorage.getItem('orgName')
  }
}

