import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../environments/root/environment';
import {RequestService} from '../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {ExtraFieldsConfig} from '../../../model/response/google-job-response';
import {JobSearchService} from '../../../service/job-search.service';
import {map} from "rxjs/operators";
import {CustomerServiceEmail} from "../../../model/request/email-confirmation";
import {TokenStorage} from "../../../../global/services/tokenStorage/token-storage";
import {DataSharingService} from '../../../service/data-sharing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jp-unsubscribe-thankyou',
  templateUrl: './jp-alert-thankyou.component.html',
  styleUrls: ['./jp-alert-thankyou.component.scss']
})
export class JpAlertThankyouComponent implements OnInit ,OnDestroy{
public isgdprCountry : boolean;
public baseUrl : string;
public loginUrl : string;
public marketingPrefUrl : string;
public activated : string;
public unsubscribe : string;
public verifyAlert : boolean;
  public showGDPRText: boolean;
  public manageJobAlertUrl: string;
  public erpMailConfirmUrl: string;
  public isErpVerifyAlert: boolean;
  SubscriptionList:Subscription[]=[];

  constructor(private _translate: TranslateService,private router: Router, private activatedRoute: ActivatedRoute,private siteService: SiteService,
              private requestService:RequestService,private jobSearchService:JobSearchService, private token:TokenStorage, private dataShareService: DataSharingService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    const jobSearchServiceSharedConfigListSubscription= this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
      if(domainConfig && domainConfig.isGDPRPrivacyPolicyEnabled) {
         this.showGDPRText=true;
        this.setMessages();
      }else{
        this.setMessages();
      }
    });
    this.baseUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this.getRouteUrls();
    this.SubscriptionList.push(jobSearchServiceSharedConfigListSubscription);
  }
  isGDPRCountry(){
    const isGDPRCountryRequestServiceGetRequestSubscription= this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getGdprConfig,null,null)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.isgdprCountry=res.data.isGDPRTrackingEnabled;
        }
      }, (error) => {

      });
      this.SubscriptionList.push(isGDPRCountryRequestServiceGetRequestSubscription);
  }


  private setMessages() {
    const requestServiceGetRequestSubscription = this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getCustomerServiceEmail).subscribe(response => {
      let isAlertV2 = this.activatedRoute.snapshot.queryParamMap.get('workflow');
      if (response.status === config.SUCCESS && response.data.erpMailConfirm && isAlertV2){
        this.isErpVerifyAlert = true;
        this.setErpUrls();
      };});
    const activatedRouteQueryParamsSubscription = this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['Submit']) {
        this.verifyAlert=false;
        if(this.showGDPRText)
          this.isGDPRCountry();
        /*this.baseUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
        this.loginUrl= this.baseUrl[routingEndpoint.login];*/
      }
      if(params['activated']) {
        this.verifyAlert=true;
        this.activated=params['activated'];
        let locale=params['lang'];
        let domain='';
        if(locale){
          domain=this.siteService.getDomain();
        this.siteService.setSite2(domain,locale);
        this._translate.setDefaultLang(this.siteService.getSiteLocale());
        }

      }
      if(params['unsubscribe']) {
        this.verifyAlert=false;
        this.unsubscribe=params['unsubscribe'];
        let locale=params['lang'];
        if(locale){
          let domain=this.siteService.getDomain();
          this.siteService.setSite2(domain,locale);
          this._translate.setDefaultLang(this.siteService.getSiteLocale());
        }

      }
    });
    this.SubscriptionList.push(requestServiceGetRequestSubscription);
    this.SubscriptionList.push(activatedRouteQueryParamsSubscription);
  }

  setErpUrls(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    this.erpMailConfirmUrl = window.location.origin+this.baseUrl[routingEndpoint.erpEmailConfirmation];
    if(orgName){
      this.erpMailConfirmUrl = window.location.origin+(environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.erpEmailConfirmation]).replace(':orgName',orgName);
    }
    if(this.token.getLoggedInToken()){
      this.manageJobAlertUrl = orgName?
                              window.location.origin+(environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.secureErpManageJobAlert]).replace(':orgName',orgName):
                              window.location.origin+this.baseUrl[routingEndpoint.secureErpManageJobAlert];
    }else{
      let defaultJobSource='';
      const dataShareServiceSharedClientConfigSubscription= this.dataShareService.sharedClientConfig.subscribe(res => defaultJobSource = res?.jobSource );
      let source = this.activatedRoute.snapshot.queryParamMap.get('jobSource')?'&jobSource='+this.activatedRoute.snapshot.queryParamMap.get('jobSource'):'?jobSource='+defaultJobSource;
      let url = orgName ?
                (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.erpManageJobAlert]).replace(':orgName',orgName) :
                this.baseUrl[routingEndpoint.erpManageJobAlert];
      this.manageJobAlertUrl = window.location.origin + url + '?identifier='+this.activatedRoute.snapshot.queryParamMap.get('identifier')+source;
      this.SubscriptionList.push(dataShareServiceSharedClientConfigSubscription);
    }
  }

  getRouteUrls() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.loginUrl = window.location.origin + (base[routingEndpoint.login]).replace(':orgName',orgName);
      this.marketingPrefUrl= (base[routingEndpoint.emailConfirmationPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.loginUrl = window.location.origin + base[routingEndpoint.login];
      this.marketingPrefUrl= base[routingEndpoint.emailConfirmationPage];
    }
  }
  ngOnDestroy(): void {
    this.SubscriptionList.forEach(subscribe=>{
      if(subscribe){
        subscribe.unsubscribe();
    }
    });
  } 
}
