export const baseUrl = {
  apiBaseUrl:'https://moatapi.hays.com.au/jobportalapi',
  go1ApiBaseUrl:'https://moat.hays.com.au/training',
  apiBaseUrlWrapper:'https://moatapi.hays.com.au/jobportalwr'
};
export const en_NZL = {
  config:{
    countryCode:', New Zealand',
    country_name: 'New Zealand',
    gtm_code:"GTM-PQXCGB",
    timesheetUrl:"https://www1.hays.com.au/haysonline/Login.aspx",
    salaryGuideUrl:"http://salaryguide.hays.com.my/",
    view_google_link:'http://maps.google.co.nz/maps?q=',
    haystech_register_vacancy_link:'https://www.hays.net.nz/it/register-a-vacancy',
    haystech_enquire_now_link:'https://www.hays.net.nz/it/rpo-recruitment',
    zopim_enabled:false,
    zopim_enabled_device:'-Desktop',
    zopim_key_withURL:"//v2.zopim.com/?2SfCzrC8fQC6u5Mn6KEr6KMcqQoU4nJL",
    locationParentId: "2691041",
    maximumSize:2097152,
    appleItunesMetaEnabled: true,
    isGo1Enabled:true,
    azure_enable: true,
    //auzureLoginURL : 'https://moat.hays.net.nz/prjsauthapp/authurl/JobPortal/{orgName}/candidate/nzl/en',
    auzureLoginURL : 'https://testsg.hays.net.nz/portal/auth?application=jobportal&client={orgName}&entityType=candidate&domain=nzl&locale=en&hostname=testsg&action=&workFlow=',
    dynamicPageTitleMetaTags: true,
    canonicalSearchLinkCompleteURL: false,
    filterSectors: true,
    filterableSectorsList: ['Enterprise Solutions'],
    indeedAPIURL:'https://cprofile1test.hays.com.au/portalservice/resources/registration/applyindeed/',
    addAutoBlockAssetScriptEnabled: true,
    addAutoBlockOptOutScriptEnabled: true,
    isCaptchaValueEnabled: false,
    isResubscibeEnabled: false
  },
  liferaybaseUrl: 'https://testsg.hays.net.nz',
  liferayUrl: 'https://testsg.hays.net.nz',
  cookieDomain:'.hays.net.nz',
  cookieBannerDomain:'.hays.net.nz',
  siteBaseURL: 'https://testsg.hays.net.nz',
  gateWayAPI:{
    location: baseUrl.apiBaseUrl+'/nzl/en/jobportal/s/candidate/submit/location',
    isCaptchaDisabled : baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/captchaenabled',
    locationOnRecruitingNow:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/recruiting/browse/v1/location?type=recruiting-now-locations',
    employeeType:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/recruiting/browse/v1/employment?type=recruiting-now-employment',
    submitRecruitingForm:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/submit/v1/recruitingnow',
    getSector: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/specilisms', // post call for getting list of sectors
    getLocationHierarchy :  baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/hierarchy',
    getStateList : baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/states',
    getOfficeForSpecialism: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/offices',
    getSubSpecialism: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/subspecialism ',
    getAllOffice: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/alloffices',
    getOfficeResultForAPAC: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/officeresultapac',
    getLatLongOfficeResult: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/officeresultlatlong',
    isAutoSugesstionEnabled: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/google/browse/v1/isautosugesstionenabled',
    getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkboxnew',
    getGDriveAndDropbox: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/partner/browse/v1/isdropboxgdrivedisabled',
    getIsKanaEnabled: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/partner/browse/v1/iskanaenabled',
    register: baseUrl.apiBaseUrlWrapper +'/int/s/nzl/en/{orgname}/candidate/submit/v1/register',
    pagemanagement:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/pagemanagement/browse/v1/pagemenu',
    countryLanguage:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/site/browse/v1/getlanguages?type=change_language',
    getBrowseByExpertise:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/browsebyexpertise',
    showOfficeEmail:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/showofficeemail',
    connectdropbox:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/connectdropbox',
    connectGdrive:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/connectgdrive',
    getDropBoxFiles:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/dropboxfiles',
    getDriveFiles:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/gdrivefiles',
    getFileLink:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/dropboxfilelink',
    gdriveFileLink:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/gdrivefilelink',
    getAutoSuggetion:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/autosuggestion?keyWord=',
    submitEmail:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/gdpr/submit/emailconfirmation',
    getgooglejobs:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/jobsv2',
    getgooglejobssecure:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/jobsv2',
    jobflowconfig:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/pagemanagement/browse/v1/jobflowconfig',
    getJobFilters:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/filters?type=job_filter',
    getCustomerServiceEmail: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/pagemanagement/browse/v1/customeremail',
    pagemanagementSecure:baseUrl.apiBaseUrl +'/int/s/nzl/en/{orgname}/pagemanagement/browse/v1/pagemenu',
    createToken:baseUrl.apiBaseUrl+'/ext/ns/nzl/en/{orgname}/usermanagement/create/v1/token',
    validatePage:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/usermanagement/validate/v1/page',
    submitForgetPassEmail: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/submit/v1/forgetpassword',
    getInterviewAndCvTips: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/browse/v1/interviewandcvtips',
    getRecentApplication: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/browse/v1/recentapplication',
    getInterviewCount: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/notification/browse/v1/interview',
    getUpcomingInterview: baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/browse/v1/interviewdetails',
    getpersonaldetails: baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/browse/v1/getpersonaldetailsupgrade',
    getGDriveFiles: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/partner/browse/v1/gdrivefiles',
    gcsPublish:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/submit/v1/gcsevent',
    saveAlert:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/create/v1/alert',
    saveWhatsapp:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/submit/v1/whatsapp',
    updateWhatsapp:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/update/v1/whatsappnumber',
    getWhatsappNumber:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/browse/v1/whatsappnumber',
    getWhatsAppAlerts:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/decrypt/v1/whatsappalert',
    getJobAlerts:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/alert',
    getGdprConfig:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/pagemanagement/browse/v1/gdprconfig',
    getMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/gdpr/browse/v1/marketingpreferences',
    updateMarketingPreferences:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/gdpr/update/v1/marketingpreferences',
    decryptIdentifier:baseUrl.apiBaseUrl+'/int/ns/nzl/en/{orgname}/decrypt/browse/v1/decryptidentifier',
    saveJob: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/job/create/v1/savejob',
    deleteJob: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/job/delete/v1/deletesavedjob',
    getSavedJobs: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/job/browse/v1/getsavedjobs',
    resetPassword:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/submit/v1/resetpassword',
    deleteJobAlert:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/delete/v1/alert',
    editAlert:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/update/v1/alert',
    changeEmail:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/submit/v1/changeemail',
    getCvs:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/browse/v1/getcvs',
    downloadCV:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/browse/v1/downloadcv',
    deleteCv:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/delete/v1/deletecv',
    getSingleJob:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/browse/v1/getsavedjob',
    changePassword:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/candidate/submit/v1/changepassword',
    uploadcv:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/upload/v1/cv',
    jobApply: baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/candidate/submit/v1/jobapply',
    unsubscribe: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/alert/v1/unsubscribe',
    jobAlertVerify: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/alert/v1/verify',
    applyLinkedin: baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/job/v1/applylinkedin',
    getWidgetList: baseUrl.apiBaseUrl+'/int/ns/nzl/en/{orgname}/content/browse/v1/widgetlist',
    getTimeSheet: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/auth/browse/v1/pagemenu',
    getKillerQuestions: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/content/browse/v1/killerquestions',
    seekAuthUrl:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/job/browse/v1/seekurl',
    seekApply:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/job/submit/v1/applyseek',
    signedURL:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/user/create/v1/signedurl',
    getErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/user/browse/v1/marketingpref',
    updateErpMarketingPref:baseUrl.apiBaseUrlWrapper+'/int/s/nzl/en/{orgname}/user/save/v1/marketingpref',
    erpValidateEmail:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/user/send/v1/consentemail',
    validateAlert: baseUrl.apiBaseUrl+'/int/ns/nzl/en/{orgname}/alert/v1/validatealert',
    getClientConfig:baseUrl.apiBaseUrl+'/int/ns/nzl/en/{orgname}/client/browse/v1/clientconfig',
    deleteAllAlerts:baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/job/delete/v1/deleteallalerts',
    viewEmailLink:baseUrl.apiBaseUrl+'/int/ns/nzl/en/{orgname}/alert/v1/viewmaillink',
    go1generateLink: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/training/create/v1/generatego1link',
    go1getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/nzl/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkbox_newgo1',
    getGo1Trainings: baseUrl.apiBaseUrl+'/int/s/nzl/en/{orgname}/training/fetch/gettraining',
    logoutUser:baseUrl.apiBaseUrl+'/ext/ns/nzl/en/{orgname}/auth/delete/v1/session',
  },
  routing_url:{
    searchLinkTitleTerm: '-jobs',
    searchLinklocTerm: 'in-',
    searchLinkTitle: '/job-search/{jobTitle}-jobs',
    searchLinkLocation: '/job-search/in-{location}',
    searchLinkInLocation: '/job-search/{jobTitle}-jobs-in-{location}',
    errorPage:'/account/error/',
    successPage:'/account/cv-success/',
    successUpdateEmailPrefences:'/account/sent-preferences-email',
    confirmationPage: '/partner/ps/success',
    officeLocatorResultPage:'/contact/offices-near-me/result',
    officeLocatorPage:'/contact/offices-near-me',
    speculativeCV:'/account/send-cv',
    myProfile:'/my-account/profile',
    login:'/account/login',
    register: '/account/register',
    search:'/job-search',
    secureSearch:'/job-search',
    secureJobDetails:'/job-detail',
    jobdetails:'/job-detail',
    forgetPassword:'/account/login/forgot-password',
    header:'/NZL/en/newheader',
    footer:'/NZL/en/newfooter',
    recordIdJobDetail:'/NZL/en/jobportal/s/candidate/job/browse/details/',
    jobApply:'/job-detail/:recordId/apply',
    secureJobApply:'/job-detail/:recordId/apply',
    gdprThankyouPage:'/my-account/marketing-preferences-confirmed',
    secureGdprThankyouPage:'/my-account/marketing-preferences-confirmed',
    secureMarketingPrefPage:'/my-account/marketing-preferences',
    marketingPrefPage:'/my-account/marketing-preferences',
    resetPasswordPage:'/account/reset',
    manageCV:'/my-account/manage-cv',
    secureThankYou:'/job-detail/:recordId/job-success',
    thankyou:'/job-detail/:recordId/job-success',
    saveWhatsappPage:'/my-account/whatsapp-number-confirmed',
    updateWhatsappPage:'/my-account/update-whatsapp-number',
    unsubscribePage:'/prjs/NZL/en/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/account/job-alert-success',
    emailConfirmationPage:'/prjs/NZL/en/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/recruitment/recruit-now',
    secureRecruitingNow:'/recruitment/recruit-now',
    goLoginPage:'/account/partner/go1/training/login',
    savedSearchesPage: '/my-account/job-alerts',
    shortlistedJobsPage: '/account/saved-jobs',
    changeEmailPage:'/my-account/change-email',
    emailConfirmation:'/account/update-preferences-email',
    logOutPage:'/account/logout',
    erpMarketingPref: '/account/erp/marketing-preferences',
    secureErpMarketingPref: '/my-account/erp/marketing-preferences',
    erpEmailConfirmation: '/account/update-preferences-email',
    erpManageJobAlert: '/account/erp/job-alerts',
    secureErpManageJobAlert: '/my-account/erp/job-alerts',
    erpThankyouPage: '/account/erp/marketing-preferences-confirmed',
    secureErpThankyouPage: '/my-account/erp/marketing-preferences-confirmed'
  },
  routing_url_org:{
    searchLinkTitleTerm: '-jobs',
    searchLinklocTerm: 'in-',
    searchLinkTitle: '/:orgName/job-search/{jobTitle}-jobs',
    searchLinkLocation: '/:orgName/job-search/in-{location}',
    searchLinkInLocation: '/:orgName/job-search/{jobTitle}-jobs-in-{location}',
    errorPage:'/:orgName/account/error/',
    successPage:'/:orgName/account/cv-success/',
    successUpdateEmailPrefences:'/:orgName/account/sent-preferences-email',
    confirmationPage: '/:orgName/partner/ps/success',
    officeLocatorResultPage:'/:orgName/contact/offices-near-me/result',
    officeLocatorPage:'/:orgName/contact/offices-near-me',
    speculativeCV:'/:orgName/account/send-cv',
    myProfile:'/:orgName/my-account/profile',
    login:'/:orgName/account/login',
    register: '/:orgName/account/register',
    search:'/:orgName/job-search',
    secureSearch:'/:orgName/job-search',
    secureJobDetails:'/:orgName/job-detail',
    jobdetails:'/:orgName/job-detail',
    forgetPassword:'/:orgName/account/login/forgot-password',
    header:'/:orgName/NZL/en/newheader',
    footer:'/:orgName/NZL/en/newfooter',
    recordIdJobDetail:'/:orgName/NZL/en/jobportal/s/candidate/job/browse/details/',
    jobApply:'/:orgName/job-detail/:recordId/apply',
    secureJobApply:'/:orgName/job-detail/:recordId/apply',
    gdprThankyouPage:'/:orgName/my-account/marketing-preferences-confirmed',
    secureGdprThankyouPage:'/:orgName/my-account/marketing-preferences-confirmed',
    secureMarketingPrefPage:'/:orgName/my-account/marketing-preferences',
    marketingPrefPage:'/:orgName/my-account/marketing-preferences',
    resetPasswordPage:'/:orgName/account/reset',
    manageCV:'/:orgName/my-account/manage-cv',
    secureThankYou:'/:orgName/job-detail/:recordId/job-success',
    thankyou:'/:orgName/job-detail/:recordId/job-success',
    saveWhatsappPage:'/:orgName/my-account/whatsapp-number-confirmed',
    updateWhatsappPage:'/:orgName/my-account/update-whatsapp-number',
    unsubscribePage:'/:orgName/prjs/NZL/en/jobportal/as/alert/submit/unsubscribe',
    alertThankYouPage:'/:orgName/account/job-alert-success',
    emailConfirmationPage:'/:orgName/prjs/NZL/en/jobportal/as/gdpr/submit/email-confirmation',
    recruitingNow:'/:orgName/recruitment/recruit-now',
    secureRecruitingNow:'/:orgName/recruitment/recruit-now',
    goLoginPage:'/:orgName/account/partner/go1/training/login',
    savedSearchesPage: '/:orgName/my-account/job-alerts',
    shortlistedJobsPage: '/:orgName/account/saved-jobs',
    changeEmailPage:'/:orgName/my-account/change-email',
    emailConfirmation:'/:orgName/account/update-preferences-email',
    logOutPage:'/:orgName/account/logout',
    erpMarketingPref: '/erp/as/marketing-pref',
    secureErpMarketingPref: '/my-account/erp/marketing-preferences',
    erpEmailConfirmation: '/account/update-preferences-email',
    erpManageJobAlert: '/account/erp/job-alerts',
    secureErpManageJobAlert: '/my-account/erp/job-alerts',
    erpThankyouPage: '/my-account/erp/marketing-preferences-confirmed',
    secureErpThankyouPage: '/my-account/erp/marketing-preferences-confirmed'
  }
  // routing_url:{
  //   errorPage:'/s/error/',
  //   successPage:'/s/success/',
  //   officeLocatorResultPage:'/office-locator/result',
  //   officeLocatorPage:'/office-locator',
  //   speculativeCV:'/speculativeCV',
  //   myProfile:'/s/my-profile',
  //   login:'/login',
  //   search:'/search',
  //   secureSearch:'/search',
  //   secureJobDetails:'/Job/Detail',
  //   jobdetails:'/Job/Detail',
  //   forgetPassword:'/login/forgot-password',
  //   header:'/NZL/en/header',
  //   footer:'/NZL/en/footer',
  //   jobApply:'/job/:recordId/apply',
  //   secureJobApply:'/job/:recordId/apply',
  //   gdprThankyouPage:'/as/thankyou',
  //   marketingPrefPage:'/as/marketing-prefrences',
  //   resetPasswordPage:'/reset',
  //   manageCV:'/s/manage-cv',
  //   secureThankYou:'/job/apply/details',
  //   thankyou:'/job/apply/details',
  //   saveWhatsappPage:'/NZL/en/jobportal/s/candidate/submit/whatsapp',
  //   updateWhatsappPage:'/NZL/en/jobportal/s/candidate/update/whatsapp',
  //   unsubscribePage:'/NZL/en/jobportal/as/alert/submit/unsubscribe',
  //   alertThankYouPage:'/job/VerifyAlert/success',
  //   emailConfirmationPage:'/NZL/en/jobportal/as/gdpr/submit/email-confirmation',
  //   recruitingNow:'/recruiting-now',
  //   secureRecruitingNow:'/recruiting-now',
  //   erpMarketingPref: '/erp/as/marketing-pref',
  //   secureErpMarketingPref: '/erp/s/marketing-pref',
  //   erpEmailConfirmation: '/erp/as/email-confirmation',
  //   erpManageJobAlert: '/erp/as/manage-job-alert',
  //   secureErpManageJobAlert: '/erp/s/manage-job-alert',
  //   erpThankyouPage: '/erp/thank-you',
  //   goLoginPage:'/partner/go1/training/login/'
  // },
  // routing_url_org:{
  //   errorPage:'/:orgName/s/error/',
  //   successPage:'/:orgName/s/success/',
  //   officeLocatorResultPage:'/:orgName/office-locator/result',
  //   officeLocatorPage:'/:orgName/office-locator',
  //   speculativeCV:'/:orgName/speculativeCV',
  //   myProfile:'/:orgName/s/my-profile',
  //   login:'/:orgName/login',
  //   search:'/:orgName/search',
  //   secureSearch:'/:orgName/search',
  //   secureJobDetails:'/:orgName/Job/Detail',
  //   jobdetails:'/:orgName/Job/Detail',
  //   forgetPassword:'/:orgName/login/forgot-password',
  //   header:'/:orgName/NZL/en/header',
  //   footer:'/:orgName/NZL/en/footer',
  //   jobApply:'/:orgName/job/:recordId/apply',
  //   secureJobApply:'/:orgName/job/:recordId/apply',
  //   gdprThankyouPage:'/:orgName/as/thankyou',
  //   marketingPrefPage:'/:orgName/as/marketing-prefrences',
  //   resetPasswordPage:'/:orgName/reset',
  //   manageCV:'/:orgName/s/manage-cv',
  //   secureThankYou:'/:orgName/job/apply/details',
  //   thankyou:'/:orgName/job/apply/details',
  //   saveWhatsappPage:'/:orgName/NZL/en/jobportal/s/candidate/submit/whatsapp',
  //   updateWhatsappPage:'/:orgName/NZL/en/jobportal/s/candidate/update/whatsapp',
  //   unsubscribePage:'/:orgName/NZL/en/jobportal/as/alert/submit/unsubscribe',
  //   alertThankYouPage:'/:orgName/job/VerifyAlert/success',
  //   emailConfirmationPage:'/:orgName/NZL/en/jobportal/as/gdpr/submit/email-confirmation',
  //   recruitingNow:'/:orgName/recruiting-now',
  //   secureRecruitingNow:'/:orgName/recruiting-now',
  //   erpMarketingPref: '/:orgName/erp/as/marketing-pref',
  //   secureErpMarketingPref: '/:orgName/erp/s/marketing-pref',
  //   erpEmailConfirmation: '/:orgName/erp/as/email-confirmation',
  //   erpManageJobAlert: '/:orgName/erp/as/manage-job-alert',
  //   secureErpManageJobAlert: '/:orgName/erp/s/manage-job-alert',
  //   erpThankyouPage: '/:orgName/erp/thank-you',
  //   goLoginPage:'/:orgName/partner/go1/training/login/',
  // }
};



