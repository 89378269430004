<div class="job-results">
  <button class="mobile-filter btn btn-block btn-secondary" [translate]="'left_Toggle_filtered_Label'" (click)="openFilter()"></button>
  <div class="applied-filters" *ngIf="extraConfig">
    <span class="tag" *ngIf="location" id="location"><em
      [textContent]="location"></em><a href="javascript:void(0);" id="gtm_job_card_removefilter_location"
                                                                                     (click)="removeFilter('location')"><i>+</i></a> </span>
    <span class="tag" *ngIf="industry" id="industry"><em
      [textContent]="extraConfig.isTitleCase?(industry| titlecase):industry"></em><a href="javascript:void(0);" id="gtm_job_card_removefilter_industry"
                                                                                     (click)="removeFilter('industry')"><i>+</i></a> </span>
    <span class="tag" *ngIf="jobSearchFilterData && jobSearchFilterData.jobTypeSelected" id="jobtype"><em
      [textContent]="extraConfig.isTitleCase?(jobSearchFilterData.jobTypeSelected| titlecase):jobSearchFilterData.jobType"></em><a href="javascript:void(0);"
           id="gtm_job_card_removefilter_jobtype"  (click)="removeFilter('jobType')"><i>+</i></a> </span>
    <span class="tag" *ngIf="(jobSearchFilterData && (jobSearchFilterData.flexibleworking!=='false' || jobSearchFilterData.fullTime!=='false' || jobSearchFilterData.partTime!=='false'))" id="flexibleWorking"><em
      [textContent]="extraConfig.isTitleCase?(jobSearchFilterData.workingPattern| titlecase):jobSearchFilterData.workingPattern"></em>
      <a href="javascript:void(0);" id="gtm_job_card_removefilter_workingPattern"      (click)="removeFilter('workingPattern')"><i>+</i></a> </span>
    <span class="tag" *ngIf="jobSearchFilterData && jobSearchFilterData.minSal && jobSearchFilterData.maxSal" id="paytype"><em
      [textContent]="jobSearchFilterData.minSal+'-'+jobSearchFilterData.maxSal"></em><a href="javascript:void(0);"
             id="gtm_job_card_removefilter_paytype" (click)="removeFilter('payType')"><i>+</i></a> </span>
  </div>

  <div class="job-listing" *ngIf="!noResultFound">
    <app-custom-loader  *ngIf="jobList && jobList.length===0"></app-custom-loader>
    <ul *ngIf="jobList">
      <li *ngFor="let job of jobList; index as i">

        <div [ngClass]="job.sponsoredJob?'job-container sponsored-job':'job-container'">

          <div class="job-descp item">
            <!-- <div *ngIf="extraConfig.isResponseJob && !job.orgCode">
              <div [ngClass]="job.micrositeID === 'HAYSITTEST' && job.jobType === 'C' && isMicrositeIdJobTypeLogoEnabled ? 'hays-microsite-contract-type-logo' : job.micrositeID === 'HAYSITTEST' ? 'hays-microsite-logo' : job.siteLocale.indexOf('hr')>-1 ? 'hays-response-logo' : 'hays-logo'"></div>
            </div> -->
            <h3><a id="gtm_job_card"  *ngIf="job.crossCountryUrl || job.micrositeID==='HAYSITTEST'"
                   [href]="job.micrositeID==='HAYSITTEST'?job.micrositeUrl:getJobDetailsUrl(job.crossCountryUrl,job.jobRecordId,job.jobId,job.isSponsored.toUpperCase(),
                      specialismId,subSpecialismId,job.jobName,job.siteLocale.split('-')[0],job.siteLocale.split('-')[1])"
                   [textContent]="job.JobTitle"></a>
              <!--<a id="gtm_job_redirecttodetail"  *ngIf="!job.crossCountryUrl && job.micrositeID!=='HAYSITTEST'"
                 [routerLink]="getJobdetailRoutingLink(job.crossCountryUrl,job.jobRecordId)"
                 [queryParams]="{q: this.keyWord ? this.keyWord : '',location: this.locationQuery ? this.locationQuery : '',
          applyId: job.jobId,
          jobSource: this.jobSource ? this.jobSource : 'HaysGCJ',
          isSponsored: job.isSponsored,
          specialismId: specialismId,
          subSpecialismId: subSpecialismId,
          jobName: job.jobName
          }" [textContent]="job.JobTitle"
              ></a>-->
              <a id="gtm_job_redirecttodetail"  *ngIf="!job.crossCountryUrl && job.micrositeID!=='HAYSITTEST'"
                 [href]="getJobdetailRoutingLink(job.crossCountryUrl,job)"
                  [textContent]="job.JobTitle"
              ></a>
              <em
                class="new-job" *ngIf="isNewJob(job.createDate)" [translate]="'new_job_text'"></em>
            <input type="hidden" [value]="job.jobId">
            </h3>
            <p [innerHTML]="job.SearchTextSnippet"></p>
            <div class="sponsored-text" [translate]="'sponsored_text'"></div>
          </div>
          <div class="other-job-info item">
            <p class="location"><i class="icon-location" *ngIf = "job.locationDesc"></i><span [textContent]="job.locationDesc"></span></p>
            <p
              [ngClass]="extraConfig.currency==='pound'?'salary pound-active':extraConfig.currency==='doller'?'salary dollar-active':extraConfig.currency==='renminbi'?'salary renminbi-active':'salary euro-active'"
              *ngIf="job.incentives"><i class="icon-circle"></i><span [textContent]="job.incentives"> </span></p>
          </div>
          <div class="action btn-cont">
            <img *ngIf="job.sponsoredJob && job.clientLogoUrl" appResizeImage [src]="job.clientLogoUrl">
            <button [id]="'gtm_job_card_google_view'"
              [ngClass]="job.isLoading?'btn btn-secondary view-details btn-loading':'btn btn-secondary view-details'"
              [disabled]="job.isLoading"
              (click)="redirectToDetails(job.crossCountryUrl,job.jobRecordId,job.jobId,job.isSponsored,specialismId,subSpecialismId,job.jobName,
                    job.siteLocale.split('-')[0],job.siteLocale.split('-')[1],job.micrositeID,job.micrositeUrl)"
              [translate]="'google_view_btn_text'">
            </button>
            <input type="hidden" [value]="job.jobId">
          </div>
        </div>
      </li>
    </ul>
    <app-custom-loader  *ngIf="isScrolled"></app-custom-loader>
  </div>
</div>
